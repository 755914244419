<template>
  <div class="hello">
    	<div class="admin-logo"><img src="@/assets/ki_logo_w.png" width="80%"></div>
			<div style="width:190px">
        <el-menu
          :default-active="selec"
          class="el-menu-vertical-demo"
          background-color="#F8F8F8"
          text-color="#444444"
          active-text-color="#04C15F" style="width:190px">
          <el-menu-item  index="1"  v-if=" login_user_type=='admin_user' ||  login_user_type=='staff_user'" @click="$router.push('/menu')" >
            <i class="el-icon-reading"></i>
            <span slot="title">菜谱</span>
          </el-menu-item>

          <el-menu-item  v-if="login_user_type=='admin_user'" index="2" @click="$router.push('/template')" >
            <i class="el-icon-collection"></i>
            <span slot="title">菜谱库</span>
          </el-menu-item>
          <el-menu-item index="3"  v-if="login_user_type=='admin_user' || ( login_user_type=='supplier_user' && supplier_manage_dish == '1' )" @click="$router.push('/dish')" >
            <i class="el-icon-food"></i>
            <span slot="title">菜品</span>
          </el-menu-item>
          <el-menu-item index="4"  v-if="login_user_type=='admin_user' || ( login_user_type=='supplier_user' && supplier_manage_dish == '1' )" @click="$router.push('/food')" >
            <i class="el-icon-box"></i>
            <span slot="title">食材</span>
          </el-menu-item>
           <el-menu-item index="5" @click="$router.push('/order')" >
            <i class="el-icon-shopping-cart-full"></i>
            <span slot="title">订单</span>
          </el-menu-item>
          <!-- <el-menu-item v-if="login_user_type=='admin_user'" index="6" @click="$router.push('/message')" >
            <i class="el-icon-chat-dot-square"></i>
            <span slot="title">留言</span>
          </el-menu-item> -->
           <el-menu-item v-if="login_user_type=='admin_user'" index="7" @click="$router.push('/statistics')" >
            <i class="el-icon-data-line"></i>
            <span slot="title">统计</span>
          </el-menu-item>
           <el-menu-item  v-if="login_user_type=='admin_user'" index="8" @click="$router.push('/system')" >
            <i class="el-icon-set-up"></i>
            <span slot="title">设置</span>
          </el-menu-item>
        </el-menu>
      </div>
  </div>
</template>

<script>
import * as MN from '@/api/common'
export default {
  name: 'common-nav',
  props: {
    msg: String,
    selec:String
  },
  data(){
    return{
      login_user_type:'',
      supplier_manage_food:'',
      supplier_manage_dish:''
    }
  },
  created() {
		this.handleLoginUser()
	},
  methods:{
    handleLoginUser(){
			// let checkSession = sessionStorage.getItem('kindergarten_meals_session');
			// if ( !checkSession ){
			//   this.$router.push('/');
			// }
			let me = this;
			// let post={
			//   meals_session:sessionStorage.getItem('kindergarten_meals_session'),
					// 	meals_usertype:sessionStorage.getItem('kindergarten_meals_usertype')
					// }
			MN.kindergartenLoginUser().then(res => {
				//me.login_user_uuid = res.user_uuid;
				me.login_user_type = res.login_type;
        me.supplier_manage_food = res.supplier_manage_food;
        me.supplier_manage_dish = res.supplier_manage_dish;
				//me.login_user_name = res.user_name;
				// me.login_user_company_name = res.company_name;
				// me.login_user_kindergarten_name = res.kindergarten_name;
				console.log(res)
				return
			})
		},
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 0 0 0;
  
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 0;
}
a {
  color: #42b983;
}
.el-menu{
  width: 200px;
}
.el-submenu{
  width: 200px;
  border-bottom:1px solid #e0e0e0;
}
.el-menu-item .disabled{
  border-bottom:1px solid #e0e0e0;
}
.el-menu-item{
  width: 200px;
  border-bottom:1px solid #e0e0e0;
}
</style>
