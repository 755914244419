<template>
	<div>

	<el-container class="data_skc">

		<el-aside class="aside-style" width="200px">
			<common-nav selec="1"></common-nav>
		</el-aside>
		
		<el-container>

			<el-header class="common_header">
				<common-header></common-header>
			</el-header>
			
			<el-main>

				<el-row :gutter="24" class="page_bar">
					<el-col :span="20" class="page_bar_title"><i @click="handleGoBack()" class="el-icon-back showhand" style="font-size:22px"></i>  批量添加菜品</el-col>
					<el-col :span="2">
						<el-badge is-dot :hidden="hiddenDraftDot"><el-button size="small" @click="handleUseDraft()" >草稿箱 {{": "+hasDraft }}</el-button></el-badge>
					</el-col>
				</el-row>

				<el-row :gutter="24" class="page_batch_title">
					<el-col :span="2">菜品图片</el-col>
					<el-col :span="3">菜品分类</el-col>
					<el-col :span="4">菜品名称</el-col>
					<el-col :span="4">每份售价</el-col>
					<el-col :span="9">包含食材</el-col>
					<el-col :span="2">操作</el-col>
				</el-row>

				
				<el-row v-for="item in batchList" :key="item.rowid" :gutter="24" class="page_batch_row">
					<el-col :span="2">
						<el-upload
						class="avatar-uploader"
						:data="{rowid:item.rowid}"
						action="https://www.yyvvi.com/kindergarten/index/postsImages"
						:show-file-list="false"
						:on-success="handleAvatarSuccess"
						:before-upload="beforeAvatarUpload">
						<img v-if="item.show_pic" :src="item.show_pic" class="avatar">
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
						</el-upload>
					</el-col>
					<el-col :span="3">
						<el-select v-model="item.type" placeholder="显示全部">
							<el-option
							v-for="item in dishTypeTableData"
							:key="item.name"
							:label="item.name"
							:value="item.name"
							:disabled="item.disabled">
							</el-option>
						</el-select>
					</el-col>
					<el-col :span="4">
						<el-input v-model="item.name" placeholder="请输入内容" ></el-input>
					</el-col>
					<el-col :span="4" >
						<el-input-number  v-model="item.price"  :precision="2"  placeholder="请输入内容"></el-input-number>
					</el-col>
					<el-col :span="9" >
						<el-link v-if="!item.foodlistString" type="primary" @click="handleSelectFood(item)">点此选择食材</el-link>
						<el-link v-if="item.foodlistString" type="primary" @click="handleSelectFood(item)">{{item.foodlistString}}</el-link>
					</el-col>
					<el-col :span="2">
						<el-link type="danger" @click="handleDelRow(item.rowid)" class="button_del" icon="el-icon-close"></el-link>
					</el-col>
				</el-row>

				<el-row style="margin-top:12px">
					<el-col :span="2" style="text-align: center">
						<el-button style="width:100%" type="success" size="mini" plain @click="handleAddRow" >增加一行</el-button>
					</el-col>
				</el-row>
				
				<el-row style="margin-top:30px">
					<el-col :span="6">
						<el-button style="width:300px" type="success" @click="handelSaveBatch" >保存全部菜品</el-button>
					</el-col>
					<el-col :span="3" :offset="1">
						<el-button style="width:100%" type="primary" @click="handelSaveDraft" plain>存为草稿</el-button>
					</el-col>
				</el-row>
				
			</el-main>

			<el-footer> copyright &copy; yyvvi.com </el-footer>
		</el-container>
	</el-container>


<el-dialog
	title="食材选择器"
	:visible.sync="addFoodDialogVisible"
	width="40%" :close-on-click-modal="false">

	<el-row class="mt20">
		<el-col :span="3">包含食材:</el-col>
		<el-col :span="20">
			<el-row>
				<el-col :span="7">
					<el-select v-model="dishAddfoodValue" filterable   @change="handleChangeUnitWeight" placeholder="显示全部" size="small" style="width:100%">
						<el-option
						v-for="item in foodListData"
						:key="item.id"
						:label="item.name"
						:value="item.id">
						</el-option>
					</el-select>
				</el-col>
				<el-col :span="11" :offset="1">
					 <el-input-number placeholder="请输入"  :precision="2" @input="handleDoseInput" style="width:80%" size="small"  v-model="dishAddfoodDose">
						<template slot="prepend">用量</template>
						<template slot="append"><strong>{{showUnitWeight}}</strong></template>
					</el-input-number>
					<strong style="margin-left:10px">g</strong>
				</el-col>
				<el-col :span="2" :offset="1">
					<el-button size="small" plain type="primary" @click="hadleNewDishAddFood">添加食材</el-button>
				</el-col>
			</el-row>
			 <el-table
				:data="foodcheckedListData"
				border
				style="width: 100%; margin-top:8px" size="mini">
				<el-table-column
				prop="foodname"
				label="已选食材">
				</el-table-column>
				<el-table-column
				prop="fooddose"
				label="每份用量">
				</el-table-column>
				<el-table-column
				prop="foodunit"
				label="单位">
				</el-table-column>
				<el-table-column
				prop="address"
				label="操作" width="100">
					<template slot-scope="scope">
						<el-button type="danger" size="mini" @click="handleDeleteDishAddFood(scope.row.foodid)"  plain>删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</el-col>
	</el-row>
	<span slot="footer" class="dialog-footer">
		<el-button @click="addFoodDialogVisible = false">取 消</el-button>
		<el-button type="primary" @click="handleSaveDishFoodRow">保存</el-button>
	</span>
	</el-dialog>

	</div>
</template>

<script>
import CommonNav from "@/components/Nav.vue";
import CommonHeader from "@/components/Header.vue";
import * as MN from '@/api/common';
import { toRaw } from 'vue';

export default {
    name: 'crm', 
	props:['selec'],
	data() {
		return {
			hasDraft:'空',
			hiddenDraftDot:true,
			dishTypeTableData:[],
			showUnitWeight:'g',
			dishRowID:'',
			dishAddfoodValue:'',
			dishAddfoodDose:'',
			dishAddfoodUnit:'',
			dishAddfoddUnitWeight:'',
			foodcheckedListData:[],
			foodListData:[],
			foodtype_options:[
				{value: '1',label: '蔬菜'},
				{value: '2',label: '水果'},
				{value: '3',label: '蛋类'},
				{value: '4',label: '肉类'},
				{value: '5',label: '谷物'}
			],
			dishtype_options:[
				{value:'1',label:'主食/干食'},
				{value:'2',label:'蔬菜类'},
				{value:'3',label:'蛋类'},
				{value:'4',label:'肉菜'},
				{value:'5',label:'荤素搭配菜'},
				{value:'6',label:'素菜'},
				{value:'7',label:'汤类'},
			],
			imageUrl: '',
			login_user_type:'',
			loading:true,
			detailData: [],
			detailList:[],
			detailUUID:this.$route.query.uuid,
			batchList:[],
			addFoodDialogVisible:false,
		}
	},
	components: {
		CommonNav,
		CommonHeader
	},

	created() {
		this.initData()
		this.handleLoginUser();
	},

	mounted() {

	},

	methods: {
		handleUseDraft(){
			let me = this;
			this.$confirm('确认使用草稿箱中的内容吗？', '提示', {
				confirmButtonText: '使用',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				let kindergartendraft = JSON.parse(localStorage.getItem('kindergartendraft_dish'));
				me.batchList = kindergartendraft;
				console.log("batchList",me.batchList)
			}).catch(() => {
				       
			});
		},
		handleChangeUnitWeight(){
			console.log("dishAddfoodValue",this.dishAddfoodValue)
			for ( let i=0; i <this.foodListData.length;i++ ){
				if ( this.foodListData[i].id ==  this.dishAddfoodValue ){
					this.showUnitWeight = this.foodListData[i].unit
					this.dishAddfoodUnit = this.foodListData[i].unit
					this.dishAddfoddUnitWeight = this.foodListData[i].unit_weight
				}
			}
			
		},
		handleDoseInput(value){
			// 使用正则表达式来判断是否为整数
			const regex = /^[-+]?\d*$/;
			if (!regex.test(value)) {
				// 如果不是整数，则设置为上一个合法的值
				this.dishAddfoodDose = value.substring(0, value.length - 1);
			}
		},
		handelSaveDraft(){
			let me = this;
			this.$confirm('草稿保存在您的浏览器，关闭浏览器后可能会被清空，请及时从草稿箱取出并保存', '提示', {
				confirmButtonText: '保存到草稿箱',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				me.hasDraft = '有草稿';
				me.hiddenDraftDot = false;
				localStorage.setItem('kindergartendraft_dish',JSON.stringify(me.batchList));
			}).catch(() => {
				       
			});
		},
		handelSaveBatch(){
			
			let isReady = true;
			this.batchList.forEach((items,index) => {
				if ( isReady == true ){
					if ( !items.type || !items.name || !items.price  || !items.foodlistString ){
						this.$message.error('请将内容补充完整');
						isReady = false;
						return;
					}
				}
			});

			// this.batchList.forEach((items,index) => {
			// 	items.foodlist = JSON.stringify(items.foodlist)
			// });

			if ( isReady == true ){

				let me = this;
				this.$confirm('确认提交吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let post={
						batchList:JSON.stringify(this.batchList),
					}
					MN.getHandleBatchSaveDish(post).then(res => {
						if( res == 'add_batch_ok' ){
							me.$message({
								type: 'success',
								message: '批量提交成功!'
							});
							me.$router.push('/dish')
						}
					})
				}).catch(() => {
					me.$message({
						type: 'info',
						message: '已取消'
					});          
				});
			}

			


			console.log("os",this.batchList)
		},
		handleSaveDishFoodRow(){
			console.log("dishRowID",this.dishRowID)
			console.log(this.batchList)
			console.log("foodcheckedListData",this.foodcheckedListData)
			let tempfoodString = '';
			this.foodcheckedListData.forEach((items,index) => {
				tempfoodString = tempfoodString + (items.foodname + "（" + items.fooddose + items.foodunit +"），");
			});
			for(let i = 0 ; i < this.batchList.length; i++ ){
				if ( this.batchList[i].rowid == this.dishRowID ){
					this.batchList[i].foodlistString = tempfoodString;
					this.batchList[i].foodlist = this.foodcheckedListData
				}
			}
			this.addFoodDialogVisible = false;
			console.log("this.batchList",this.batchList);
		},
		handleDeleteDishAddFood(foodid){
			console.log(foodid);
			for ( let i = 0; i < this.foodListData.length;i++ ){
				if ( this.foodcheckedListData[i].foodid == foodid ){
					this.foodcheckedListData.splice([i],1)
					return
				}
			}
		},
		hadleNewDishAddFood(){
			if ( !this.dishAddfoodValue || !this.dishAddfoodDose ){
				this.$message.error('请选择要添加的食材、输入用量');
				return
			}
			let getfoodname = '';
			let getfoodunit = '';
			let getfoodunitweight = '';
			for ( let i = 0; i < this.foodListData.length;i++ ){
				if ( this.foodListData[i].id == this.dishAddfoodValue ){
					getfoodname = this.foodListData[i].name;
					getfoodunit = this.foodListData[i].unit;
					getfoodunitweight = this.foodListData[i].unit_weight;
				}
			}
			
			this.foodcheckedListData.push({
				foodid:this.dishAddfoodValue,
				foodname:getfoodname,
				fooddose:this.dishAddfoodDose,
				foodunit:getfoodunit,
				foodunitweight:getfoodunitweight
			})

			this.dishAddfoodValue = '';
			this.dishAddfoodDose = '';
			this.dishAddfoodUnit = '';
			this.dishAddfoddUnitWeight = '';
		},
		handleSelectFood(rowdata){
			let me = this;
			me.dishRowID = rowdata.rowid;
			MN.getFoodListData().then(res => {
				
				me.dishAddfoodValue = '';
				me.dishAddfoodDose = '';
				me.dishAddfoodUnit = '';
				me.dishAddfoddUnitWeight = '';
				me.foodcheckedListData = [];
				me.foodListData=res;
				if ( rowdata.foodlist ){
					me.foodcheckedListData = rowdata.foodlist;
				}
				me.addFoodDialogVisible = true;
			})
		},
		handleDelRow(rowid){
			if ( this.batchList.length == 1 ){
				this.$message.error('至少留一行啊!');
				return
			}
			this.batchList.forEach((items,index) => {
				if ( items.rowid == rowid ){
					this.batchList.splice(index,1);
				}
			});
		},
		handleAddRow(){
			let row_id = new Date().getTime();
			this.batchList.push({rowid:row_id,type:'',name:'',show_pic:'',pic_url:'',price:'',foodlistString:'',foodlist:[]});
		},
		handleAvatarSuccess(res, file) {
			//this.imageUrl = URL.createObjectURL(file.raw);
			this.batchList.forEach(items => {
				if ( items.rowid == res.rowid ){
					items.show_pic = "https://www.yyvvi.com/uploads/" + res.image_url
					items.pic_url =  res.image_url
				}
			});
		},
      beforeAvatarUpload(file) {
        const isPNG = file.type === 'image/png';
		const isGIF = file.type === 'image/gif';
		const isJPG = file.type === 'image/jpeg';
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isJPG && !isGIF && !isPNG) {
          this.$message.error('只能是图片格式（JPG/GIF/PNG）');
		   return false
        }
        if (!isLt2M) {
          this.$message.error('图片大小不能超过2MB');
		  return false
        }
        
      },

		handleLoginUser(){
			let me = this;
			MN.kindergartenLoginUser().then(res => {
				//me.login_user_uuid = res.user_uuid;
				me.login_user_type = res.login_type;
			})
		},
		initData(){
			let me = this;
			let row_id = new Date().getTime();
			this.batchList.push({rowid:row_id,type:'',name:'',show_pic:'',pic_url:'',price:'',foodlistString:'',foodlist:[]});
			MN.getActivateTypeList().then(res => {
				me.dishTypeTableData = res.dishtype;
			})
			let check_kindergartendraft = localStorage.getItem('kindergartendraft_dish');
			if ( check_kindergartendraft ){
				me.hasDraft = '有草稿';
				me.hiddenDraftDot = false;
			}
		},
		handleGoBack(){
			this.$router.back(-1)
		}
	}
}
</script>

<style scoped>

.button_del{ font-size: 26px; margin: 12px 0 0 0; }

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;background-color: red;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 18px;
    color: #4d7534;
    width: 45px;
    height: 45px;
    line-height: 45px;
    text-align: center;
	border: 1px dashed #a4c471;

  }
  .avatar {
    width: 45px;
    height:45px;
    display: block;
  }

.page_batch_row{
	position: relative;
	border: 1px solid #d3d3d3;
	background-color:#ffffff;
	border-radius: 6px;
	padding: 8px;
	margin: 4px 0 10px 0;
}

.page_batch_title{
	font-size: 15px;
	color: #1a9016;
	background-color: #f2fdf6;
	padding: 9px;
	border-bottom: 1px solid #a4dab0;
}

.clear{ clear: both; }
.day_block_empty{
	position: absolute;
	left: 0;
	top: 30%;
	text-align: center;
	width: 100%;
	color: #b3b3b3;
	font-size: 13px;
}
.button_edit_dish{
	width: 18px;
	height: 18px;
	background:url('@/assets/icon_green_edit.png') no-repeat;
	background-size: 100%;
	position: absolute;
	right:30px;
	top: 4px;
	cursor: pointer;
}
.button_del_dish{
	background:url('@/assets/icon_green_del.png') no-repeat;
	background-size: 100%;
	width: 18px;
	height: 18px;
	position: absolute;
	right: 5px;
	top: 4px;
	cursor: pointer;
}
.dis_dish_row{ 
	position: relative;
	border: 1px solid #cccccc;
	padding: 4px;
	text-align: left;
	font-size: 13px;
	margin-bottom: 5px;
	border-radius: 4px;
}
.button_add_dish{
	position: absolute;
	left: 0;
	bottom: -33px;
	width: 99%;
	padding:6px 0 6px 0;
	font-size: 13px;
	background:url('@/assets/icon_green_add.png') no-repeat #F4F8EE;
	background-position:30% 50%;
	background-size: 16px;
	border: 1px solid #54C651;
	color: #2C9E46;
	font-weight: bolder;
	border-radius: 4px;
	cursor: pointer;
}
.panel_menu_rowx{
	position: relative;
	overflow-x: auto;
	width: 97%;
	margin: 0 0 0 2%;
}
.panel_menu_rowbig{
	width: 1250px;
	padding-bottom:16px;
}
.week_txo{
	position: absolute;
	left: 5px;
	top: -18px;
	font-size: 12px;
	color: #999999;
}
.panel_day_block{
	position: relative;
	background-color: #ffffff;
	box-shadow: 1px 1px 3px #b9b9b9;
	border-radius: 8px;
	min-height: 60px;
	width: 145px;
	padding: 5px;
	float: left;
	margin: 20px 0 33px 14px;
}
.panel_dixz_label{
	width: 15px;
	float: left;
	text-align:center;
	font-size: 12px;
	color: #0C922E;
	font-weight: bolder;
}
.panel_dixz{
	border: 1px solid #cbcbcb;
	background:url('@/assets/icon_green_bg.png') repeat-y #EDECE6;
	background-size: 27px;
	text-align: center;
	padding: 6px;
	border-radius: 8px;
	margin-bottom: 8px;
	position: relative;
}
.panel_exo{
	border-radius: 10px;
	padding: 20px;
	background-color: #f9f9f9;
}
.buttonexcel{
	background: none;
	background-color: #409eff;
	width: 90px;
	color: #fff;
	border: none;
	border-radius: 4px;
	padding: 5px;
	cursor: pointer;
}
.page_bar_title{
	color: #575757;
}
.mt20{
	margin-top: 20px;
}
.detail_row{
	background-color: #f8f8f8;
	border-radius: 10px;
	padding: 8px;
	box-shadow: 0 2px 5px #eeeeee;
	text-align: left;
}

::v-deep .el-tabs__active-bar {
	cursor: pointer;
	background-color: #03BC5B;
}
::v-deep .el-tabs__item.is-active {
	color: #03BC5B;
	font-weight: bolder;
	cursor: pointer;
}
::v-deep .el-tabs__item:hover {
	color: #03BC5B;
	cursor: pointer;
}

</style>
