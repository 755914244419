<template>
	<div>

	<el-container class="data_skc">

		<el-aside class="aside-style" width="200px">
			<common-nav selec="7"></common-nav>
		</el-aside>
		
		<el-container>

			<el-header class="common_header">
				<common-header></common-header>
			</el-header>
			
			<el-main>

				<el-row :gutter="24" class="page_bar">
					<el-col :span="20" class="page_bar_title"><i @click="handleGoBack()" class="el-icon-back showhand" style="font-size:22px"></i>  {{detailData.topics_name}}</el-col>
					<el-col :span="4"></el-col>
				</el-row>

				<el-row :gutter="24" style="margin-top:10px">
					<el-col :span="19">
						<el-descriptions class="margin-top" :column="2" size="medium" border>
							<el-descriptions-item>
								<template slot="label">
									参与人数
								</template>
								{{userCount}}
							</el-descriptions-item>
							
							<el-descriptions-item>
								<template slot="label">
									专题类型
								</template>
								报名
							</el-descriptions-item>
							<el-descriptions-item>
								<template slot="label">
									创建人
								</template>
								管理员
							</el-descriptions-item>
							<el-descriptions-item>
								<template slot="label">
									创建日期
								</template>
								<!-- 2023-9-25 15:23 -->
							</el-descriptions-item>
							<el-descriptions-item>
								<template slot="label">
									专题链接
								</template>
								<a target="_blank" :href="topicsLink">{{topicsLink}}</a>
							</el-descriptions-item>
						</el-descriptions>
					</el-col>
					<el-col :span="5">
						<img :src="'https://www.baibaodu.com/qrcode/'+detailData.topics_qrcode" width="200px">
						 <!-- <img src="@/assets/six_qrcode.png" width="200px">  -->
					</el-col>
				</el-row>

				<div class="detail_row">
					<form :action="'https://api.baibaodu.com/public/wendaoink/index/exportExcel'+detailData.nickname" method="post" enctype="multipart/form-data">
                            <input type="submit" value="导出Excel" class="buttonexcel">
					</form>
					<!-- <el-button disabled type="primary" size="mini">导出Ecxel</el-button> -->
					<!-- <el-button type="danger" size="mini">结束活动</el-button> -->
				</div>

				<el-tabs  class="mt20" v-model="activeName">
					<el-tab-pane label="活动数据" name='customer'>
						<el-table
							:data="detailList"
							border
							style="width: 100%" stripe size="small">
							<el-table-column
							prop="id"
							label="ID"
							width="50">
							</el-table-column>
							<el-table-column
							prop="name"
							label="姓名"
							width="110">
							</el-table-column>
							<el-table-column
							prop="sex"
							label="性别" width="80">
							</el-table-column>
							<el-table-column
							prop="company_title"
							label="职务">
							</el-table-column>
							<el-table-column
							prop="company_name"
							label="单位">
							</el-table-column>
							<el-table-column
							prop="phone"
							label="电话">
							</el-table-column>
							<el-table-column
							prop="need_room"
							label="是否住宿">
							</el-table-column>
							<el-table-column
							prop="room_type"
							label="需要房型">
							</el-table-column>
							<el-table-column
							prop="checkin"
							label="入住日期">
							</el-table-column>
							<el-table-column
							prop="checkout"
							label="离开日期">
							</el-table-column>
							<el-table-column
							prop="cohabitant"
							label="同住人">
							</el-table-column>
							<el-table-column
							prop="remark"
							label="备注">
							</el-table-column>
						</el-table>
					</el-tab-pane>
					<!-- <el-tab-pane label="参与客户">参与客户</el-tab-pane> -->
					<!-- 
					<el-tab-pane label="角色管理">角色管理</el-tab-pane>
					<el-tab-pane label="定时任务补偿">定时任务补偿</el-tab-pane> -->
				</el-tabs>
			</el-main>

			<el-footer> copyright &copy; yyvvi.com </el-footer>
		</el-container>
	</el-container>


	</div>
</template>

<script>
import CommonNav from "@/components/Nav.vue";
import CommonHeader from "@/components/Header.vue";
import * as MN from '@/api/common';

export default {
    name: 'crm', 
	props:['selec'],
	data() {
		return {
			activeName:'customer',
			loading:true,
			boxshow: false, 
			curTipsID: '',
			userCount:0,
			detailData: [],
			detailList:[],
			detailUUID:this.$route.query.uuid,
			topicsLink:''
		}
	},
	components: {
		CommonNav,
		CommonHeader
	},

	created() {
		this.initData()
	},

	mounted() {

	},

	methods: {
		initData(){
			let me = this;
			this.loading = false
			let post={
				uuid:this.detailUUID
			}
			MN.getTopicsDetail(post).then(res => {
				if ( res.status == 'no_login' || res == 'error_user' || res.userid != '3885A8DF457616F2FE5A909F1921B00B'){
					//location.href = "https://www.baibaodu.com/main/";
					me.$router.push('/');
					return
				}
				this.detailData = res.data;
				this.detailList = res.list;
				this.userCount = res.count;
				this.topicsLink = res.data.topics_link;
				this.loading = false;
			})
		},
		handleGoBack(){
			this.$router.back(-1)
		}
	}
}
</script>

<style scoped>
.buttonexcel{
	background: none;
	background-color: #409eff;
	width: 90px;
	color: #fff;
	border: none;
	border-radius: 4px;
	padding: 5px;
	cursor: pointer;
}
.page_bar_title{
	color: #575757;
}
.mt20{
	margin-top: 20px;
}
.detail_row{
	background-color: #f8f8f8;
	border-radius: 10px;
	padding: 8px;
	box-shadow: 0 2px 5px #eeeeee;
	text-align: left;
}
</style>
