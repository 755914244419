<template>
	<div>

	<el-container class="data_skc">

		<el-aside class="aside-style" width="200px">
			<common-nav selec="3"></common-nav>
		</el-aside>
		
		<el-container>

			<el-header class="common_header">
				<common-header></common-header>
			</el-header>
			
			<el-main>

				<el-row :gutter="24" class="page_bar">
					<el-col :span="5" class="page_bar_title">菜品列表</el-col>
					<el-col :span="5" :offset="10">
						<el-button size="small" type="success"  icon="el-icon-plus" @click="handleAddDish" >新建菜品</el-button>
						<el-button size="small" type="success"  icon="el-icon-document-copy" @click="handleBatchDish" >批量新建</el-button>
					</el-col>
					<el-col :span="4">
						<el-input placeholder="输入标题查找" v-model="searchDishValue" class="input-with-select" size="small" >
							<el-button slot="append" icon="el-icon-search" @click="handleSearchDish"></el-button>
						</el-input>
					</el-col>
				</el-row>


					<el-table v-loading="loading" :data="tableDishData" size="medium" stripe highlight-current-row>
						<el-table-column prop="pic_url" label="图片" width="80" >
							<template slot-scope="scope">
								<el-image :src="scope.row.pic_url" class="cover_img">
									<div slot="error" class="image-slot">
										<i class="el-icon-picture-outline"></i>
									</div>
								</el-image>
							</template>
						</el-table-column>
						<el-table-column prop="status" label="状态" width="80" >
							<template slot-scope="scope">
								<label v-if="scope.row.status == 1" style="color:green">正常</label>
								<label v-if="scope.row.status == 0" style="color:red">停用</label>
							</template>
						</el-table-column>
						<el-table-column prop="stype" label="分类" width="110" >
							<!-- <template slot-scope="scope">
								<label v-if="scope.row.stype == 1" >主食/干食</label>
								<label v-if="scope.row.stype == 2" >蔬菜类</label>
								<label v-if="scope.row.stype == 3" >蛋类</label>
								<label v-if="scope.row.stype == 4" >肉菜</label>
								<label v-if="scope.row.stype == 5" >荤素搭配菜</label>
								<label v-if="scope.row.stype == 6" >素菜</label>
								<label v-if="scope.row.stype == 7" >汤类</label>
							</template> -->
						</el-table-column>
						<el-table-column prop="name" label="名称"></el-table-column>
						<el-table-column  label="包含食材" width="480" >
							<template slot-scope="scope">
								<span v-for="item in scope.row.foodlist" :key="item">
									{{item.food_name}}（{{item.dose}}{{item.unit}}）,
								</span>
							</template>
						</el-table-column>
						<el-table-column prop="unit" width="90" label="单位" ></el-table-column>
						<el-table-column prop="price" width="110" label="每份售价" ></el-table-column>
						<el-table-column label="操作" width="170">
							<template slot-scope="scope">
								<el-button type="danger" v-if="scope.row.status==1" size="mini" plain @click="handleChangeDishStatus('0',scope.row.uuid)">停用</el-button>
								<el-button type="success" v-if="scope.row.status==0" size="mini" plain @click="handleChangeDishStatus('1',scope.row.uuid)">启用</el-button>
								<el-button type="primary" size="mini" plain @click="handleEditDish(scope.row)">修改</el-button>
							</template>
						</el-table-column>
					</el-table>

					<!-- <el-pagination
						background
						layout="prev, pager, next"
						:total="10" class="page_pagination">
					</el-pagination> -->

					<el-pagination
						background
						:page-size="15" 
						:current-page="pagenow"
						layout="prev, pager, next"
						:total="totalnum" @current-change="handleCurrentChange" class="page_pagination">
					</el-pagination>

	</el-main>

			<el-footer> copyright &copy; yyvvi.com </el-footer>
		</el-container>
	</el-container>



	<el-dialog
	title="菜品"
	:visible.sync="addDishDialogVisible"
	width="40%" :close-on-click-modal="false">

	<el-row>
		<el-col :span="5">
			<el-upload
			class="avatar-uploader"
			action="https://www.yyvvi.com/kindergarten/index/postsImages"
			:show-file-list="false"
			:on-success="handleAvatarSuccess"
			:before-upload="beforeAvatarUpload">
			<img v-if="show_newadd_dish_pic" :src="show_newadd_dish_pic" class="avatar">
			<i v-else class="el-icon-plus avatar-uploader-icon"></i>
			</el-upload>
		</el-col>
		<el-col :span="18">
			<el-row>
				<el-col :span="11">
					菜品分类:
					<el-select v-model="select_dishtype" placeholder="显示全部" size="small">
						<el-option
						v-for="item in dishTypeTableData"
						:key="item.name"
						:label="item.name"
						:value="item.name"
						:disabled="item.disabled">
						</el-option>
					</el-select>
				</el-col> 
				<el-col :span="12" :offset="1">
					菜品名称:
					<el-input v-model="newdish_name"  size="small" placeholder="请输入内容"></el-input>
				</el-col>
			</el-row>
			<el-row class="mt20">
				<el-col :span="4">每份售价:</el-col>
				<el-col :span="10">
					<el-input-number  v-model="dish_sell_price"  :precision="2"  size="small" placeholder="请输入内容"></el-input-number>
				</el-col>
			</el-row>
		</el-col>
	</el-row>

	<el-row class="mt20">
		<el-col :span="3">包含食材:</el-col>
		<el-col :span="20">
			<el-row>
				<el-col :span="7">
					<el-select v-model="dishAddfoodValue" @change="handleChangeUnitWeight" filterable placeholder="显示全部" size="small" style="width:100%">
						<el-option
						v-for="item in foodListData"
						:key="item.id"
						:label="item.name"
						:value="item.id">
						</el-option>
					</el-select>
				</el-col>
				<el-col :span="11" :offset="1">
					<el-input-number  placeholder="请输入" :precision="2" style="width:80%" size="small"  v-model="dishAddfoodDose">
						<template slot="prepend">用量</template>
						<template slot="append"><strong>{{showUnitWeight}}</strong></template>
					</el-input-number >
					<strong style="margin-left:10px">g</strong>
				</el-col>
				<el-col :span="2" :offset="1">
					<el-button size="small" plain type="primary"  @click="hadleNewDishAddFood">添加食材</el-button>
				</el-col>
			</el-row>
			 <el-table
				:data="foodcheckedListData"
				border
				style="width: 100%; margin-top:8px" size="mini">
				<el-table-column
				prop="foodname"
				label="已选食材">
				</el-table-column>
				<el-table-column
				prop="fooddose"
				label="每份用量">
				</el-table-column>
				<el-table-column
				prop="foodunit"
				label="单位">
				</el-table-column>
				<el-table-column
				prop="address"
				label="操作" width="100">
					<template slot-scope="scope">
						<el-button type="danger" size="mini" @click="handleDeleteDishAddFood(scope.row.foodid)"  plain>删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</el-col>
	</el-row>
	<span slot="footer" class="dialog-footer">
		<el-button @click="addDishDialogVisible = false">取 消</el-button>
		
		<el-button v-if="!edit_dish_uuid" type="primary" @click="hadleSaveNewDish">保存新菜品</el-button>
		<el-button v-if="!!edit_dish_uuid" type="primary" @click="handleSaveEditDish">保存修改</el-button>
	</span>
	</el-dialog>





	</div>
</template>

<script>

import CommonNav from "@/components/Nav.vue";
import CommonHeader from "@/components/Header.vue";

import * as MN from '@/api/common'

export default {
    name: 'crm', 
	props:['selec'],
	data() {
		return {
			pagenow:1,
			totalnum:0,
			dishTypeTableData:[],
			showUnitWeight:'g',
			newadd_dish_pic:'',
			show_newadd_dish_pic:'',
			edit_dish_uuid:'',
			login_user_type:'',
			login_supplier_food:'',
			login_supplier_dish:'',
			new_totemplate_menu:'',
			new_template_name:'',
			tableTemplateData:[],
			select_kindergarten_options: [],
			new_menu_kindergarten_uuid: '',
			new_menu_template:'',
			new_menuWeek:'',
			templateTableData:[],
			dish_sell_price:'',
			dishAddfoodValue:'',
			dishAddfoodDose:'',
			dishAddfoodUnit:'',
			dishAddfoddUnitWeight:'',
			foodcheckedListData:[],
			addfood_value:'',
			newdish_name:'',
			select_dishtype:'',
			foodListData:[],
			addDishDialogVisible:false,
			searchDishValue:'',
			searchFoodValue:'',
			edit_food_id:'',
			newfood_name:'',
			select_foodtype:'',
			buy_price:'',
			dishtype_options:[
				{value:'1',label:'主食/干食'},
				{value:'2',label:'蔬菜类'},
				{value:'3',label:'蛋类'},
				{value:'4',label:'肉菜'},
				{value:'5',label:'荤素搭配菜'},
				{value:'6',label:'素菜'},
				{value:'7',label:'汤类'},
			],
			foodtype_options:[
				{value: '1',label: '蔬菜'},
				{value: '2',label: '水果'},
				{value: '3',label: '蛋类'},
				{value: '4',label: '肉类'},
				{value: '5',label: '谷物'}
			],
			addFoodDialogVisible:false,
			activeTab:'menu',
			searchValue:'',
			loading:true,
			boxshow: false, 
			curTipsID: '', 
			tableMenuData: [],
			tableDishData:[],
			tableFoodData:[],
			select_value: '',
		}
	},
	components: {
		CommonNav,
		CommonHeader
	},

	created() {
		this.initData();
		this.handleLoginUser();
	},

	mounted() {

	},

	methods: {
		handleCurrentChange(new_page) {
			this.initData(new_page);
     	},
		handleChangeUnitWeight(){
			console.log("dishAddfoodValue",this.dishAddfoodValue)
			for ( let i=0; i <this.foodListData.length;i++ ){
				if ( this.foodListData[i].id ==  this.dishAddfoodValue ){
					this.showUnitWeight = this.foodListData[i].unit
					this.dishAddfoodUnit = this.foodListData[i].unit
					this.dishAddfoddUnitWeight = this.foodListData[i].unit_weight
				}
			}
			
		},
		handleDoseInput(value){
			// 使用正则表达式来判断是否为整数
			//const regex = /^[-+]?\d*$/;
			//const regex = /^\d+(.\d{1,2})?$/;
			const regex = /^[0-9]+(.\d[0-9]{2})?$/;
			if (!regex.test(value)) {
				// 如果不是整数，则设置为上一个合法的值
				this.dishAddfoodDose = value.substring(0, value.length - 1);
			}
		},
		handleBatchDish(){
			this.$router.push('/dish/batch')
		},
		handleAvatarSuccess(res, file) {
			this.newadd_dish_pic = res.image_url
			this.show_newadd_dish_pic = "https://www.yyvvi.com/" + "uploads/" + res.image_url
		},
      beforeAvatarUpload(file) {
        const isPNG = file.type === 'image/png';
		const isGIF = file.type === 'image/gif';
		const isJPG = file.type === 'image/jpeg';
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isJPG && !isGIF && !isPNG) {
          this.$message.error('只能是图片格式（JPG/GIF/PNG）');
		   return false
        }
        if (!isLt2M) {
          this.$message.error('图片大小不能超过2MB');
		  return false
        }
        
      },
		handleChangeDishStatus(new_status,uuid){
			let show_label = '';
			let me = this;
			if ( new_status == '1' ){
				show_label = '确认启用这个菜品吗？';
			}else{
				show_label = '确认停用这个菜品吗？';
			}
			this.$confirm(show_label, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {

				let post={
					u_uuid:uuid,
					u_status:new_status
				}
				MN.setChangeDishStatus(post).then(res => {
					me.$message({
						type: 'success',
						message: '操作成功!'
					});
					me.initData(1);
				})
				
			}).catch(() => {
				me.$message({
					type: 'info',
					message: '已取消'
				});          
			});

		},
		handleLoginUser(){
			// let checkSession = sessionStorage.getItem('kindergarten_meals_session');
			// if ( !checkSession ){
			//   this.$router.push('/');
			// }
			let me = this;
			// let post={
			//   meals_session:sessionStorage.getItem('kindergarten_meals_session'),
					// 	meals_usertype:sessionStorage.getItem('kindergarten_meals_usertype')
					// }
			MN.kindergartenLoginUser().then(res => {
				//me.login_user_uuid = res.user_uuid;
				me.login_user_type = res.login_type;
				me.login_supplier_food = res.supplier_manage_food;
				me.login_supplier_dish = res.supplier_manage_dish;
				//me.login_user_name = res.user_name;
				// me.login_user_company_name = res.company_name;
				// me.login_user_kindergarten_name = res.kindergarten_name;

				if(  res.login_type == 'admin_user' ){
					me.disabled_tab_menu = false
					me.disabled_tab_template = false
					me.disabled_tab_dish = false
					me.disabled_tab_food = false
				}

				if(  res.login_type == 'staff_user' ){
					me.disabled_tab_menu = false
					me.disabled_tab_template = true
					me.disabled_tab_dish = true
					me.disabled_tab_food = true
				}

				if(  res.login_type == 'supplier_user' ){
					//me.disabled_tab_menu = true
					me.disabled_tab_template = true
					if ( res.supplier_manage_food == '0' ){
						me.disabled_tab_dish = false
					}else{
						me.disabled_tab_dish = true
					}

					if ( res.supplier_manage_dish == '0' ){
						me.disabled_tab_food = false
					}else{
						me.disabled_tab_food = true
					}
					
				}


				console.log(res)
				return
			})
		},
		handleSaveMenuToTemplate(){
			let me = this;
			if ( !this.new_template_name ){
				this.$message.error('请输入模板名称');
				return
			}
			let post={
				menu_uuid:me.new_totemplate_menu,
				template_name:me.new_template_name,
			}
			MN.saveMenuToTemplate(post).then(res => {
				if( res == 'new_template_ok' ){
					me.$message.success('成功保存为模板，可到模板库中查看');
					me.dialogSaveMenuTemplate = false
				}
			})
		},
		handleMenuToTemplate(menu_uuid){
			this.new_totemplate_menu = menu_uuid;
			this.new_template_name = '';
			this.dialogSaveMenuTemplate = true;
		},
		handleDetailTemplate(rowdata){
			
			this.template_detail_menu_zaocan=JSON.parse( rowdata.menu_zaocan );
			this.template_detail_menu_jiacan=JSON.parse( rowdata.menu_jiacan );
			this.template_detail_menu_wucan=JSON.parse( rowdata.menu_wucan );
			this.template_detail_menu_wudian=JSON.parse( rowdata.menu_wudian );
			this.template_detail_menu_wancan=JSON.parse( rowdata.menu_wancan );
			this.dialogShowDetailTemplate = true;
			console.log(rowdata);
		},
		handleDeleteTemplate(template_uuid){
			console.log(template_uuid);
			let me = this;
			
			this.$confirm('此操作将永久删除此模板, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
					let post={
						template_uuid:template_uuid
					}
					MN.handleDeleteTemplate(post).then(res => {
						me.initData(1);
					})
					
					this.$message({
						type: 'success',
						message: '删除成功!'
					});
			}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});          
			});
		},
		hadleSaveNewDish(){
			if (!this.select_dishtype){
				this.$message.error('请选择菜品类型');
				return
			}
			if (!this.newdish_name){
				this.$message.error('请输入菜品名称');
				return
			}
			if (!this.dish_sell_price){
				this.$message.error('请输入售价');
				return
			}
			if (this.foodcheckedListData.length == 0){
				this.$message.error('没有选择食材');
				return
			}
			let me = this;
			let post={
				pic_url:this.newadd_dish_pic,
				dish_name:this.newdish_name,
				dish_type:this.select_dishtype,
				sell_price:this.dish_sell_price,
				checkedlist:JSON.stringify(this.foodcheckedListData)
			}
			MN.setSaveNewDish(post).then(res => {
				console.log(res)
				if( res == 'error_input' ){
					this.$message.error('请检查提交的内容');
				}
				if( res == 'add_ok' ){
					me.initData(1);
					me.addDishDialogVisible = false
				}
			})
			

		},
		handleDeleteDish(dish_uuid){
			console.log(dish_uuid)
			this.$message.error(dish_uuid);
		},
		handleEditDish(rowdata){
			
			console.log("rowdata",rowdata);
			// return;

			let me = this;
			
			MN.getActivateTypeList().then(res => {
				me.dishTypeTableData = res.dishtype;
			})
			MN.getFoodListData().then(res => {
				let split_url = rowdata.pic_url;
				split_url = split_url.split("/uploads/");

				me.newadd_dish_pic = split_url[1]
				me.show_newadd_dish_pic = rowdata.pic_url
				me.edit_dish_uuid = rowdata.uuid;
				me.select_dishtype = rowdata.stype;
				me.newdish_name = rowdata.name;
				me.dish_sell_price = rowdata.price;
				me.dishAddfoodValue = '';
				me.dishAddfoodDose = '';
				me.dishAddfoodUnit = '';
				me.dishAddfoddUnitWeight = '';
				me.foodcheckedListData = [];
				for ( let i = 0 ;i < rowdata.foodlist.length; i++ ){
					me.foodcheckedListData.push({
						foodid:rowdata.foodlist[i].food_id,
						foodname:rowdata.foodlist[i].food_name,
						fooddose:rowdata.foodlist[i].dose,
						foodunit:rowdata.foodlist[i].unit,
						foodunitweight:rowdata.foodlist[i].unit_weight
					})
				}
				me.foodListData=res;
				me.addDishDialogVisible=true;
			})

		},
		handleSaveEditDish(){

			if (!this.select_dishtype){
				this.$message.error('请选择菜品类型');
				return
			}
			if (!this.newdish_name){
				this.$message.error('请输入菜品名称');
				return
			}
			if (!this.dish_sell_price){
				this.$message.error('请输入售价');
				return
			}
			if (this.foodcheckedListData.length == 0){
				this.$message.error('没有选择食材');
				return
			}
			let me = this;
			let post={
				pic_url:this.newadd_dish_pic,
				edit_dish_uuid:this.edit_dish_uuid,
				dish_name:this.newdish_name,
				dish_type:this.select_dishtype,
				sell_price:this.dish_sell_price,
				checkedlist:JSON.stringify(this.foodcheckedListData)
			}
			MN.setSaveEditDish(post).then(res => {
				console.log(res)
				if( res == 'error_input' ){
					this.$message.error('请检查提交的内容');
				}
				if( res == 'edit_ok' ){
					me.initData(1);
					me.addDishDialogVisible = false
				}
			})


			//this.$message.error('此功能还未配置权限');
		},
		handleDeleteDishAddFood(foodid){
			console.log(foodid);
			for ( let i = 0; i < this.foodListData.length;i++ ){
				if ( this.foodcheckedListData[i].foodid == foodid ){
					this.foodcheckedListData.splice([i],1)
				}
			}
		},
		hadleNewDishAddFood(){
			if ( !this.dishAddfoodValue || !this.dishAddfoodDose ){
				this.$message.error('请选择要添加的食材、输入用量');
				return
			}
			let getfoodname = '';
			let getfoodunit = 'g';
			let getfoodunitweight = '0';
			for ( let i = 0; i < this.foodListData.length;i++ ){
				if ( this.foodListData[i].id == this.dishAddfoodValue ){
					getfoodname = this.foodListData[i].name;
					//getfoodunit = this.foodListData[i].unit;
					//getfoodunitweight = this.foodListData[i].unit_weight;
				}
			}
			
			this.foodcheckedListData.push({
				foodid:this.dishAddfoodValue,
				foodname:getfoodname,
				fooddose:this.dishAddfoodDose,
				foodunit:getfoodunit,
				foodunitweight:getfoodunitweight
			})

			this.dishAddfoodValue = '';
			this.dishAddfoodDose = '';
			this.dishAddfoodUnit = '';
			this.dishAddfoddUnitWeight = '';
		},
		handleAddDish(){
			let me = this;
			MN.getActivateTypeList().then(res => {
				me.dishTypeTableData = res.dishtype;
			})
			MN.getFoodListData().then(res => {
				me.select_dishtype = '';
				me.newdish_name = '';
				me.dish_sell_price = '';
				me.dishAddfoodValue = '';
				me.dishAddfoodDose = '';
				me.dishAddfoodUnit = '';
				me.dishAddfoddUnitWeight = '';
				me.foodcheckedListData = [];
				
				me.foodListData=res;
				me.addDishDialogVisible=true;
			})
		},
		handleSearchDish(){
			this.loading=true;
			let me = this;
			let post={
				searchdish:this.searchDishValue,
			}
			MN.searchDishData(post).then(res => {
				me.tableDishData=res;
				me.loading=false;
			})
		},
		handleSearchFood(){

			this.loading=true;
			let me = this;
			let post={
				searchfood:this.searchFoodValue,
			}
			MN.searchFoodData(post).then(res => {
				me.tableFoodData=res;
				me.loading=false;
			})
			//this.initData('food');
		},
		handleTabClick(){
			// console.log(this.activeTab);
			// this.initData(this.activeTab);
		},
		initData(p_num){
			let me = this;
			this.loading = true;
			let post={
				p_num:p_num
			};
			MN.getDishList(post).then(res => {
				me.totalnum = res.total;
				me.tableDishData=res.data;
				me.tableDishData.forEach(items => {
					items.pic_url = "https://www.yyvvi.com/" + "uploads/" + items.pic_url
				});
				me.loading=false;
			})
			
		}
	}
}
</script>

<style scoped>

.cover_img{
	font-size: 20px;
	background-color:#EDECE6;
	width: 55px;
	height: 55px;
	line-height: 55px;
	text-align:center;
	border-radius: 4px;
}

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;background-color: red;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 18px;
    color: #4d7534;
    width: 85px;
    height: 85px;
    line-height: 85px;
    text-align: center;
	border: 1px dashed #a4c471;

  }
  .avatar {
    width: 85px;
    height:85px;
    display: block;
  }


.page_bar_in{
	margin: 9px 0 0 0;
}
.sikopop_title{
	font-size: 16px;
	padding-bottom:10px;
	font-weight: bolder;
}
.sikopop{
	background-color: #f3f3f3;
	padding: 10px;
	border: 1px solid #EDECE6;
	border-radius: 5px;
	margin-left: 6px;
}
.panel_dixz_label{
	width: 15px;
	float: left;
	text-align:center;
	font-size: 12px;
	color: #0C922E;
	font-weight: bolder;
}
.panel_dixz{
	border: 1px solid #cbcbcb;
	background:url('@/assets/icon_green_bg.png') repeat-y #EDECE6;
	background-size: 27px;
	text-align: center;
	padding: 6px;
	border-radius: 8px;
	margin-bottom: 8px;
	position: relative;
}
.sixk_row{
	width: 14.22%;
	font-size: 13px;
}

.mt20{
	margin-top: 20px;
}
.uicxo{
	font-size: 12px;
	color: #bababa;
}
.panel_menu_rowx{
	position: relative;
	overflow-x: auto;
	width: 97%;
	margin: 0 0 0 2%;
}
.panel_menu_rowbig{
	width: 1250px;
	padding-bottom:16px;
}
.week_txo{
	position: absolute;
	left: 5px;
	top: -18px;
	font-size: 12px;
	color: #999999;
}
.dis_dish_row{ 
	position: relative;
	border-bottom: 1px dashed #cccccc;
	padding: 4px;
	text-align: center;
	font-size: 13px;
	margin-bottom: 5px;
	border-radius: 4px;
	
}
.panel_day_block{
	position: relative;
	background-color: #ffffff;
	box-shadow: 1px 1px 3px #b9b9b9;
	border-radius: 8px;
	min-height: 60px;
	width: 145px;
	padding: 5px;
	float: left;
	margin: 20px 0 6px 14px;
}
::v-deep .el-tabs__active-bar {
	cursor: pointer;
	background-color: #03BC5B;
}
::v-deep .el-tabs__item.is-active {
	color: #03BC5B;
	font-weight: bolder;
	cursor: pointer;
}
::v-deep .el-tabs__item:hover {
	color: #03BC5B;
	cursor: pointer;
}

</style>
