<template>
	<div>

	<el-container class="data_skc">

		<el-aside class="aside-style" width="200px">
			<common-nav selec="1"></common-nav>
		</el-aside>
		
		<el-container>

			<el-header class="common_header">
				<common-header></common-header>
			</el-header>
			
			<el-main>

				<el-row :gutter="24" class="page_bar">
					<el-col :span="20" class="page_bar_title"><i @click="handleGoBack()" class="el-icon-back showhand" style="font-size:22px"></i>  修改菜谱模板</el-col>
					<el-col :span="4"></el-col>
				</el-row>

				<el-row :gutter="24" style="margin-top:25px">
					<el-col :span="10">
						菜谱模板名称：
						<el-input size="small" v-model="template_name" placeholder="例：优选系列周菜单1号"></el-input>
					</el-col>
				</el-row>

				<el-tabs  class="mt20" v-model="activeName">
					<el-tab-pane label="菜谱内容" name='customer'>

						<div class="panel_exo">
							<div class="panel_dixz">
								<div class="panel_dixz_label">早餐</div>
								<div class="panel_menu_rowx">
									<div class="panel_menu_rowbig">
										<div class="panel_day_block" v-for="item in menu_zaocan" :key="item.id" >
											<div class="week_txo">{{item.label}}</div>
											<div class="button_add_dish" @click="handleAddDish(item.id,'menu_zaocan')">加菜</div>
											<div class="day_block_empty" v-if="item.value.length==0">无菜品</div>
											<div class="dis_dish_row" v-for="(rows, index2) in item.value" :key="index2" >
												{{rows.dish_name}}
												<div class="button_edit_dish" @click="handleEditDish(item.id,index2,'menu_zaocan',rows.dish_id)"></div>
												<div class="button_del_dish" @click="handleDelDish(item.id,index2,'menu_zaocan',rows.dish_id)"></div>
											</div>
										</div>
										<div class="clear"></div>
									</div>
								</div>
							</div>
							<div class="panel_dixz">
								<div class="panel_dixz_label">加餐</div>
								<div class="panel_menu_rowx">
									<div class="panel_menu_rowbig">
										<div class="panel_day_block" v-for="item in menu_jiacan" :key="item.id" >
											<div class="week_txo">{{item.label}}</div>
											<div class="button_add_dish" @click="handleAddDish(item.id,'menu_jiacan')">加菜</div>
											<div class="day_block_empty" v-if="item.value.length==0">无菜品</div>
											<div class="dis_dish_row" v-for="(rows, index2) in item.value" :key="index2" >
												{{rows.dish_name}}
												<div class="button_edit_dish" @click="handleEditDish(item.id,index2,'menu_jiacan',rows.dish_id)"></div>
												<div class="button_del_dish" @click="handleDelDish(item.id,index2,'menu_jiacan',rows.dish_id)"></div>
											</div>
										</div>
										<div class="clear"></div>
									</div>
								</div>
							</div>
							<div class="panel_dixz">
								<div class="panel_dixz_label">午餐</div>
								<div class="panel_menu_rowx">
									<div class="panel_menu_rowbig">
										<div class="panel_day_block" v-for="item in menu_wucan" :key="item.id" >
											<div class="week_txo">{{item.label}}</div>
											<div class="button_add_dish" @click="handleAddDish(item.id,'menu_wucan')">加菜</div>
											<div class="day_block_empty" v-if="item.value.length==0">无菜品</div>
											<div class="dis_dish_row" v-for="(rows, index2) in item.value" :key="index2" >
												{{rows.dish_name}}
												<div class="button_edit_dish" @click="handleEditDish(item.id,index2,'menu_wucan',rows.dish_id)"></div>
												<div class="button_del_dish" @click="handleDelDish(item.id,index2,'menu_wucan',rows.dish_id)"></div>
											</div>
										</div>
										<div class="clear"></div>
									</div>
								</div>
							</div>
							<div class="panel_dixz">
								<div class="panel_dixz_label">午点</div>
								<div class="panel_menu_rowx">
									<div class="panel_menu_rowbig">
										<div class="panel_day_block" v-for="item in menu_wudian" :key="item.id" >
											<div class="week_txo">{{item.label}}</div>
											<div class="button_add_dish" @click="handleAddDish(item.id,'menu_wudian')">加菜</div>
											<div class="day_block_empty" v-if="item.value.length==0">无菜品</div>
											<div class="dis_dish_row" v-for="(rows, index2) in item.value" :key="index2" >
												{{rows.dish_name}}
												<div class="button_edit_dish" @click="handleEditDish(item.id,index2,'menu_wudian',rows.dish_id)"></div>
												<div class="button_del_dish" @click="handleDelDish(item.id,index2,'menu_wudian',rows.dish_id)"></div>
											</div>
										</div>
										<div class="clear"></div>
									</div>
								</div>
							</div>
							<div class="panel_dixz">
								<div class="panel_dixz_label">晚餐</div>
								<div class="panel_menu_rowx">
									<div class="panel_menu_rowbig">
										<div class="panel_day_block" v-for="item in menu_wancan" :key="item.id" >
											<div class="week_txo">{{item.label}}</div>
											<div class="button_add_dish" @click="handleAddDish(item.id,'menu_wancan')">加菜</div>
											<div class="day_block_empty" v-if="item.value.length==0">无菜品</div>
											<div class="dis_dish_row" v-for="(rows, index2) in item.value" :key="index2" >
												{{rows.dish_name}}
												<div class="button_edit_dish" @click="handleEditDish(item.id,index2,'menu_wancan',rows.dish_id)"></div>
												<div class="button_del_dish" @click="handleDelDish(item.id,index2,'menu_wancan',rows.dish_id)"></div>
											</div>
										</div>
										<div class="clear"></div>
									</div>
								</div>
							</div>
						</div>	

						<el-row style="margin-top:30px">
							<el-col :span="7">
								<el-button style="width:100%" type="success" @click="handleSaveMenu" >保存模板</el-button>
							</el-col>
							<!-- <el-col :span="5" :offset="1" >
								<el-button v-if="login_user_type == 'admin_user'"  style="width:200px" plain type="primary" @click="handleEditToTemplate" >存为模板</el-button>
							</el-col> -->

						</el-row>
					</el-tab-pane>
					<!-- <el-tab-pane label="参与客户">参与客户</el-tab-pane> -->
					<!-- 
					<el-tab-pane label="角色管理">角色管理</el-tab-pane>
					<el-tab-pane label="定时任务补偿">定时任务补偿</el-tab-pane> -->
				</el-tabs>
			</el-main>

			<el-footer> copyright &copy; yyvvi.com </el-footer>
		</el-container>
	</el-container>

	
	<el-dialog
	title="添加菜品"
	:visible.sync="showAddDishDialog" :close-on-click-modal="false"
	width="50%">
	<el-row>
		<el-col :span="24" style="text-align: center">
			请选择要添加的菜品：
			<el-select v-model="dish_new_value" filterable placeholder="请选择">
				<el-option
				v-for="item in DishSelectData"
				:key="item.uuid"
				:label="item.name"
				:value="item.uuid">
				</el-option>
			</el-select>
		</el-col>
	</el-row>
	<span slot="footer" class="dialog-footer">
		<el-button @click="showAddDishDialog = false">取 消</el-button>
		<el-button type="primary" @click="handleAddtoMenu">确 定</el-button>
	</span>
	</el-dialog>


	<el-dialog
	title="修改菜品"
	:visible.sync="showEditDishDialog" :close-on-click-modal="false"
	width="50%">
	<el-row>
		<el-col :span="24" style="text-align: center">
			请选择要修改的菜品：
			<el-select v-model="dish_edit_value" filterable placeholder="请选择">
				<el-option
				v-for="item in DishSelectData"
				:key="item.uuid"
				:label="item.name"
				:value="item.uuid">
				</el-option>
			</el-select>
		</el-col>
	</el-row>
	<span slot="footer" class="dialog-footer">
		<el-button @click="showEditDishDialog = false">取 消</el-button>
		<el-button type="primary" @click="handleEdittoMenu">确 定</el-button>
	</span>
	</el-dialog>


	<el-dialog
	title="保存菜谱到模板库"
	:visible.sync="dialogSaveMenuTemplate" :close-on-click-modal="false"
	width="30%">
	<el-row>
		<el-col :span="18">
			请给菜谱输入名称：
			<el-input v-model="new_template_name" placeholder="例：优选系列周菜单1号"></el-input>
		</el-col>
	</el-row>
	<span slot="footer" class="dialog-footer">
		<el-button @click="dialogSaveMenuTemplate = false">取 消</el-button>
		<el-button type="primary" @click="handleSaveEditMenuToTemplate">确 定</el-button>
	</span>
	</el-dialog>


	</div>
</template>

<script>
import CommonNav from "@/components/Nav.vue";
import CommonHeader from "@/components/Header.vue";
import * as MN from '@/api/common';
import { toRaw } from 'vue';

export default {
    name: 'crm', 
	props:['selec'],
	data() {
		return {
			template_name:'',
			login_user_type:'',
			new_template_name:'',
			dialogSaveMenuTemplate:false,
			edit_start_date:'',
			edit_end_date:'',
			edit_kindergarten_name:'',
			dish_edit_value:'',
			showEditDishDialog:false,
			used_block_id:'',
			used_row_id:'',
			used_menu_type:'',
			dish_new_value:'',
			DishSelectData:[],
			showAddDishDialog:false,
			menu_zaocan:[
				{id:1,label:'周一 Mon',value:[]},
				{id:2,label:'周二 Tue',value:[]},
				{id:3,label:'周三 Wed',value:[]},
				{id:4,label:'周四 Thu',value:[]},
				{id:5,label:'周五 Mon',value:[]},
				{id:6,label:'周六 Sat',value:[]},
				{id:7,label:'周日 Sun',value:[]}
			],
			menu_jiacan:[
				{id:1,label:'周一 Mon',value:[]},
				{id:2,label:'周二 Tue',value:[]},
				{id:3,label:'周三 Wed',value:[]},
				{id:4,label:'周四 Thu',value:[]},
				{id:5,label:'周五 Mon',value:[]},
				{id:6,label:'周六 Sat',value:[]},
				{id:7,label:'周日 Sun',value:[]}
			],
			menu_wucan:[
				{id:1,label:'周一 Mon',value:[]},
				{id:2,label:'周二 Tue',value:[]},
				{id:3,label:'周三 Wed',value:[]},
				{id:4,label:'周四 Thu',value:[]},
				{id:5,label:'周五 Mon',value:[]},
				{id:6,label:'周六 Sat',value:[]},
				{id:7,label:'周日 Sun',value:[]}
			],
			menu_wudian:[
				{id:1,label:'周一 Mon',value:[]},
				{id:2,label:'周二 Tue',value:[]},
				{id:3,label:'周三 Wed',value:[]},
				{id:4,label:'周四 Thu',value:[]},
				{id:5,label:'周五 Mon',value:[]},
				{id:6,label:'周六 Sat',value:[]},
				{id:7,label:'周日 Sun',value:[]}
			],
			menu_wancan:[
				{id:1,label:'周一 Mon',value:[]},
				{id:2,label:'周二 Tue',value:[]},
				{id:3,label:'周三 Wed',value:[]},
				{id:4,label:'周四 Thu',value:[]},
				{id:5,label:'周五 Mon',value:[]},
				{id:6,label:'周六 Sat',value:[]},
				{id:7,label:'周日 Sun',value:[]}
			],
			menuWeek:'',
			activeName:'customer',
			loading:true,
			boxshow: false, 
			curTipsID: '',
			userCount:0,
			detailData: [],
			detailList:[],
			detailUUID:this.$route.query.uuid,
			topicsLink:'',
			select_kindergarten_options: [],
			select_kindergarten_value: ''
		}
	},
	components: {
		CommonNav,
		CommonHeader
	},

	created() {
		this.initData()
		this.handleLoginUser();
	},

	mounted() {

	},

	methods: {
		handleLoginUser(){
			// let checkSession = sessionStorage.getItem('kindergarten_meals_session');
			// if ( !checkSession ){
			//   this.$router.push('/');
			// }
			let me = this;
			// let post={
			//   meals_session:sessionStorage.getItem('kindergarten_meals_session'),
					// 	meals_usertype:sessionStorage.getItem('kindergarten_meals_usertype')
					// }
			MN.kindergartenLoginUser().then(res => {
				//me.login_user_uuid = res.user_uuid;
				me.login_user_type = res.login_type;
			
				
			})
		},
		handleSaveEditMenuToTemplate(){
			let me = this;
			if ( !this.new_template_name ){
				this.$message.error('请输入模板名称');
				return
			}
			
			let post={
				template_name:me.new_template_name,
				menu_zaocan:JSON.stringify(this.menu_zaocan),
				menu_jiacan:JSON.stringify(this.menu_jiacan),
				menu_wucan:JSON.stringify(this.menu_wucan),
				menu_wudian:JSON.stringify(this.menu_wudian),
				menu_wancan:JSON.stringify(this.menu_wancan)
			}
			MN.saveEditMenuToTemplate(post).then(res => {
				if( res == 'new_template_ok' ){
					me.$message.success('成功保存为模板，可到模板库中查看');
					me.dialogSaveMenuTemplate = false
				}
			})
		},
		handleEditToTemplate(){
			this.new_template_name = '';
			this.dialogSaveMenuTemplate = true;
		},
		handleSaveMenu(){
			console.log("!12");
			console.log(this.menuWeek);

			if(!this.template_name){
				this.$message.error('请输入模板名称');
				return
			}

			let me = this;
			this.loading = false
			let post={
				template_uuid:me.detailUUID,
				template_name:me.template_name,
				menu_zaocan:JSON.stringify(this.menu_zaocan),
				menu_jiacan:JSON.stringify(this.menu_jiacan),
				menu_wucan:JSON.stringify(this.menu_wucan),
				menu_wudian:JSON.stringify(this.menu_wudian),
				menu_wancan:JSON.stringify(this.menu_wancan)
			}
			MN.getHandleSaveEditMenuTemplate(post).then(res => {
				if( res == 'edit_ok' ){
					this.$message.success('修改保存成功');
					this.$router.back(-1)
				}
			})
		},
		handleEdittoMenu(){
			if ( !this.dish_edit_value ){
				this.$message.error('请选择要添加的菜品');
				return
			}
			
			let dish_name = '';
			for ( let i=0; i < this.DishSelectData.length; i++ ){
				if ( this.dish_edit_value == this.DishSelectData[i].uuid ){
					dish_name = this.DishSelectData[i].name;
				}
			}

			if (this.used_menu_type == 'menu_zaocan'){
				for ( let i=0; i < this.menu_zaocan.length; i++ ){
					if ( this.used_block_id == this.menu_zaocan[i].id ){
						this.menu_zaocan[i].value[this.used_row_id].dish_id = this.dish_edit_value;
						this.menu_zaocan[i].value[this.used_row_id].dish_name = dish_name;
					}
				}
			}

			if (this.used_menu_type == 'menu_jiacan'){
				for ( let i=0; i < this.menu_jiacan.length; i++ ){
					if ( this.used_block_id == this.menu_jiacan[i].id ){
						this.menu_jiacan[i].value[this.used_row_id].dish_id = this.dish_edit_value;
						this.menu_jiacan[i].value[this.used_row_id].dish_name = dish_name;
					}
				}
			}

			if (this.used_menu_type == 'menu_wucan'){
				for ( let i=0; i < this.menu_wucan.length; i++ ){
					if ( this.used_block_id == this.menu_wucan[i].id ){
						this.menu_wucan[i].value[this.used_row_id].dish_id = this.dish_edit_value;
						this.menu_wucan[i].value[this.used_row_id].dish_name = dish_name;
					}
				}
			}

			if (this.used_menu_type == 'menu_wudian'){
				for ( let i=0; i < this.menu_wudian.length; i++ ){
					if ( this.used_block_id == this.menu_wudian[i].id ){
						this.menu_wudian[i].value[this.used_row_id].dish_id = this.dish_edit_value;
						this.menu_wudian[i].value[this.used_row_id].dish_name = dish_name;
					}
				}
			}

			if (this.used_menu_type == 'menu_wancan'){
				for ( let i=0; i < this.menu_wancan.length; i++ ){
					if ( this.used_block_id == this.menu_wancan[i].id ){
						this.menu_wancan[i].value[this.used_row_id].dish_id = this.dish_edit_value;
						this.menu_wancan[i].value[this.used_row_id].dish_name = dish_name;
					}
				}
			}

			this.dish_edit_value = '';
			this.showEditDishDialog = false;
			this.$message.success('修改成功');

		},
		handleAddtoMenu(){
			if ( !this.dish_new_value ){
				this.$message.error('请选择要添加的菜品');
				return
			}

			let dish_name = '';
			for ( let i=0; i < this.DishSelectData.length; i++ ){
				if ( this.dish_new_value == this.DishSelectData[i].uuid ){
					dish_name = this.DishSelectData[i].name;
				}
			}

			if (this.used_menu_type == 'menu_zaocan'){
				for ( let i=0; i < this.menu_zaocan.length; i++ ){
					if ( this.used_block_id == this.menu_zaocan[i].id ){
						this.menu_zaocan[i].value.push({dish_id:this.dish_new_value,dish_name:dish_name})
					}
				}
			}

			if (this.used_menu_type == 'menu_jiacan'){
				for ( let i=0; i < this.menu_jiacan.length; i++ ){
					if ( this.used_block_id == this.menu_jiacan[i].id ){
						this.menu_jiacan[i].value.push({dish_id:this.dish_new_value,dish_name:dish_name})
					}
				}
			}

			if (this.used_menu_type == 'menu_wucan'){
				for ( let i=0; i < this.menu_wucan.length; i++ ){
					if ( this.used_block_id == this.menu_wucan[i].id ){
						this.menu_wucan[i].value.push({dish_id:this.dish_new_value,dish_name:dish_name})
					}
				}
			}

			if (this.used_menu_type == 'menu_wudian'){
				for ( let i=0; i < this.menu_wudian.length; i++ ){
					if ( this.used_block_id == this.menu_wudian[i].id ){
						this.menu_wudian[i].value.push({dish_id:this.dish_new_value,dish_name:dish_name})
					}
				}
			}

			if (this.used_menu_type == 'menu_wancan'){
				for ( let i=0; i < this.menu_wancan.length; i++ ){
					if ( this.used_block_id == this.menu_wancan[i].id ){
						this.menu_wancan[i].value.push({dish_id:this.dish_new_value,dish_name:dish_name})
					}
				}
			}

			this.dish_new_value = '';
			this.showAddDishDialog = false;
			this.$message.success('添加成功');
		},
		handleAddDish(block_id,menu_type){
			this.used_block_id = block_id;
			this.used_menu_type = menu_type
			MN.getDishSelectData().then(res => {
				this.DishSelectData=res.data;
			})
			console.log("block_id",block_id)
			this.showAddDishDialog = true
		},
		handleEditDish(block_id,row_id,menu_type,uuid){
			this.dish_edit_value = uuid;
			this.used_block_id = block_id;
			this.used_menu_type = menu_type
			this.used_row_id = row_id;
			MN.getDishSelectData().then(res => {
				this.DishSelectData=res.data;
			})
			this.showEditDishDialog = true
			console.log("block_id",block_id)
			console.log("row_id",row_id)
			console.log("menu_type",block_id)
			console.log("this.dish_edit_value",this.dish_edit_value)
		},
		handleDelDish(block_id,row_id,menu_type,uuid){
			this.used_block_id = block_id;
			this.used_menu_type = menu_type
			this.used_row_id = row_id;
			this.$confirm('确认删除吗?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {

				if (this.used_menu_type == 'menu_zaocan'){
					for ( let i=0; i < this.menu_zaocan.length; i++ ){
						if ( this.used_block_id == this.menu_zaocan[i].id ){
							this.menu_zaocan[i].value.splice(this.used_row_id, 1);
						}
					}
				}

				if (this.used_menu_type == 'menu_jiacan'){
					for ( let i=0; i < this.menu_jiacan.length; i++ ){
						if ( this.used_block_id == this.menu_jiacan[i].id ){
							this.menu_jiacan[i].value.splice(this.used_row_id, 1);
						}
					}
				}

				if (this.used_menu_type == 'menu_wucan'){
					for ( let i=0; i < this.menu_wucan.length; i++ ){
						if ( this.used_block_id == this.menu_wucan[i].id ){
							this.menu_wucan[i].value.splice(this.used_row_id, 1);
						}
					}
				}

				if (this.used_menu_type == 'menu_wudian'){
					for ( let i=0; i < this.menu_wudian.length; i++ ){
						if ( this.used_block_id == this.menu_wudian[i].id ){
							this.menu_wudian[i].value.splice(this.used_row_id, 1);
						}
					}
				}

				if (this.used_menu_type == 'menu_wancan'){
					for ( let i=0; i < this.menu_wancan.length; i++ ){
						if ( this.used_block_id == this.menu_wancan[i].id ){
							this.menu_wancan[i].value.splice(this.used_row_id, 1);
						}
					}
				}

				this.$message({
					type: 'success',
					message: '删除成功!'
				});
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消删除'
				});          
			});
			console.log("block_id",block_id)
			console.log("row_id",row_id)
		},
		initData(){
			let me = this;
			this.loading = false
			let post={
				template_uuid:this.detailUUID
			}
			MN.getMenuTemplateDetail(post).then(res => {
				// me.edit_start_date = res.start_date
				// me.edit_end_date = res.end_date
				// me.edit_kindergarten_name = res.company_name
				me.template_name = res.name;
				me.menu_zaocan = JSON.parse(res.menu_zaocan)
				me.menu_jiacan = JSON.parse(res.menu_jiacan) 
				me.menu_wucan = JSON.parse(res.menu_wucan) 
				me.menu_wudian = JSON.parse(res.menu_wudian) 
				me.menu_wancan = JSON.parse(res.menu_wancan) 
			})
		},
		handleGoBack(){
			this.$router.back(-1)
		}
	}
}
</script>

<style scoped>
.clear{ clear: both; }
.day_block_empty{
	position: absolute;
	left: 0;
	top: 30%;
	text-align: center;
	width: 100%;
	color: #b3b3b3;
	font-size: 13px;
}
.button_edit_dish{
	width: 18px;
	height: 18px;
	background:url('@/assets/icon_green_edit.png') no-repeat;
	background-size: 100%;
	position: absolute;
	right:30px;
	top: 4px;
	cursor: pointer;
}
.button_del_dish{
	background:url('@/assets/icon_green_del.png') no-repeat;
	background-size: 100%;
	width: 18px;
	height: 18px;
	position: absolute;
	right: 5px;
	top: 4px;
	cursor: pointer;
}
.dis_dish_row{ 
	position: relative;
	border: 1px solid #cccccc;
	padding: 4px;
	text-align: left;
	font-size: 13px;
	margin-bottom: 5px;
	border-radius: 4px;
}
.button_add_dish{
	position: absolute;
	left: 0;
	bottom: -33px;
	width: 99%;
	padding:6px 0 6px 0;
	font-size: 13px;
	background:url('@/assets/icon_green_add.png') no-repeat #F4F8EE;
	background-position:30% 50%;
	background-size: 16px;
	border: 1px solid #54C651;
	color: #2C9E46;
	font-weight: bolder;
	border-radius: 4px;
	cursor: pointer;
}
.panel_menu_rowx{
	position: relative;
	overflow-x: auto;
	width: 97%;
	margin: 0 0 0 2%;
}
.panel_menu_rowbig{
	width: 1250px;
	padding-bottom:16px;
}
.week_txo{
	position: absolute;
	left: 5px;
	top: -18px;
	font-size: 12px;
	color: #999999;
}
.panel_day_block{
	position: relative;
	background-color: #ffffff;
	box-shadow: 1px 1px 3px #b9b9b9;
	border-radius: 8px;
	min-height: 60px;
	width: 145px;
	padding: 5px;
	float: left;
	margin: 20px 0 33px 14px;
}
.panel_dixz_label{
	width: 15px;
	float: left;
	text-align:center;
	font-size: 12px;
	color: #0C922E;
	font-weight: bolder;
}
.panel_dixz{
	border: 1px solid #cbcbcb;
	background:url('@/assets/icon_green_bg.png') repeat-y #EDECE6;
	background-size: 27px;
	text-align: center;
	padding: 6px;
	border-radius: 8px;
	margin-bottom: 8px;
	position: relative;
}
.panel_exo{
	border-radius: 10px;
	padding: 20px;
	background-color: #f9f9f9;
}
.buttonexcel{
	background: none;
	background-color: #409eff;
	width: 90px;
	color: #fff;
	border: none;
	border-radius: 4px;
	padding: 5px;
	cursor: pointer;
}
.page_bar_title{
	color: #575757;
}
.mt20{
	margin-top: 20px;
}
.detail_row{
	background-color: #f8f8f8;
	border-radius: 10px;
	padding: 8px;
	box-shadow: 0 2px 5px #eeeeee;
	text-align: left;
}

::v-deep .el-tabs__active-bar {
	cursor: pointer;
	background-color: #03BC5B;
}
::v-deep .el-tabs__item.is-active {
	color: #03BC5B;
	font-weight: bolder;
	cursor: pointer;
}
::v-deep .el-tabs__item:hover {
	color: #03BC5B;
	cursor: pointer;
}

</style>
