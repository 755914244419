import axios from 'axios'
// import store from '../store'
import { Message, MessageBox } from 'element-ui'


// var auth = window.localStorage.getItem("auth")
// console.log(store.state.loginBase)
// var openid = window.localStorage.getItem("openid")
const service = axios.create({
  baseURL: '/api',
  timeout: 30000,
  // headers: {
  //   'auth' : auth, 
  // }
})

// axios.defaults.headers.AothId = 'auid'
//localstorage.getItem("sessionId");

// 添加一个请求拦截器
service.interceptors.request.use(
    
    config => {

      // if( window.localStorage.getItem("hmcopenid") ){
      //   config.headers.openid = window.localStorage.getItem("hmcopenid")
      // }

      // if( store.state.openid ){
      //   config.headers.openid = store.state.openid//'oLwbcwQ36HJuYACLpF8dYMS9vTX0'
      // }

      // config.headers = {        
      //   'openid' : 'oLwbcwQ36HJuYACLpF8dYMS9vTX0',
      // }
      
      return config
    },
    error => {
      // Do something with request error
      console.log(error) // for debug
      Promise.reject(error)
    }
)


// 再添加一个返回拦截器
service.interceptors.response.use(
    response => {
      const res = response.data;
      if( res != 'isOptions' ){
        return response.data; 
      }
      
      // console.log(res);
      // if(res.code==200){
      //   return response.data;      
      // }else{        
      //   Message({
      //     message: res.data,
      //     type: 'error',
      //   })
      //   // location.href="#/login";// 权限 需退出登录
      // }
      
    },
    error => {
      console.log('err' + error) // for debug
      Message({
        message: error.message,
        type: 'error',
      })
      return Promise.reject(error.message)
    }
)

export default service
