<template>
	<div>

	<el-container class="data_skc">

		<el-aside class="aside-style" width="200px">
			<common-nav selec="1"></common-nav>
		</el-aside>
		
		<el-container>

			<el-header class="common_header">
				<common-header></common-header>
			</el-header>
			
			<el-main>

				<el-row :gutter="24" class="page_bar">
					<el-col :span="4" class="page_bar_title">菜谱列表</el-col>
					<el-col :span="6" :offset="14">
							<el-button size="small" type="success"  icon="el-icon-plus" @click="$router.push('/menu/add')" >新建空菜谱</el-button>
							<el-button size="small" type="primary"  icon="el-icon-plus" @click="handleShowMenuTemplate" >用模板创建菜谱</el-button>
					</el-col>
					<!-- <el-col :span="4">
						<el-input placeholder="输入标题查找" v-model="searchMenuValue" class="input-with-select" size="small" >
							<el-button slot="append" icon="el-icon-search" @click="handleSearchMenu"></el-button>
						</el-input>
					</el-col> -->
				</el-row>

			
					<el-table v-loading="loading" :data="tableMenuData" size="medium" stripe highlight-current-row>					
						<el-table-column prop="status" label="状态" width="80" >
							<template slot-scope="scope">
								<label v-if="scope.row.status == 1 & scope.row.is_order == 0">已创建</label>
								<label v-if="scope.row.status == 1 & scope.row.is_order == 1" style="color:green">已下单</label>
							</template>
						</el-table-column>
						<el-table-column prop="topics_type" label="周菜谱" >
							<template slot-scope="scope">
								{{scope.row.start_date}} 至 {{scope.row.end_date}}<br>
								<span class="uicxo">识别码：{{scope.row.uuid}}</span>
							</template>
						</el-table-column>
						<el-table-column prop="company_name" label="所属幼儿园"></el-table-column>
						<el-table-column prop="create_user"  label="创建人" ></el-table-column>
						<el-table-column prop="create_date" label="创建日期" ></el-table-column>
						<el-table-column label="操作" width="390">
							<template slot-scope="scope">
								<el-button type="primary" @click="handleMenuDetail(scope.row)" size="mini" plain>详情</el-button>
								<!-- <el-button type="primary" @click="handleMenuDetail(scope.row)" size="mini" plain>导出Excel</el-button> -->
								
								<el-button type="primary" v-if="login_user_type == 'admin_user'" size="mini" @click="handleMenuToTemplate(scope.row.uuid)" plain>存为模板</el-button>
								<el-button type="warning" @click="handleMenuEdit(scope.row)" size="mini" plain>修改</el-button>
								<el-button type="danger" v-if="login_user_type == 'admin_user'" @click="handleMenuDelete(scope.row.uuid)" size="mini" plain>删除</el-button>
								<form class="form_xcv" :action="'https://www.yyvvi.com/kindergarten/index/exportToExcel?menuid='+scope.row.uuid" method="post" enctype="multipart/form-data">
										<input type="submit" value="导出Excel" class="buttonexcel">
								</form>
								<!-- <el-button type="warning" size="mini" plain>用量</el-button> -->
								<!-- <el-button type="warning" size="mini" plain>下单</el-button> -->
								<!-- <el-button type="primary" size="mini" plain>修改</el-button>
								<el-button type="danger" size="mini" plain>删除</el-button> -->
							</template>
						</el-table-column>
					</el-table>

					<el-pagination
						background
						layout="prev, pager, next"
						:total="10" class="page_pagination">
					</el-pagination>
						
	</el-main>

			<el-footer> copyright &copy; yyvvi.com </el-footer>
		</el-container>
	</el-container>

	<el-dialog
	title="食材"
	:visible.sync="addFoodDialogVisible"
	width="40%"  :close-on-click-modal="false">
	<el-row>
		<el-col :span="10">
			食材分类:
			<el-select v-model="select_foodtype" placeholder="显示全部" size="small">
				<el-option
				v-for="item in foodtype_options"
				:key="item.value"
				:label="item.label"
				:value="item.value"
				:disabled="item.disabled">
				</el-option>
			</el-select>	
		</el-col> 
		<el-col :span="13" :offset="1">
			食材名称:
			<el-input v-model="newfood_name"  size="small" placeholder="请输入内容"></el-input>
		</el-col>
	</el-row>
	<el-row class="mt20">
		<el-col :span="8">
			每500g采购价:
			<el-input-number  v-model="buy_price"  :precision="2"  size="small" placeholder="请输入内容"></el-input-number>
		</el-col>
	</el-row>
	<span slot="footer" class="dialog-footer">
		<el-button @click="addFoodDialogVisible = false">取 消</el-button>
		
		<el-button v-if="!edit_food_id" type="primary" @click="hadleSaveNewFood">新增食材</el-button>
		<el-button v-if="!!edit_food_id" type="primary" @click="handleSaveEditFood">保存修改</el-button>
	</span>
	</el-dialog>


	<el-dialog
	title="菜品"
	:visible.sync="addDishDialogVisible"
	width="40%" :close-on-click-modal="false">
	<el-row>
		<el-col :span="10">
			菜品分类:
			<el-select v-model="select_dishtype" placeholder="显示全部" size="small">
				<el-option
				v-for="item in dishtype_options"
				:key="item.value"
				:label="item.label"
				:value="item.value"
				:disabled="item.disabled">
				</el-option>
			</el-select>
		</el-col> 
		<el-col :span="13" :offset="1">
			菜品名称:
			<el-input v-model="newdish_name"  size="small" placeholder="请输入内容"></el-input>
		</el-col>
	</el-row>
	<el-row class="mt20">
		<el-col :span="3">每份售价:</el-col>
		<el-col :span="10">
			<el-input-number  v-model="dish_sell_price"  :precision="2"  size="small" placeholder="请输入内容"></el-input-number>
		</el-col>
	</el-row>
	<el-row class="mt20">
		<el-col :span="3">包含食材:</el-col>
		<el-col :span="20">
			<el-row>
				<el-col :span="7">
					<el-select v-model="dishAddfoodValue" filterable  placeholder="显示全部" size="small" style="width:100%">
						<el-option
						v-for="item in foodListData"
						:key="item.id"
						:label="item.name"
						:value="item.id">
						</el-option>
					</el-select>
				</el-col>
				<el-col :span="11" :offset="1">
					 <el-input placeholder="请输入内容" style="width:100%" size="small"  v-model="dishAddfoodDose">
						<template slot="prepend">用量（g）</template>
					</el-input>
				</el-col>
				<el-col :span="2" :offset="1">
					<el-button size="small" plain type="primary" @click="hadleNewDishAddFood">添加食材</el-button>
				</el-col>
			</el-row>
			 <el-table
				:data="foodcheckedListData"
				border
				style="width: 100%; margin-top:8px" size="mini">
				<el-table-column
				prop="foodname"
				label="已选食材">
				</el-table-column>
				<el-table-column
				prop="fooddose"
				label="每份用量">
				</el-table-column>
				<el-table-column
				prop="address"
				label="操作" width="100">
					<template slot-scope="scope">
						<el-button type="danger" size="mini" @click="handleDeleteDishAddFood(scope.row.foodid)"  plain>删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</el-col>
	</el-row>
	<span slot="footer" class="dialog-footer">
		<el-button @click="addDishDialogVisible = false">取 消</el-button>
		
		<el-button v-if="!edit_food_id" type="primary" @click="hadleSaveNewDish">保存新菜品</el-button>
		<el-button v-if="!!edit_food_id" type="primary" @click="handleSaveEditDish">保存修改</el-button>
	</span>
	</el-dialog>



	<el-dialog
	title="请从左侧列表选择要使用的菜谱模板"
	:visible.sync="dialogMenuTemplate"
	width="90%" :close-on-click-modal="false" top="20px" center>

	<el-row>
		<el-col :span="6" >
			<el-table
				:data="templateTableData"
				border
				@cell-mouse-enter="templateTableEnter"
				style="width: 100%;" size="mini">
				<el-table-column
				prop="name"
				label="模板库">
				</el-table-column>
				<el-table-column label="操作" width="80" >
					<template slot-scope="scope">
						<el-button type="primary" size="mini" @click="handleUseTemplate(scope.row.uuid)" plain>使用</el-button>
					</template>
				</el-table-column>
			</el-table>
		</el-col>
		<el-col :span="18">
			<div class="sikopop">
				<div class="sikopop_title">菜谱预览：{{menu_template_name}}</div>
				<div class="panel_exo">
					<div class="panel_dixz">
						<div class="panel_dixz_label">早餐</div>
						<div class="panel_menu_rowx">
							<div class="panel_menu_rowbig">
								<div class="panel_day_block" v-for="item in menu_zaocan" :key="item.id" >
									<div class="week_txo">{{item.label}}</div>
									<div class="day_block_empty" v-if="item.value.length==0">无菜品</div>
									<div class="dis_dish_row" v-for="(rows, index2) in item.value" :key="index2" >
										{{rows.dish_name}}
									</div>
								</div>
								<div class="clear"></div>
							</div>
						</div>
					</div>
					<div class="panel_dixz">
						<div class="panel_dixz_label">加餐</div>
						<div class="panel_menu_rowx">
							<div class="panel_menu_rowbig">
								<div class="panel_day_block" v-for="item in menu_jiacan" :key="item.id" >
									<div class="week_txo">{{item.label}}</div>
									<div class="day_block_empty" v-if="item.value.length==0">无菜品</div>
									<div class="dis_dish_row" v-for="(rows, index2) in item.value" :key="index2" >
										{{rows.dish_name}}
									</div>
								</div>
								<div class="clear"></div>
							</div>
						</div>
					</div>
					<div class="panel_dixz">
						<div class="panel_dixz_label">午餐</div>
						<div class="panel_menu_rowx">
							<div class="panel_menu_rowbig">
								<div class="panel_day_block" v-for="item in menu_wucan" :key="item.id" >
									<div class="week_txo">{{item.label}}</div>
									<div class="day_block_empty" v-if="item.value.length==0">无菜品</div>
									<div class="dis_dish_row" v-for="(rows, index2) in item.value" :key="index2" >
										{{rows.dish_name}}
									</div>
								</div>
								<div class="clear"></div>
							</div>
						</div>
					</div>
					<div class="panel_dixz">
						<div class="panel_dixz_label">午点</div>
						<div class="panel_menu_rowx">
							<div class="panel_menu_rowbig">
								<div class="panel_day_block" v-for="item in menu_wudian" :key="item.id" >
									<div class="week_txo">{{item.label}}</div>
									<div class="day_block_empty" v-if="item.value.length==0">无菜品</div>
									<div class="dis_dish_row" v-for="(rows, index2) in item.value" :key="index2" >
										{{rows.dish_name}}
									</div>
								</div>
								<div class="clear"></div>
							</div>
						</div>
					</div>
					<div class="panel_dixz">
						<div class="panel_dixz_label">晚餐</div>
						<div class="panel_menu_rowx">
							<div class="panel_menu_rowbig">
								<div class="panel_day_block" v-for="item in menu_wancan" :key="item.id" >
									<div class="week_txo">{{item.label}}</div>
									<div class="day_block_empty" v-if="item.value.length==0">无菜品</div>
									<div class="dis_dish_row" v-for="(rows, index2) in item.value" :key="index2" >
										{{rows.dish_name}}
									</div>
								</div>
								<div class="clear"></div>
							</div>
						</div>
					</div>
				</div>	
			</div>
			
		</el-col>
	</el-row>

	    <el-dialog
		width="30%"
		title="补充其他信息"
		:close-on-click-modal="false" 
		:visible.sync="dialogConfirmUseTemplate"
		append-to-body>
			<el-row :gutter="24" style="margin-top:15px">
				<el-col :span="24">
					菜谱日期：
					<el-date-picker
						v-model="new_menuWeek"
						:firstDayOfWeek="1" 
						type="week"
						format="yyyy 第 WW 周"
						value-format="yyyy-MM-dd"
						placeholder="选择周" size="small">
					</el-date-picker>
				</el-col>
			</el-row>
			<el-row :gutter="24" style="margin-top:15px">
				<el-col :span="24">
					所属园所：
					<el-select v-model="new_menu_kindergarten_uuid" placeholder="显示全部" size="small">
							<el-option
							v-for="item in select_kindergarten_options"
							:key="item.uuid"
							:label="item.kindergarten_name"
							:value="item.uuid"
							:disabled="item.disabled">
							</el-option>
					</el-select>
				</el-col>
			</el-row>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="handleAddMenuByTemplate" >创建菜谱</el-button>
			</div>
		</el-dialog>

	</el-dialog>



	<el-dialog
	width="80%"
	title="模板菜谱详情"
	:close-on-click-modal="false" 
	:visible.sync="dialogShowDetailTemplate">
		<el-row :gutter="24" style="margin-top:5px">
			<el-col :span="24">
				<div class="panel_exo">
					<div class="panel_dixz">
						<div class="panel_dixz_label">早餐</div>
						<div class="panel_menu_rowx">
							<div class="panel_menu_rowbig">
								<div class="panel_day_block" v-for="item in template_detail_menu_zaocan" :key="item.id" >
									<div class="week_txo">{{item.label}}</div>
									<div class="day_block_empty" v-if="item.value.length==0">无菜品</div>
									<div class="dis_dish_row" v-for="(rows, index2) in item.value" :key="index2" >
										{{rows.dish_name}}
									</div>
								</div>
								<div class="clear"></div>
							</div>
						</div>
					</div>
					<div class="panel_dixz">
						<div class="panel_dixz_label">加餐</div>
						<div class="panel_menu_rowx">
							<div class="panel_menu_rowbig">
								<div class="panel_day_block" v-for="item in template_detail_menu_jiacan" :key="item.id" >
									<div class="week_txo">{{item.label}}</div>
									<div class="day_block_empty" v-if="item.value.length==0">无菜品</div>
									<div class="dis_dish_row" v-for="(rows, index2) in item.value" :key="index2" >
										{{rows.dish_name}}
									</div>
								</div>
								<div class="clear"></div>
							</div>
						</div>
					</div>
					<div class="panel_dixz">
						<div class="panel_dixz_label">午餐</div>
						<div class="panel_menu_rowx">
							<div class="panel_menu_rowbig">
								<div class="panel_day_block" v-for="item in template_detail_menu_wucan" :key="item.id" >
									<div class="week_txo">{{item.label}}</div>
									<div class="day_block_empty" v-if="item.value.length==0">无菜品</div>
									<div class="dis_dish_row" v-for="(rows, index2) in item.value" :key="index2" >
										{{rows.dish_name}}
									</div>
								</div>
								<div class="clear"></div>
							</div>
						</div>
					</div>
					<div class="panel_dixz">
						<div class="panel_dixz_label">午点</div>
						<div class="panel_menu_rowx">
							<div class="panel_menu_rowbig">
								<div class="panel_day_block" v-for="item in template_detail_menu_wudian" :key="item.id" >
									<div class="week_txo">{{item.label}}</div>
									<div class="day_block_empty" v-if="item.value.length==0">无菜品</div>
									<div class="dis_dish_row" v-for="(rows, index2) in item.value" :key="index2" >
										{{rows.dish_name}}
									</div>
								</div>
								<div class="clear"></div>
							</div>
						</div>
					</div>
					<div class="panel_dixz">
						<div class="panel_dixz_label">晚餐</div>
						<div class="panel_menu_rowx">
							<div class="panel_menu_rowbig">
								<div class="panel_day_block" v-for="item in template_detail_menu_wancan" :key="item.id" >
									<div class="week_txo">{{item.label}}</div>
									<div class="day_block_empty" v-if="item.value.length==0">无菜品</div>
									<div class="dis_dish_row" v-for="(rows, index2) in item.value" :key="index2" >
										{{rows.dish_name}}
									</div>
								</div>
								<div class="clear"></div>
							</div>
						</div>
					</div>
				</div>
			</el-col>
		</el-row>
	</el-dialog>



	<el-dialog
	title="保存菜谱到模板库"
	:visible.sync="dialogSaveMenuTemplate" :close-on-click-modal="false"
	width="30%">
	<el-row>
		<el-col :span="18">
			请给菜谱输入名称：
			<el-input v-model="new_template_name" placeholder="例：优选系列周菜单1号"></el-input>
		</el-col>
	</el-row>
	<span slot="footer" class="dialog-footer">
		<el-button @click="dialogSaveMenuTemplate = false">取 消</el-button>
		<el-button type="primary" @click="handleSaveMenuToTemplate">确 定</el-button>
	</span>
	</el-dialog>


	</div>
</template>

<script>

import CommonNav from "@/components/Nav.vue";
import CommonHeader from "@/components/Header.vue";

import * as MN from '@/api/common'

export default {
    name: 'crm', 
	props:['selec'],
	data() {
		return {
			disabled_tab_menu:false,
			disabled_tab_template:false,
			disabled_tab_dish:false,
			disabled_tab_food:false,
			login_user_type:'',
			login_supplier_food:'',
			login_supplier_dish:'',
			new_totemplate_menu:'',
			new_template_name:'',
			dialogSaveMenuTemplate:false,
			dialogShowDetailTemplate:false,
			tableTemplateData:[],
			select_kindergarten_options: [],
			new_menu_kindergarten_uuid: '',
			new_menu_template:'',
			new_menuWeek:'',
			templateTableData:[],
			dialogConfirmUseTemplate:false,
			dialogMenuTemplate:false,
			dish_sell_price:'',
			dishAddfoodValue:'',
			dishAddfoodDose:'',
			foodcheckedListData:[],
			addfood_value:'',
			newdish_name:'',
			select_dishtype:'',
			foodListData:[],
			addDishDialogVisible:false,
			searchMenuValue:'',
			searchDishValue:'',
			searchFoodValue:'',
			edit_food_id:'',
			newfood_name:'',
			select_foodtype:'',
			buy_price:'',
			dishtype_options:[
				{value:'1',label:'主食/干食'},
				{value:'2',label:'蔬菜类'},
				{value:'3',label:'蛋类'},
				{value:'4',label:'肉菜'},
				{value:'5',label:'荤素搭配菜'},
				{value:'6',label:'素菜'},
				{value:'7',label:'汤类'},
			],
			foodtype_options:[
				{value: '1',label: '蔬菜'},
				{value: '2',label: '水果'},
				{value: '3',label: '蛋类'},
				{value: '4',label: '肉类'},
				{value: '5',label: '谷物'}
			],
			addFoodDialogVisible:false,
			activeTab:'menu',
			searchValue:'',
			loading:true,
			boxshow: false, 
			curTipsID: '', 
			tableMenuData: [],
			tableDishData:[],
			tableFoodData:[],
			select_options: [{
			value: 't',
			label: '显示全部'
			},{
			value: '1',
			label: '西安市AAAAAA幼儿园'
			}, {
			value: '2',
			label: '西安市BBBBBB幼儿园'
			}, {
			value: '3',
			label: '西安市CCCCCC幼儿园'
			}, {
			value: '4',
			label: '西安市DDDDDD幼儿园'
			}, {
			value: '5',
			label: '西安市EEEEEE幼儿园'
			}],
			select_value: '',
			menu_template_name:'',
			template_detail_menu_zaocan:[],
			template_detail_menu_jiacan:[],
			template_detail_menu_wucan:[],
			template_detail_menu_wudian:[],
			template_detail_menu_wancan:[],
			menu_zaocan:[
				{id:1,label:'周一 Mon',value:[]},
				{id:2,label:'周二 Tue',value:[]},
				{id:3,label:'周三 Wed',value:[]},
				{id:4,label:'周四 Thu',value:[]},
				{id:5,label:'周五 Fri',value:[]},
				{id:6,label:'周六 Sat',value:[]},
				{id:7,label:'周日 Sun',value:[]}
			],
			menu_jiacan:[
				{id:1,label:'周一 Mon',value:[]},
				{id:2,label:'周二 Tue',value:[]},
				{id:3,label:'周三 Wed',value:[]},
				{id:4,label:'周四 Thu',value:[]},
				{id:5,label:'周五 Fri',value:[]},
				{id:6,label:'周六 Sat',value:[]},
				{id:7,label:'周日 Sun',value:[]}
			],
			menu_wucan:[
				{id:1,label:'周一 Mon',value:[]},
				{id:2,label:'周二 Tue',value:[]},
				{id:3,label:'周三 Wed',value:[]},
				{id:4,label:'周四 Thu',value:[]},
				{id:5,label:'周五 Fri',value:[]},
				{id:6,label:'周六 Sat',value:[]},
				{id:7,label:'周日 Sun',value:[]}
			],
			menu_wudian:[
				{id:1,label:'周一 Mon',value:[]},
				{id:2,label:'周二 Tue',value:[]},
				{id:3,label:'周三 Wed',value:[]},
				{id:4,label:'周四 Thu',value:[]},
				{id:5,label:'周五 Fri',value:[]},
				{id:6,label:'周六 Sat',value:[]},
				{id:7,label:'周日 Sun',value:[]}
			],
			menu_wancan:[
				{id:1,label:'周一 Mon',value:[]},
				{id:2,label:'周二 Tue',value:[]},
				{id:3,label:'周三 Wed',value:[]},
				{id:4,label:'周四 Thu',value:[]},
				{id:5,label:'周五 Fri',value:[]},
				{id:6,label:'周六 Sat',value:[]},
				{id:7,label:'周日 Sun',value:[]}
			],
		}
	},
	components: {
		CommonNav,
		CommonHeader
	},

	created() {
		this.initData('menu');
		this.handleLoginUser();
	},

	mounted() {

	},

	methods: {
		// handleMenuToExcel(menu_id){
		// 	let me = this;
		// 	this.$confirm('确定将此菜单导出到Excel吗?', '提示', {
		// 	confirmButtonText: '确定',
		// 	cancelButtonText: '取消',
		// 	type: 'warning'
		// 	}).then(() => {
		// 		let post={
		// 			menu_id:menu_id
		// 		}
		// 		MN.setHandleMenuToExcel(post).then(res => {
		// 			this.$message({
		// 				type: 'success',
		// 				message: '导出成功'
		// 			});	
		// 		})
		// 	}).catch(() => {
		// 		this.$message({
		// 			type: 'info',
		// 			message: '导出成功'
		// 		});          
		// 	});
		// },
		handleLoginUser(){
			// let checkSession = sessionStorage.getItem('kindergarten_meals_session');
			// if ( !checkSession ){
			//   this.$router.push('/');
			// }
			let me = this;
			// let post={
			//   meals_session:sessionStorage.getItem('kindergarten_meals_session'),
					// 	meals_usertype:sessionStorage.getItem('kindergarten_meals_usertype')
					// }
			MN.kindergartenLoginUser().then(res => {
				//me.login_user_uuid = res.user_uuid;
				me.login_user_type = res.login_type;
				me.login_supplier_food = res.supplier_manage_food;
				me.login_supplier_dish = res.supplier_manage_dish;
				//me.login_user_name = res.user_name;
				// me.login_user_company_name = res.company_name;
				// me.login_user_kindergarten_name = res.kindergarten_name;

				if(  res.login_type == 'admin_user' ){
					me.disabled_tab_menu = false
					me.disabled_tab_template = false
					me.disabled_tab_dish = false
					me.disabled_tab_food = false
				}

				if(  res.login_type == 'staff_user' ){
					me.disabled_tab_menu = false
					me.disabled_tab_template = true
					me.disabled_tab_dish = true
					me.disabled_tab_food = true
				}

				if(  res.login_type == 'supplier_user' ){
					//me.disabled_tab_menu = true
					me.disabled_tab_template = true
					if ( res.supplier_manage_food == '0' ){
						me.disabled_tab_dish = false
					}else{
						me.disabled_tab_dish = true
					}

					if ( res.supplier_manage_dish == '0' ){
						me.disabled_tab_food = false
					}else{
						me.disabled_tab_food = true
					}
					
				}


				console.log(res)
				return
			})
		},
		handleSaveMenuToTemplate(){
			let me = this;
			if ( !this.new_template_name ){
				this.$message.error('请输入模板名称');
				return
			}
			let post={
				menu_uuid:me.new_totemplate_menu,
				template_name:me.new_template_name,
			}
			MN.saveMenuToTemplate(post).then(res => {
				if( res == 'new_template_ok' ){
					me.$message.success('成功保存为模板，可到模板库中查看');
					me.dialogSaveMenuTemplate = false
				}
			})
		},
		handleMenuToTemplate(menu_uuid){
			this.new_totemplate_menu = menu_uuid;
			this.new_template_name = '';
			this.dialogSaveMenuTemplate = true;
		},
		handleDetailTemplate(rowdata){
			
			this.template_detail_menu_zaocan=JSON.parse( rowdata.menu_zaocan );
			this.template_detail_menu_jiacan=JSON.parse( rowdata.menu_jiacan );
			this.template_detail_menu_wucan=JSON.parse( rowdata.menu_wucan );
			this.template_detail_menu_wudian=JSON.parse( rowdata.menu_wudian );
			this.template_detail_menu_wancan=JSON.parse( rowdata.menu_wancan );
			this.dialogShowDetailTemplate = true;
			console.log(rowdata);
		},
		handleDeleteTemplate(template_uuid){
			console.log(template_uuid);
			let me = this;
			this.$confirm('此操作将永久删除此模板, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
					let post={
						template_uuid:template_uuid
					}
					MN.handleDeleteTemplate(post).then(res => {
						me.initData('template');
					})
					
					this.$message({
						type: 'success',
						message: '删除成功!'
					});
			}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});          
			});
		},
		handleAddMenuByTemplate(){
			let me = this;
			if ( !this.new_menuWeek ){
				this.$message.error('请选择菜谱日期');
				return
			}
			if ( !this.new_menu_kindergarten_uuid ){
				this.$message.error('请选择幼儿园');
				return
			}
			let post={
				menuWeek:this.new_menuWeek,
				menu_template:this.new_menu_template,
				kindergarten_uuid:this.new_menu_kindergarten_uuid
			}
			MN.setAddMenuByTemplate(post).then(res => {
				if( res == 'add_ok' ){
					me.initData('menu');
					me.dialogMenuTemplate = false;
					me.dialogConfirmUseTemplate = false;
				}
			})
		},
		handleUseTemplate(template_uuid){
			let me = this;
			MN.getKindergartenList().then(res => {
				me.select_kindergarten_options = res;
				me.loading = false;
			})
			this.dialogConfirmUseTemplate = true;
			this.new_menu_template = template_uuid;
			console.log("template_uuid",template_uuid)
		},
		templateTableEnter(row){
			console.log("row",row.name)
			this.menu_template_name = row.name;
			this.menu_zaocan = JSON.parse( row.menu_zaocan );
			this.menu_jiacan = JSON.parse( row.menu_jiacan );
			this.menu_wucan = JSON.parse( row.menu_wucan );
			this.menu_wudian = JSON.parse( row.menu_wudian );
			this.menu_wancan = JSON.parse( row.menu_wancan );
		},
		handleShowMenuTemplate(){
			let me = this;
			this.menu_template_name = '';
			this.menu_zaocan=[
				{id:1,label:'周一 Mon',value:[]},
				{id:2,label:'周二 Tue',value:[]},
				{id:3,label:'周三 Wed',value:[]},
				{id:4,label:'周四 Thu',value:[]},
				{id:5,label:'周五 Fri',value:[]},
				{id:6,label:'周六 Sat',value:[]},
				{id:7,label:'周日 Sun',value:[]}
			],
			this.menu_jiacan=[
				{id:1,label:'周一 Mon',value:[]},
				{id:2,label:'周二 Tue',value:[]},
				{id:3,label:'周三 Wed',value:[]},
				{id:4,label:'周四 Thu',value:[]},
				{id:5,label:'周五 Fri',value:[]},
				{id:6,label:'周六 Sat',value:[]},
				{id:7,label:'周日 Sun',value:[]}
			],
			this.menu_wucan=[
				{id:1,label:'周一 Mon',value:[]},
				{id:2,label:'周二 Tue',value:[]},
				{id:3,label:'周三 Wed',value:[]},
				{id:4,label:'周四 Thu',value:[]},
				{id:5,label:'周五 Fri',value:[]},
				{id:6,label:'周六 Sat',value:[]},
				{id:7,label:'周日 Sun',value:[]}
			],
			this.menu_wudian=[
				{id:1,label:'周一 Mon',value:[]},
				{id:2,label:'周二 Tue',value:[]},
				{id:3,label:'周三 Wed',value:[]},
				{id:4,label:'周四 Thu',value:[]},
				{id:5,label:'周五 Fri',value:[]},
				{id:6,label:'周六 Sat',value:[]},
				{id:7,label:'周日 Sun',value:[]}
			],
			this.menu_wancan=[
				{id:1,label:'周一 Mon',value:[]},
				{id:2,label:'周二 Tue',value:[]},
				{id:3,label:'周三 Wed',value:[]},
				{id:4,label:'周四 Thu',value:[]},
				{id:5,label:'周五 Fri',value:[]},
				{id:6,label:'周六 Sat',value:[]},
				{id:7,label:'周日 Sun',value:[]}
			],
			MN.getTemplateTableData().then(res => {
				console.log(res)
				me.templateTableData = res;
				me.dialogMenuTemplate = true;
			})
		},
		hadleSaveNewDish(){
			if (!this.select_dishtype){
				this.$message.error('请选择菜品类型');
				return
			}
			if (!this.newdish_name){
				this.$message.error('请输入菜品名称');
				return
			}
			if (!this.dish_sell_price){
				this.$message.error('请输入售价');
				return
			}
			if (this.foodcheckedListData.length == 0){
				this.$message.error('没有选择食材');
				return
			}
			let me = this;
			let post={
				dish_name:this.newdish_name,
				dish_type:this.select_dishtype,
				sell_price:this.dish_sell_price,
				checkedlist:JSON.stringify(this.foodcheckedListData)
			}
			MN.setSaveNewDish(post).then(res => {
				console.log(res)
				if( res == 'error_input' ){
					this.$message.error('请检查提交的内容');
				}
				if( res == 'add_ok' ){
					me.initData('dish');
					me.addDishDialogVisible = false
				}
			})
			

		},
		handleDeleteDish(){
			this.$message.error('此功能还未配置权限');
		},
		handleEditDish(){
			this.$message.error('此功能还未配置权限');
		},
		handleSaveEditDish(){
			this.$message.error('此功能还未配置权限');
		},
		handleDeleteDishAddFood(foodid){
			console.log(foodid);
			for ( let i = 0; i < this.foodListData.length;i++ ){
				if ( this.foodcheckedListData[i].foodid == foodid ){
					this.foodcheckedListData.splice([i],1)
				}
			}
		},
		hadleNewDishAddFood(){
			if ( !this.dishAddfoodValue || !this.dishAddfoodDose ){
				this.$message.error('请选择要添加的食材、输入用量');
				return
			}
			let getfoodname = '';
			for ( let i = 0; i < this.foodListData.length;i++ ){
				if ( this.foodListData[i].id == this.dishAddfoodValue ){
					getfoodname = this.foodListData[i].name;
				}
			}
			
			this.foodcheckedListData.push({
				foodid:this.dishAddfoodValue,
				foodname:getfoodname,
				fooddose:this.dishAddfoodDose
			})

			this.dishAddfoodValue = '';
			this.dishAddfoodDose = '';
		},
		handleAddDish(){
			let me = this;
			MN.getFoodListData().then(res => {
				me.select_dishtype = '';
				me.newdish_name = '';
				me.dish_sell_price = '';
				me.dishAddfoodValue = '';
				me.dishAddfoodDose = '';
				me.foodcheckedListData = [];

				me.foodListData=res;
				me.addDishDialogVisible=true;
			})
		},
		handleSearchMenu(){
			this.loading=true;
			let me = this;
			let post={
				searchmenu:this.searchMenuValue,
			}
			MN.searchMenuData(post).then(res => {
				me.tableMenuData=res;
				me.loading=false;
			})
		},
		handleSearchDish(){
			this.loading=true;
			let me = this;
			let post={
				searchdish:this.searchDishValue,
			}
			MN.searchDishData(post).then(res => {
				me.tableDishData=res;
				me.loading=false;
			})
		},
		handleSearchFood(){

			this.loading=true;
			let me = this;
			let post={
				searchfood:this.searchFoodValue,
			}
			MN.searchFoodData(post).then(res => {
				me.tableFoodData=res;
				me.loading=false;
			})
			//this.initData('food');
		},
		handleSaveEditFood(){
			if( !this.newfood_name || !this.select_foodtype || !this.buy_price ){
				this.$message.error('请选择类别、输入食物名称、采购价');
				return
			}
			let me = this;
			let post={
				edit_food_id:this.edit_food_id,
				food_name:this.newfood_name,
				food_type:this.select_foodtype,
				buy_price:this.buy_price
			}
			MN.setSaveEditFood(post).then(res => {
				console.log(res)
				if( res == 'error_input' ){
					this.$message.error('请选择类别、输入食物名称、采购价');
				}
				if( res == 'edit_ok' ){
					me.edit_food_id = ''
					me.initData('food');
					me.addFoodDialogVisible = false
				}
			})
		},
		handleEditFood(rowdata){
			console.log(rowdata)
			this.edit_food_id = rowdata.id;
			this.newfood_name = rowdata.name;
			this.select_foodtype = rowdata.stype;
			this.buy_price = rowdata.buy_price;
			this.addFoodDialogVisible = true
		},
		handleDeleteFood(rowid){
			let me = this;
			console.log(rowid)
			 this.$confirm('此操作将永久删除, 是否继续?', '提示', {
			confirmButtonText: '确定',
			cancelButtonText: '取消',
			type: 'warning'
			}).then(() => {
				let post={
					food_id:rowid
				}
				MN.handleDeleteFood(post).then(res => {
					me.initData('food');
				})
				
				this.$message({
					type: 'success',
					message: '删除成功!'
				});
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消删除'
				});          
			});
		},
		hadleSaveNewFood(){
			if( !this.newfood_name || !this.select_foodtype || !this.buy_price ){
				this.$message.error('请选择类别、输入食物名称、采购价');
				return
			}
			let me = this;
			let post={
				food_name:this.newfood_name,
				food_type:this.select_foodtype,
				buy_price:this.buy_price
			}
			MN.setSaveNewFood(post).then(res => {
				console.log(res)
				if( res == 'error_input' ){
					this.$message.error('请选择类别、输入食物名称、采购价');
				}
				if( res == 'add_ok' ){
					me.initData('food');
					me.addFoodDialogVisible = false
				}
			})
		},
		handleAddFood(){
			this.select_foodtype = '';
			this.newfood_name = '';
			this.addFoodDialogVisible = true;
		},
		handleTabClick(){
			console.log(this.activeTab);
			this.initData(this.activeTab);
		},
		initData(tabname){
			let me = this;
			this.loading = true
			MN.getTopicsList().then(res => {
				this.tableMenuData=res.data;
				this.loading=false;
			})
		},
		handleMenuDelete(menu_uuid){
			let me = this;
			console.log(menu_uuid)
			this.$confirm('此操作将永久删除此菜单, 是否继续?', '提示', {
			confirmButtonText: '确定',
			cancelButtonText: '取消',
			type: 'warning'
			}).then(() => {
				let post={
					menu_uuid:menu_uuid
				}
				MN.handleDeleteMenu(post).then(res => {
					me.initData('menu');
				})
				
				this.$message({
					type: 'success',
					message: '删除成功!'
				});
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消删除'
				});          
			});
		},
		handleMenuEdit(rowdata){
			this.$router.push('/menu/edit?uuid='+rowdata.uuid)
		},
		handleMenuDetail(rowdata){
			this.$router.push('/menu/detail?uuid='+rowdata.uuid)
			//this.$router.push({name: 'topicsdetail', params: {uuid: rowdata.uuid}})
		}
	}
}
</script>

<style scoped>
.form_xcv{
	float: right;
}
.buttonexcel{
	color: #409EFF;
  background: #ecf5ff;
  border:none;
  padding: 5px 15px;
  display: inline-block;
  cursor: pointer;
  border: 1px solid #b3d8ff;
  border-radius: 3px;
  font-size: 12px;
}
.sikopop_title{
	font-size: 16px;
	padding-bottom:10px;
	font-weight: bolder;
}
.sikopop{
	background-color: #f3f3f3;
	padding: 10px;
	border: 1px solid #EDECE6;
	border-radius: 5px;
	margin-left: 6px;
}
.panel_dixz_label{
	width: 15px;
	float: left;
	text-align:center;
	font-size: 12px;
	color: #0C922E;
	font-weight: bolder;
}
.panel_dixz{
	border: 1px solid #cbcbcb;
	background:url('@/assets/icon_green_bg.png') repeat-y #EDECE6;
	background-size: 27px;
	text-align: center;
	padding: 6px;
	border-radius: 8px;
	margin-bottom: 8px;
	position: relative;
}
.sixk_row{
	width: 14.22%;
	font-size: 13px;
}

.mt20{
	margin-top: 20px;
}
.uicxo{
	font-size: 12px;
	color: #bababa;
}
.panel_menu_rowx{
	position: relative;
	overflow-x: auto;
	width: 97%;
	margin: 0 0 0 2%;
}
.panel_menu_rowbig{
	width: 1250px;
	padding-bottom:16px;
}
.week_txo{
	position: absolute;
	left: 5px;
	top: -18px;
	font-size: 12px;
	color: #999999;
}
.dis_dish_row{ 
	position: relative;
	border-bottom: 1px dashed #cccccc;
	padding: 4px;
	text-align: center;
	font-size: 13px;
	margin-bottom: 5px;
	border-radius: 4px;
}
.panel_day_block{
	position: relative;
	background-color: #ffffff;
	box-shadow: 1px 1px 3px #b9b9b9;
	border-radius: 8px;
	min-height: 60px;
	width: 145px;
	padding: 5px;
	float: left;
	margin: 20px 0 6px 14px;
}
::v-deep .el-tabs__active-bar {
	cursor: pointer;
	background-color: #03BC5B;
}
::v-deep .el-tabs__item.is-active {
	color: #03BC5B;
	font-weight: bolder;
	cursor: pointer;
}
::v-deep .el-tabs__item:hover {
	color: #03BC5B;
	cursor: pointer;
}

</style>
