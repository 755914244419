<template>
	<div>

	<el-container class="data_skc">

		<el-aside class="aside-style" width="200px">
			<common-nav selec="5"></common-nav>
		</el-aside>
		
		<el-container>

			<el-header class="common_header">
				<common-header></common-header>
			</el-header>
			
			<el-main>

				<el-row :gutter="24" class="page_bar">
					<el-col :span="20" class="page_bar_title">供应商列表</el-col>
					<el-col :span="4">
						<el-input placeholder="输入标题查找" v-model="searchValue" class="input-with-select" size="small" >
							<el-button slot="append" icon="el-icon-search"></el-button>
						</el-input>
					</el-col>
				</el-row>

				<el-empty description="需要配置权限"></el-empty>
				
				</el-main>

			<el-footer> copyright &copy; yyvvi.com </el-footer>
		</el-container>
	</el-container>


	</div>
</template>

<script>

import CommonNav from "@/components/Nav.vue";
import CommonHeader from "@/components/Header.vue";

import * as MN from '@/api/common'

export default {
    name: 'crm', 
	props:['selec'],
	data() {
		return {
			searchValue:'',
			loading:true,
			boxshow: false, 
			curTipsID: '', 
			tableData: []
		}
	},
	components: {
		CommonNav,
		CommonHeader
	},

	created() {
		this.initData()
		
	},

	mounted() {

	},

	methods: {
		initData(){
			let me = this;
			this.loading = true
			MN.getTopicsList().then(res => {
				if ( res.status == 'no_login' || res == 'error_user' ){
					//location.href = "https://www.baibaodu.com/main/";
					me.$router.push('/');
					return
				}
				this.tableData=res.data;
				this.loading=false;
			})
		},
		handlePSTableDetail(rowdata){
			this.$router.push('/topics/psdetail?uuid='+rowdata.uuid)
		},
		handleTableDetail(rowdata){
			
			this.$router.push('/topics/detail?uuid='+rowdata.uuid)
			//this.$router.push({name: 'topicsdetail', params: {uuid: rowdata.uuid}})
		}
	}
}
</script>

<style scoped>

</style>
