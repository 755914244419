<template>
	<div>

	<el-container class="data_skc">

		<el-aside class="aside-style" width="200px">
			<common-nav selec="7"></common-nav>
		</el-aside>
		
		<el-container>

			<el-header class="common_header">
				<common-header></common-header>
			</el-header>
			
			<el-main>

				<el-row :gutter="24" class="page_bar">
					<el-col :span="20" class="page_bar_title">数据统计</el-col>
					<el-col :span="4">
						<!-- <el-input placeholder="输入标题查找" v-model="searchValue" class="input-with-select" size="small" >
							<el-button slot="append" icon="el-icon-search"></el-button>
						</el-input> -->
					</el-col>
				</el-row>

				<div id="maychar"></div>
<!-- 
				<el-pagination
					background
					layout="prev, pager, next"
					:total="10" class="page_pagination">
					</el-pagination>
				 -->
</el-main>
			<el-footer> copyright &copy; yyvvi.com </el-footer>
		</el-container>
	</el-container>


	</div>
</template>

<script>

import CommonNav from "@/components/Nav.vue";
import CommonHeader from "@/components/Header.vue";

import * as MN from '@/api/common'

export default {
    name: 'crm', 
	props:['selec'],
	data() {
		return {
			searchValue:'',
			loading:true,
			boxshow: false, 
			curTipsID: '', 
			tableData: []
		}
	},
	components: {
		CommonNav,
		CommonHeader
	},

	created() {
		
		
	},

	mounted() {
		this.getCharts()
	},

	methods: {
		getCharts(){
			
			let chartBox = this.$echarts.init(document.getElementById("maychar"));
			let option = {
				xAxis: {
				data: ["星期一", "星期二", "星期三", "星期四", "星期五", "星期六", "星期日"],
				},
				yAxis: {},
				series: [
				{
					type: "bar",
					data: [23, 24, 18, 25, 27, 10, 25],
				},
				],
			};
			chartBox.setOption(option);
			// 根据页面大小自动响应图表大小
			window.addEventListener("resize", function () {
				chartBox.resize();
			});
    

		},
	}
}
</script>

<style scoped>
#maychar{ width: 100%; height: 500px; }
</style>
