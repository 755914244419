<template>
	<div>

	<el-container class="data_skc">

		<el-aside class="aside-style" width="200px">
			<common-nav selec="1"></common-nav>
		</el-aside>
		
		<el-container>

			<el-header class="common_header">
				<common-header></common-header>
			</el-header>
			
			<el-main>

				<el-row :gutter="24" class="page_bar">
					<el-col :span="18" class="page_bar_title"><i @click="handleGoBack()" class="el-icon-back" style="font-size:22px"></i>  批量添加食材</el-col>
					<el-col :span="3">
						<el-button size="small" type="warning"  icon="el-icon-document-copy" @click="handleBatchFoodExcel" >从Excel导入食材</el-button>
					</el-col>
					<el-col :span="2">
						<el-badge is-dot :hidden="hiddenDraftDot"><el-button size="small" @click="handleUseDraft()" >草稿箱 {{": "+hasDraft }}</el-button></el-badge>
					</el-col>
				</el-row>

				<el-row :gutter="24" class="page_batch_title">
					<el-col :span="2">食材图片</el-col>
					<el-col :span="3">食材分类</el-col>
					<el-col :span="5">名称</el-col>
					<el-col :span="7">
						<el-row :gutter="24" >
							<el-col :span="12">
								单位
							</el-col>
							<el-col :span="12">
								每单位重量（g）
							</el-col>
						</el-row>
					</el-col>
					<el-col :span="3" :offset="1">每500g采购价</el-col>
					<el-col :span="2" :offset="1">操作</el-col>
				</el-row>

				
				<el-row v-for="item in batchList" :key="item.rowid" :gutter="24" class="page_batch_row">
					<el-col :span="2">
						<el-upload
						class="avatar-uploader"
						:data="{rowid:item.rowid}"
						action="https://www.yyvvi.com/kindergarten/index/postsImages"
						:show-file-list="false"
						:on-success="handleAvatarSuccess"
						:before-upload="beforeAvatarUpload">
						<img v-if="item.show_pic" :src="item.show_pic" class="avatar">
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
						</el-upload>
					</el-col>
					<el-col :span="3">
						<el-select v-model="item.type" placeholder="显示全部">
							<el-option
							v-for="item in foodTypeTableData"
							:key="item.name"
							:label="item.name"
							:value="item.name"
							:disabled="item.disabled">
							</el-option>
						</el-select>
					</el-col>
					<el-col :span="5">
						<el-input v-model="item.name" placeholder="请输入内容" ></el-input>
					</el-col>
					<el-col :span="7">
						<el-row :gutter="24" >
							<el-col :span="12">
								<el-select v-model="item.unit"  placeholder="显示全部">
									<el-option
									v-for="item in foodWeightTableData"
									:key="item.name"
									:label="item.name"
									:value="item.name"
									:disabled="item.disabled">
									</el-option>
								</el-select>
							</el-col>
							<el-col :span="12">
								<el-input-number v-if="item.unit != 'g'" controls-position="right" v-model="item.unit_weight" min="0" :precision="0"  placeholder="请输入内容"> </el-input-number>
							</el-col>
						</el-row>
					</el-col>
					<el-col :span="3" :offset="1">
						<el-input-number v-model="item.price" min="0" :precision="2"  placeholder="请输入内容"></el-input-number>
					</el-col>
					<el-col :span="2" :offset="1">
						<el-link type="danger" @click="handleDelRow(item.rowid)" class="button_del" icon="el-icon-close"></el-link>
					</el-col>
				</el-row>

				<el-row style="margin-top:12px">
					<el-col :span="2" style="text-align: center">
						<el-button style="width:100%" type="success" size="mini" plain @click="handleAddRow" >增加一行</el-button>
					</el-col>
				</el-row>
				
				<el-row style="margin-top:30px">
					<el-col :span="6">
						<el-button style="width:100%" type="success" @click="handelSaveBatch" >保存全部食材</el-button>
					</el-col>
					<el-col :span="3" :offset="1">
						<el-button style="width:100%" type="primary" @click="handelSaveDraft" plain>存为草稿</el-button>
					</el-col>
				</el-row>
				
			</el-main>

			<el-footer> copyright &copy; yyvvi.com </el-footer>
		</el-container>
	</el-container>

	<el-dialog
	title="从Excel文件导入食材"
	:visible.sync="batchFoodExcelDialog"
	width="30%"  :close-on-click-modal="false">
	<el-row>
		<el-col :span="8">
			<el-upload
			class="avatar-uploader"
			action="https://www.yyvvi.com/kindergarten/index/handleBatchFoodExcel"
			:show-file-list="false"
			:on-success="handleBatchFoodExcelSuccess">
			<el-button type="primary"  >选择Excel文件并导入</el-button>
			</el-upload>
		</el-col>
	</el-row>
	<el-row class="mt20">
		<el-col :span="24">请按照Excel模板顺序填好表格并保存，然后上传此Excel文件。</el-col>
	</el-row>
	<el-row class="mt20">
		<el-col :span="24"><el-link type="primary" href="https://www.yyvvi.com/template/food_template.xlsx">点此下载Excel模板</el-link></el-col>
	</el-row>

	</el-dialog>


	</div>
</template>

<script>
import CommonNav from "@/components/Nav.vue";
import CommonHeader from "@/components/Header.vue";
import * as MN from '@/api/common';
import { toRaw } from 'vue';

export default {
    name: 'crm', 
	props:['selec'],
	data() {
		return {
			batchFoodExcelDialog:false,
			hasDraft:'空',
			hiddenDraftDot:true,
			foodTypeTableData:[],
			foodWeightTableData:[],
			foodtype_options:[
				{value: '1',label: '蔬菜'},
				{value: '2',label: '水果'},
				{value: '3',label: '蛋类'},
				{value: '4',label: '肉类'},
				{value: '5',label: '谷物'}
			],
			foodunit_options:[
				{value: 'g',label: 'g'},
				{value: '盒',label: '盒'},
				{value: '袋',label: '袋'},
				{value: '瓶',label: '瓶'},
				{value: '支',label: '支'},
				{value: '件',label: '件'}
			],
			imageUrl: '',
			login_user_type:'',
			loading:true,
			detailData: [],
			detailList:[],
			detailUUID:this.$route.query.uuid,
			batchList:[]
		}
	},
	components: {
		CommonNav,
		CommonHeader
	},

	created() {
		this.initData()
		this.handleLoginUser();
	},

	mounted() {

	},

	methods: {
		handleBatchFoodExcel(){
			this.batchFoodExcelDialog = true;
		},
		handleBatchFoodExcelSuccess(res,file){
			this.batchList = res;
			this.batchFoodExcelDialog = false;
			console.log("res",res)
		},
		handleUseDraft(){
			let me = this;
			this.$confirm('确认使用草稿箱中的内容吗？', '提示', {
				confirmButtonText: '使用',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				let kindergartendraft = JSON.parse(localStorage.getItem('kindergartendraft'));
				me.batchList = kindergartendraft;
				console.log("batchList",me.batchList)
			}).catch(() => {
				       
			});
		},
		handelSaveDraft(){
			let me = this;
			this.$confirm('草稿保存在您的浏览器，关闭浏览器后可能会被清空，请及时从草稿箱取出并保存', '提示', {
				confirmButtonText: '保存到草稿箱',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				me.hasDraft = '有草稿';
				me.hiddenDraftDot = false;
				localStorage.setItem('kindergartendraft',JSON.stringify(me.batchList));
			}).catch(() => {
				       
			});
		},
		handleDelRow(rowid){
			if ( this.batchList.length == 1 ){
				this.$message.error('至少留一行啊!');
				return
			}
			this.batchList.forEach((items,index) => {
				if ( items.rowid == rowid ){
					this.batchList.splice(index,1);
				}
			});
		},
		handelSaveBatch(){
			
			let isReady = true;
			this.batchList.forEach((items,index) => {
				if ( isReady == true ){
					if ( !items.type || !items.name  || !items.price ){
						this.$message.error('请将内容补充完整');
						isReady = false;
						return;
					}

					if( items.unit != 'g' && items.unit_weight == '0' ){
						this.$message.error('选择其他食材单位后，请填写重量');
						isReady = false;
						return
					}
				}
			});

			if ( isReady == true ){
				let me = this;
				this.$confirm('确认提交吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let post={
						batchList:JSON.stringify(this.batchList),
					}
					MN.getHandleBatchSaveFood(post).then(res => {
						if( res == 'add_batch_ok' ){
							me.$message({
								type: 'success',
								message: '批量提交成功!'
							});
							me.$router.push('/food')
						}
					})
				}).catch(() => {
					me.$message({
						type: 'info',
						message: '已取消'
					});          
				});
			}
			console.log("os",this.batchList)
		},
		handleAddRow(){
			let row_id = new Date().getTime();
			this.batchList.push({rowid:row_id,type:'',name:'',show_pic:'',pic_url:'',price:'',unit:'g',unit_weight:0});
		},
		handleAvatarSuccess(res, file) {
			//this.imageUrl = URL.createObjectURL(file.raw);
			this.batchList.forEach(items => {
				if ( items.rowid == res.rowid ){
					items.show_pic = "https://www.yyvvi.com/uploads/" + res.image_url
					items.pic_url =  res.image_url
				}
			});
		},
      beforeAvatarUpload(file) {
        const isPNG = file.type === 'image/png';
		const isGIF = file.type === 'image/gif';
		const isJPG = file.type === 'image/jpeg';
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isJPG && !isGIF && !isPNG) {
          this.$message.error('只能是图片格式（JPG/GIF/PNG）');
		   return false
        }
        if (!isLt2M) {
          this.$message.error('图片大小不能超过2MB');
		  return false
        }
        
      },

		handleLoginUser(){
			let me = this;
			MN.kindergartenLoginUser().then(res => {
				//me.login_user_uuid = res.user_uuid;
				me.login_user_type = res.login_type;
			})
		},
		initData(){
			let me = this;
			let row_id = new Date().getTime();
			this.batchList.push({rowid:row_id,type:'',name:'',show_pic:'',pic_url:'',price:'',unit:'g',unit_weight:0});
			MN.getActivateTypeList().then(res => {
				me.foodTypeTableData = res.food;
				me.foodWeightTableData = res.foodweight;
			})
			let check_kindergartendraft = localStorage.getItem('kindergartendraft');
			if ( check_kindergartendraft ){
				me.hasDraft = '有草稿';
				me.hiddenDraftDot = false;
			}
		},
		handleGoBack(){
			this.$router.back(-1)
		}
	}
}
</script>

<style scoped>

.button_del{ font-size: 26px; margin: 12px 0 0 0; }

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;background-color: red;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 18px;
    color: #4d7534;
    width: 45px;
    height: 45px;
    line-height: 45px;
    text-align: center;
	border: 1px dashed #a4c471;

  }
  .avatar {
    width: 45px;
    height:45px;
    display: block;
  }

.page_batch_row{
	position: relative;
	border: 1px solid #d3d3d3;
	background-color:#ffffff;
	border-radius: 6px;
	padding: 8px;
	margin: 4px 0 10px 0;
}

.page_batch_title{
	font-size: 15px;
	color: #1a9016;
	background-color: #f2fdf6;
	padding: 9px;
	border-bottom: 1px solid #a4dab0;
}

.clear{ clear: both; }
.day_block_empty{
	position: absolute;
	left: 0;
	top: 30%;
	text-align: center;
	width: 100%;
	color: #b3b3b3;
	font-size: 13px;
}
.button_edit_dish{
	width: 18px;
	height: 18px;
	background:url('@/assets/icon_green_edit.png') no-repeat;
	background-size: 100%;
	position: absolute;
	right:30px;
	top: 4px;
	cursor: pointer;
}
.button_del_dish{
	background:url('@/assets/icon_green_del.png') no-repeat;
	background-size: 100%;
	width: 18px;
	height: 18px;
	position: absolute;
	right: 5px;
	top: 4px;
	cursor: pointer;
}
.dis_dish_row{ 
	position: relative;
	border: 1px solid #cccccc;
	padding: 4px;
	text-align: left;
	font-size: 13px;
	margin-bottom: 5px;
	border-radius: 4px;
}
.button_add_dish{
	position: absolute;
	left: 0;
	bottom: -33px;
	width: 99%;
	padding:6px 0 6px 0;
	font-size: 13px;
	background:url('@/assets/icon_green_add.png') no-repeat #F4F8EE;
	background-position:30% 50%;
	background-size: 16px;
	border: 1px solid #54C651;
	color: #2C9E46;
	font-weight: bolder;
	border-radius: 4px;
	cursor: pointer;
}
.panel_menu_rowx{
	position: relative;
	overflow-x: auto;
	width: 97%;
	margin: 0 0 0 2%;
}
.panel_menu_rowbig{
	width: 1250px;
	padding-bottom:16px;
}
.week_txo{
	position: absolute;
	left: 5px;
	top: -18px;
	font-size: 12px;
	color: #999999;
}
.panel_day_block{
	position: relative;
	background-color: #ffffff;
	box-shadow: 1px 1px 3px #b9b9b9;
	border-radius: 8px;
	min-height: 60px;
	width: 145px;
	padding: 5px;
	float: left;
	margin: 20px 0 33px 14px;
}
.panel_dixz_label{
	width: 15px;
	float: left;
	text-align:center;
	font-size: 12px;
	color: #0C922E;
	font-weight: bolder;
}
.panel_dixz{
	border: 1px solid #cbcbcb;
	background:url('@/assets/icon_green_bg.png') repeat-y #EDECE6;
	background-size: 27px;
	text-align: center;
	padding: 6px;
	border-radius: 8px;
	margin-bottom: 8px;
	position: relative;
}
.panel_exo{
	border-radius: 10px;
	padding: 20px;
	background-color: #f9f9f9;
}
.buttonexcel{
	background: none;
	background-color: #409eff;
	width: 90px;
	color: #fff;
	border: none;
	border-radius: 4px;
	padding: 5px;
	cursor: pointer;
}
.page_bar_title{
	color: #575757;
}
.mt20{
	margin-top: 20px;
}
.detail_row{
	background-color: #f8f8f8;
	border-radius: 10px;
	padding: 8px;
	box-shadow: 0 2px 5px #eeeeee;
	text-align: left;
}

::v-deep .el-tabs__active-bar {
	cursor: pointer;
	background-color: #03BC5B;
}
::v-deep .el-tabs__item.is-active {
	color: #03BC5B;
	font-weight: bolder;
	cursor: pointer;
}
::v-deep .el-tabs__item:hover {
	color: #03BC5B;
	cursor: pointer;
}

</style>
