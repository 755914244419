import Vue from 'vue'
import VueRouter from 'vue-router'
import loginView from '../views/loginView.vue'
import MenuView from '../views/menu/index.vue'
import MenuAdd from '../views/menu/add.vue'
import MenuEdit from '../views/menu/edit.vue'
import MenuDetail from '../views/menu/detail.vue'
import OrderView from '../views/order/index.vue'
import OrderDetail from '../views/order/detail.vue'
import SupplierView from '../views/supplier/index.vue'
import SupplierDetail from '../views/supplier/detail.vue'
import MessageView from '../views/message/index.vue'
import MessageDetail from '../views/message/detail.vue'
import StatisticsView from '../views/statistics/index.vue'
import StatisticsDetail from '../views/statistics/detail.vue'
import SystemView from '../views/system/index.vue'
import SystemDetail from '../views/system/detail.vue'
import TemplateView from '../views/template/index.vue'
import TemplateEdit from '../views/template/edit.vue'
import DishView from '../views/dish/index.vue'
import DishBatchAddView from '../views/dish/batch.vue'
import FoodView from '../views/food/index.vue'
import FoodBatchAddView from '../views/food/batch.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: loginView
  },
  {
    path: '/dish/batch',
    name: 'dishbatch',
    component: DishBatchAddView
  },
  {
    path: '/food/batch',
    name: 'foodbatch',
    component: FoodBatchAddView
  },
  {
    path: '/template/edit',
    name: 'templateedit',
    component: TemplateEdit
  },
  {
    path: '/template',
    name: 'template',
    component: TemplateView
  },
  {
    path: '/dish',
    name: 'dish',
    component: DishView
  },
  {
    path: '/food',
    name: 'food',
    component: FoodView
  },
  {
    path: '/menu',
    name: 'menu',
    component: MenuView
  },
  {
    path: '/menu/edit',
    name: 'menuedit',
    component: MenuEdit
  },
  {
    path: '/menu/add',
    name: 'menuadd',
    component: MenuAdd
  },
  {
    path: '/menu/detail',
    name: 'menudetail',
    component: MenuDetail
  },
  {
    path: '/order',
    name: 'order',
    component: OrderView
  },
  {
    path: '/order/detail',
    name: 'orderdetail',
    component: OrderDetail
  },
  {
    path: '/supplier',
    name: 'supplier',
    component: SupplierView
  },
  {
    path: '/supplier/detail',
    name: 'supplierdetail',
    component: SupplierDetail
  },
  {
    path: '/message',
    name: 'message',
    component: MessageView
  },
  {
    path: '/message/detail',
    name: 'messagedetail',
    component: MessageDetail
  },
  {
    path: '/statistics',
    name: 'statistics',
    component: StatisticsView
  },
  {
    path: '/statistics/detail',
    name: 'statisticsdetail',
    component: StatisticsDetail
  },
  {
    path: '/system',
    name: 'system',
    component: SystemView
  },
  {
    path: '/system/detail',
    name: 'systemdetail',
    component: SystemDetail
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  //mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
