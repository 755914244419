<template>
	<div>

	<el-container class="data_skc">

		<el-aside class="aside-style" width="200px">
			<common-nav selec="1"></common-nav>
		</el-aside>
		
		<el-container>

			<el-header class="common_header">
				<common-header></common-header>
			</el-header>
			
			<el-main>

				<el-row :gutter="24" class="page_bar">
					<el-col :span="20" class="page_bar_title"><i @click="handleGoBack()" class="el-icon-back showhand" style="font-size:22px"></i>  菜谱详情</el-col>
					<el-col :span="4"></el-col>
				</el-row>

				<el-row  style="margin-top:10px">
					<el-col :span="20">
						<el-descriptions class="margin-top" :column="2" size="medium" border>
							<el-descriptions-item>
								<template slot="label">
									菜单日期
								</template>
								{{date_start}} 至 {{date_end}}
							</el-descriptions-item>
							<el-descriptions-item>
								<template slot="label">
									下单状态
								</template>
								<block v-if="is_order == 0">未下单</block>
								<block v-if="is_order == 1">已下单</block>
							</el-descriptions-item>
							<el-descriptions-item>
								<template slot="label">
									菜谱编号
								</template>
								{{this.detailUUID}}
							</el-descriptions-item>
							<el-descriptions-item>
								<template slot="label">
									所属幼儿园
								</template>
								{{this.company_name}}
							</el-descriptions-item>
							
							<el-descriptions-item>
								<template slot="label">
									创建人
								</template>
								{{create_user}}
							</el-descriptions-item>
							<el-descriptions-item>
								<template slot="label">
									创建日期
								</template>
								{{create_date}}
							</el-descriptions-item>
						</el-descriptions>
					</el-col>

				</el-row>

				<el-tabs  class="mt20" v-model="activeName"  @tab-click="handleTabClick">
					<el-tab-pane label="菜单详情" name='menu'>
						<div class="panel_exo">
							<div class="panel_dixz">
								<div class="panel_dixz_label">早餐</div>
								<div class="panel_menu_rowx">
									<div class="panel_menu_rowbig">
										<div class="panel_day_block" v-for="item in menu_zaocan" :key="item.id" >
											<div class="week_txo">{{item.label}}</div>
											<div class="day_block_empty" v-if="item.value.length==0">无菜品</div>
											<div class="dis_dish_row" v-for="(rows, index2) in item.value" :key="index2" >
												{{rows.dish_name}}
											</div>
										</div>
										<div class="clear"></div>
									</div>
								</div>
							</div>
							<div class="panel_dixz">
								<div class="panel_dixz_label">加餐</div>
								<div class="panel_menu_rowx">
									<div class="panel_menu_rowbig">
										<div class="panel_day_block" v-for="item in menu_jiacan" :key="item.id" >
											<div class="week_txo">{{item.label}}</div>
											<div class="day_block_empty" v-if="item.value.length==0">无菜品</div>
											<div class="dis_dish_row" v-for="(rows, index2) in item.value" :key="index2" >
												{{rows.dish_name}}
											</div>
										</div>
										<div class="clear"></div>
									</div>
								</div>
							</div>
							<div class="panel_dixz">
								<div class="panel_dixz_label">午餐</div>
								<div class="panel_menu_rowx">
									<div class="panel_menu_rowbig">
										<div class="panel_day_block" v-for="item in menu_wucan" :key="item.id" >
											<div class="week_txo">{{item.label}}</div>
											<div class="day_block_empty" v-if="item.value.length==0">无菜品</div>
											<div class="dis_dish_row" v-for="(rows, index2) in item.value" :key="index2" >
												{{rows.dish_name}}
											</div>
										</div>
										<div class="clear"></div>
									</div>
								</div>
							</div>
							<div class="panel_dixz">
								<div class="panel_dixz_label">午点</div>
								<div class="panel_menu_rowx">
									<div class="panel_menu_rowbig">
										<div class="panel_day_block" v-for="item in menu_wudian" :key="item.id" >
											<div class="week_txo">{{item.label}}</div>
											<div class="day_block_empty" v-if="item.value.length==0">无菜品</div>
											<div class="dis_dish_row" v-for="(rows, index2) in item.value" :key="index2" >
												{{rows.dish_name}}
											</div>
										</div>
										<div class="clear"></div>
									</div>
								</div>
							</div>
							<div class="panel_dixz">
								<div class="panel_dixz_label">晚餐</div>
								<div class="panel_menu_rowx">
									<div class="panel_menu_rowbig">
										<div class="panel_day_block" v-for="item in menu_wancan" :key="item.id" >
											<div class="week_txo">{{item.label}}</div>
											<div class="day_block_empty" v-if="item.value.length==0">无菜品</div>
											<div class="dis_dish_row" v-for="(rows, index2) in item.value" :key="index2" >
												{{rows.dish_name}}
											</div>
										</div>
										<div class="clear"></div>
									</div>
								</div>
							</div>
						</div>	
					</el-tab-pane>
					<el-tab-pane label="用量与下单" name='dose'>

						<el-row class="barsxk">
							<el-col :span="5">
								下单给:
								<el-select v-model="select_supplier" placeholder="显示全部" size="small">
									<el-option
									v-for="item in supplier_options"
									:key="item.uuid"
									:label="item.company_name"
									:value="item.uuid"
									:disabled="item.disabled">
									</el-option>
								</el-select>
							</el-col>
							<el-col :span="6" :offset="1">
								送货日期:
								<el-date-picker
									v-model="deliver_date"
									type="date" 
									size="small"
									placeholder="选择日期">
								</el-date-picker>
							</el-col>
							<el-col :span="4">
								<el-button type="success" size="small" @click="handleOrderNow">确认下单</el-button>
							</el-col>
						</el-row>

						<el-row>
							<el-col class="sixk_row">
								<el-card class="box-card" shadow="never">
									<div slot="header" class="clearfix">
										<span class="card_sub_title">周一</span>
										<!-- <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button> -->
									</div>
									<div class="diner_line">早餐</div>
									<div class="panel_people_num">
										<span>人数:</span>
										<el-input-number
											size="mini"
											placeholder="用餐人数"
											v-model="MonNum_zaocan" @change="handleMonNum('zaocan')" :min='0' controls-position="right" class="sxik_input" :disabled="MonNumStatus_zaocan">
										</el-input-number>
									</div>
									<div v-for="item in MonDoseList" :key="item.uuid">
										<div v-if="item.meal_type == 'zaocan'" class="panel_food_row" ref="MonNumStatuszaocan">
											{{item.food_name}} <div class="panel_food_wig">{{item.newdose}}{{item.unit}}</div>
											<div v-if="item.dose_package != '0'" class="more_weight">共{{item.dose_package}}{{item.food_unit}}</div>
										</div>
									</div>
									<div class="diner_line">加餐</div>
									<div class="panel_people_num">
										<span>人数:</span>
										<el-input-number
											size="mini"
											placeholder="用餐人数"
											v-model="MonNum_jiacan" @change="handleMonNum('jiacan')" :min='0'  controls-position="right" class="sxik_input" :disabled="MonNumStatus_jiacan">
										</el-input-number>
									</div>
									<div v-for="item in MonDoseList" :key="item.uuid">
										<div v-if="item.meal_type == 'jiacan'" class="panel_food_row MonNumStatus_jiacan">
											{{item.food_name}} <div class="panel_food_wig">{{item.newdose}}{{item.unit}}</div>
											<div v-if="item.dose_package != '0'" class="more_weight">共{{item.dose_package}}{{item.food_unit}}</div>
										</div>
									</div>
									<div class="diner_line">午餐</div>
									<div class="panel_people_num">
										<span>人数:</span>
										<el-input-number
											size="mini"
											placeholder="用餐人数"
											v-model="MonNum_wucan" @change="handleMonNum('wucan')" :min='0'  controls-position="right" class="sxik_input" :disabled="MonNumStatus_wucan">
										</el-input-number>
									</div>
									<div v-for="item in MonDoseList" :key="item.uuid">
										<div v-if="item.meal_type == 'wucan'" class="panel_food_row MonNumStatus_wucan">
											{{item.food_name}} <div class="panel_food_wig">{{item.newdose}}{{item.unit}}</div>
											<div v-if="item.dose_package != '0'" class="more_weight">共{{item.dose_package}}{{item.food_unit}}</div>
										</div>
									</div>
									<div class="diner_line">午点</div>
									<div class="panel_people_num">
										<span>人数:</span>
										<el-input-number
											size="mini"
											placeholder="用餐人数"
											v-model="MonNum_wudian" @change="handleMonNum('wudian')" :min='0'  controls-position="right" class="sxik_input" :disabled="MonNumStatus_wudian">
										</el-input-number>
									</div>
									<div v-for="item in MonDoseList" :key="item.uuid">
										<div v-if="item.meal_type == 'wudian'" class="panel_food_row MonNumStatus_wudian">
											{{item.food_name}} <div class="panel_food_wig">{{item.newdose}}{{item.unit}}</div>
											<div v-if="item.dose_package != '0'" class="more_weight">共{{item.dose_package}}{{item.food_unit}}</div>
										</div>
									</div>
									<div class="diner_line">晚餐</div>
									<div class="panel_people_num">
										<span>人数:</span>
										<el-input-number
											size="mini"
											placeholder="用餐人数"
											v-model="MonNum_wancan" @change="handleMonNum('wancan')" :min='0'  controls-position="right" class="sxik_input" :disabled="MonNumStatus_wancan">
										</el-input-number>
									</div>
									<div v-for="item in MonDoseList" :key="item.uuid">
										<div v-if="item.meal_type == 'wancan'" class="panel_food_row MonNumStatus_wancan">
											{{item.food_name}} <div class="panel_food_wig">{{item.newdose}}{{item.unit}}</div>
											<div v-if="item.dose_package != '0'" class="more_weight">共{{item.dose_package}}{{item.food_unit}}</div>
										</div>
									</div>
								</el-card>
							</el-col>
							<el-col class="sixk_row">
								<el-card class="box-card" shadow="never">
									<div slot="header" class="clearfix">
										<span class="card_sub_title">周二</span>
										<!-- <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button> -->
									</div>
									<div class="diner_line">早餐</div>
									<div class="panel_people_num">
										<span>人数:</span>
										<el-input-number
											size="mini"
											placeholder="用餐人数"
											v-model="TueNum_zaocan" @change="handleTueNum('zaocan')" :min='0' controls-position="right" class="sxik_input" :disabled="TueNumStatus_zaocan">
										</el-input-number>
									</div>
									<div v-for="item in TueDoseList" :key="item.uuid">
										<div v-if="item.meal_type == 'zaocan'" class="panel_food_row TueNumStatus_zaocan">
											{{item.food_name}} <div class="panel_food_wig">{{item.newdose}}{{item.unit}}</div>
											<div v-if="item.dose_package != '0'" class="more_weight">共{{item.dose_package}}{{item.food_unit}}</div>
										</div>
									</div>
									<div class="diner_line">加餐</div>
									<div class="panel_people_num">
										<span>人数:</span>
										<el-input-number
											size="mini"
											placeholder="用餐人数"
											v-model="TueNum_jiacan" @change="handleTueNum('jiacan')" :min='0'  controls-position="right" class="sxik_input" :disabled="TueNumStatus_jiacan">
										</el-input-number>
									</div>
									<div v-for="item in TueDoseList" :key="item.uuid">
										<div v-if="item.meal_type == 'jiacan'" class="panel_food_row TueNumStatus_jiacan">
											{{item.food_name}} <div class="panel_food_wig">{{item.newdose}}{{item.unit}}</div>
											<div v-if="item.dose_package != '0'" class="more_weight">共{{item.dose_package}}{{item.food_unit}}</div>
										</div>
									</div>
									<div class="diner_line">午餐</div>
									<div class="panel_people_num">
										<span>人数:</span>
										<el-input-number
											size="mini"
											placeholder="用餐人数"
											v-model="TueNum_wucan" @change="handleTueNum('wucan')" :min='0'  controls-position="right" class="sxik_input" :disabled="TueNumStatus_wucan">
										</el-input-number>
									</div>
									<div v-for="item in TueDoseList" :key="item.uuid">
										<div v-if="item.meal_type == 'wucan'" class="panel_food_row TueNumStatus_wucan">
											{{item.food_name}} <div class="panel_food_wig">{{item.newdose}}{{item.unit}}</div>
											<div v-if="item.dose_package != '0'" class="more_weight">共{{item.dose_package}}{{item.food_unit}}</div>
										</div>
									</div>
									<div class="diner_line">午点</div>
									<div class="panel_people_num">
										<span>人数:</span>
										<el-input-number
											size="mini"
											placeholder="用餐人数"
											v-model="TueNum_wudian" @change="handleTueNum('wudian')" :min='0'  controls-position="right" class="sxik_input" :disabled="TueNumStatus_wudian">
										</el-input-number>
									</div>
									<div v-for="item in TueDoseList" :key="item.uuid">
										<div v-if="item.meal_type == 'wudian'" class="panel_food_row TueNumStatus_wudian">
											{{item.food_name}} <div class="panel_food_wig">{{item.newdose}}{{item.unit}}</div>
											<div v-if="item.dose_package != '0'" class="more_weight">共{{item.dose_package}}{{item.food_unit}}</div>
										</div>
									</div>
									<div class="diner_line">晚餐</div>
									<div class="panel_people_num">
										<span>人数:</span>
										<el-input-number
											size="mini"
											placeholder="用餐人数"
											v-model="TueNum_wancan" @change="handleTueNum('wancan')" :min='0'  controls-position="right" class="sxik_input" :disabled="TueNumStatus_wancan">
										</el-input-number>
									</div>
									<div v-for="item in TueDoseList" :key="item.uuid">
										<div v-if="item.meal_type == 'wancan'" class="panel_food_row TueNumStatus_wancan">
											{{item.food_name}} <div class="panel_food_wig">{{item.newdose}}{{item.unit}}</div>
											<div v-if="item.dose_package != '0'" class="more_weight">共{{item.dose_package}}{{item.food_unit}}</div>
										</div>
									</div>
								</el-card>
							</el-col>
							<el-col class="sixk_row">
								<el-card class="box-card" shadow="never">
									<div slot="header" class="clearfix">
										<span class="card_sub_title">周三</span>
										<!-- <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button> -->
									</div>
									<div class="diner_line">早餐</div>
									<div class="panel_people_num">
										<span>人数:</span>
										<el-input-number
											size="mini"
											placeholder="用餐人数"
											v-model="WedNum_zaocan" @change="handleWedNum('zaocan')" :min='0' controls-position="right" class="sxik_input" :disabled="WedNumStatus_zaocan">
										</el-input-number>
									</div>
									<div v-for="item in WedDoseList" :key="item.uuid">
										<div v-if="item.meal_type == 'zaocan'" class="panel_food_row WedNumStatus_zaocan">
											{{item.food_name}} <div class="panel_food_wig">{{item.newdose}}{{item.unit}}</div>
											<div v-if="item.dose_package != '0'" class="more_weight">共{{item.dose_package}}{{item.food_unit}}</div>
										</div>
									</div>
									<div class="diner_line">加餐</div>
									<div class="panel_people_num">
										<span>人数:</span>
										<el-input-number
											size="mini"
											placeholder="用餐人数"
											v-model="WedNum_jiacan" @change="handleWedNum('jiacan')" :min='0'  controls-position="right" class="sxik_input" :disabled="WedNumStatus_jiacan">
										</el-input-number>
									</div>
									<div v-for="item in WedDoseList" :key="item.uuid">
										<div v-if="item.meal_type == 'jiacan'" class="panel_food_row WedNumStatus_jiacan">
											{{item.food_name}} <div class="panel_food_wig">{{item.newdose}}{{item.unit}}</div>
											<div v-if="item.dose_package != '0'" class="more_weight">共{{item.dose_package}}{{item.food_unit}}</div>
										</div>
									</div>
									<div class="diner_line">午餐</div>
									<div class="panel_people_num">
										<span>人数:</span>
										<el-input-number
											size="mini"
											placeholder="用餐人数"
											v-model="WedNum_wucan" @change="handleWedNum('wucan')" :min='0'  controls-position="right" class="sxik_input" :disabled="WedNumStatus_wucan">
										</el-input-number>
									</div>
									<div v-for="item in WedDoseList" :key="item.uuid">
										<div v-if="item.meal_type == 'wucan'" class="panel_food_row WedNumStatus_wucan">
											{{item.food_name}} <div class="panel_food_wig">{{item.newdose}}{{item.unit}}</div>
											<div v-if="item.dose_package != '0'" class="more_weight">共{{item.dose_package}}{{item.food_unit}}</div>
										</div>
									</div>
									<div class="diner_line">午点</div>
									<div class="panel_people_num">
										<span>人数:</span>
										<el-input-number
											size="mini"
											placeholder="用餐人数"
											v-model="WedNum_wudian" @change="handleWedNum('wudian')" :min='0'  controls-position="right" class="sxik_input" :disabled="WedNumStatus_wudian">
										</el-input-number>
									</div>
									<div v-for="item in WedDoseList" :key="item.uuid">
										<div v-if="item.meal_type == 'wudian'" class="panel_food_row WedNumStatus_wudian">
											{{item.food_name}} <div class="panel_food_wig">{{item.newdose}}{{item.unit}}</div>
											<div v-if="item.dose_package != '0'" class="more_weight">共{{item.dose_package}}{{item.food_unit}}</div>
										</div>
									</div>
									<div class="diner_line">晚餐</div>
									<div class="panel_people_num">
										<span>人数:</span>
										<el-input-number
											size="mini"
											placeholder="用餐人数"
											v-model="WedNum_wancan" @change="handleWedNum('wancan')" :min='0'  controls-position="right" class="sxik_input" :disabled="WedNumStatus_wancan">
										</el-input-number>
									</div>
									<div v-for="item in WedDoseList" :key="item.uuid">
										<div v-if="item.meal_type == 'wancan'" class="panel_food_row WedNumStatus_wancan">
											{{item.food_name}} <div class="panel_food_wig">{{item.newdose}}{{item.unit}}</div>
											<div v-if="item.dose_package != '0'" class="more_weight">共{{item.dose_package}}{{item.food_unit}}</div>
										</div>
									</div>
								</el-card>
							</el-col>
							<el-col class="sixk_row">
								<el-card class="box-card" shadow="never">
									<div slot="header" class="clearfix">
										<span class="card_sub_title">周四</span>
										<!-- <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button> -->
									</div>
									<div class="diner_line">早餐</div>
									<div class="panel_people_num">
										<span>人数:</span>
										<el-input-number
											size="mini"
											placeholder="用餐人数"
											v-model="ThuNum_zaocan" @change="handleThuNum('zaocan')" :min='0' controls-position="right" class="sxik_input" :disabled="ThuNumStatus_zaocan">
										</el-input-number>
									</div>
									<div v-for="item in ThuDoseList" :key="item.uuid">
										<div v-if="item.meal_type == 'zaocan'" class="panel_food_row ThuNumStatus_zaocan">
											{{item.food_name}} <div class="panel_food_wig">{{item.newdose}}{{item.unit}}</div>
											<div v-if="item.dose_package != '0'" class="more_weight">共{{item.dose_package}}{{item.food_unit}}</div>
										</div>
									</div>
									<div class="diner_line">加餐</div>
									<div class="panel_people_num">
										<span>人数:</span>
										<el-input-number
											size="mini"
											placeholder="用餐人数"
											v-model="ThuNum_jiacan" @change="handleThuNum('jiacan')" :min='0'  controls-position="right" class="sxik_input" :disabled="ThuNumStatus_jiacan">
										</el-input-number>
									</div>
									<div v-for="item in ThuDoseList" :key="item.uuid">
										<div v-if="item.meal_type == 'jiacan'" class="panel_food_row ThuNumStatus_jiacan">
											{{item.food_name}} <div class="panel_food_wig">{{item.newdose}}{{item.unit}}</div>
											<div v-if="item.dose_package != '0'" class="more_weight">共{{item.dose_package}}{{item.food_unit}}</div>
										</div>
									</div>
									<div class="diner_line">午餐</div>
									<div class="panel_people_num">
										<span>人数:</span>
										<el-input-number
											size="mini"
											placeholder="用餐人数"
											v-model="ThuNum_wucan" @change="handleThuNum('wucan')" :min='0'  controls-position="right" class="sxik_input" :disabled="ThuNumStatus_wucan">
										</el-input-number>
									</div>
									<div v-for="item in ThuDoseList" :key="item.uuid">
										<div v-if="item.meal_type == 'wucan'" class="panel_food_row ThuNumStatus_wucan">
											{{item.food_name}} <div class="panel_food_wig">{{item.newdose}}{{item.unit}}</div>
											<div v-if="item.dose_package != '0'" class="more_weight">共{{item.dose_package}}{{item.food_unit}}</div>
										</div>
									</div>
									<div class="diner_line">午点</div>
									<div class="panel_people_num">
										<span>人数:</span>
										<el-input-number
											size="mini"
											placeholder="用餐人数"
											v-model="ThuNum_wudian" @change="handleThuNum('wudian')" :min='0'  controls-position="right" class="sxik_input" :disabled="ThuNumStatus_wudian">
										</el-input-number>
									</div>
									<div v-for="item in ThuDoseList" :key="item.uuid">
										<div v-if="item.meal_type == 'wudian'" class="panel_food_row ThuNumStatus_wudian">
											{{item.food_name}} <div class="panel_food_wig">{{item.newdose}}{{item.unit}}</div>
											<div v-if="item.dose_package != '0'" class="more_weight">共{{item.dose_package}}{{item.food_unit}}</div>
										</div>
									</div>
									<div class="diner_line">晚餐</div>
									<div class="panel_people_num">
										<span>人数:</span>
										<el-input-number
											size="mini"
											placeholder="用餐人数"
											v-model="ThuNum_wancan" @change="handleThuNum('wancan')" :min='0'  controls-position="right" class="sxik_input" :disabled="ThuNumStatus_wancan">
										</el-input-number>
									</div>
									<div v-for="item in ThuDoseList" :key="item.uuid">
										<div v-if="item.meal_type == 'wancan'" class="panel_food_row ThuNumStatus_wancan">
											{{item.food_name}} <div class="panel_food_wig">{{item.newdose}}{{item.unit}}</div>
											<div v-if="item.dose_package != '0'" class="more_weight">共{{item.dose_package}}{{item.food_unit}}</div>
										</div>
									</div>
								</el-card>
							</el-col>
							<el-col class="sixk_row">
								<el-card class="box-card" shadow="never">
									<div slot="header" class="clearfix">
										<span class="card_sub_title">周五</span>
										<!-- <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button> -->
									</div>
									<div class="diner_line">早餐</div>
									<div class="panel_people_num">
										<span>人数:</span>
										<el-input-number
											size="mini"
											placeholder="用餐人数"
											v-model="FriNum_zaocan" @change="handleFriNum('zaocan')" :min='0' controls-position="right" class="sxik_input" :disabled="FriNumStatus_zaocan">
										</el-input-number>
									</div>
									<div v-for="item in FriDoseList" :key="item.uuid">
										<div v-if="item.meal_type == 'zaocan'" class="panel_food_row FriNumStatus_zaocan">
											{{item.food_name}} <div class="panel_food_wig">{{item.newdose}}{{item.unit}}</div>
											<div v-if="item.dose_package != '0'" class="more_weight">共{{item.dose_package}}{{item.food_unit}}</div>
										</div>
									</div>
									<div class="diner_line">加餐</div>
									<div class="panel_people_num">
										<span>人数:</span>
										<el-input-number
											size="mini"
											placeholder="用餐人数"
											v-model="FriNum_jiacan" @change="handleFriNum('jiacan')" :min='0'  controls-position="right" class="sxik_input" :disabled="FriNumStatus_jiacan">
										</el-input-number>
									</div>
									<div v-for="item in FriDoseList" :key="item.uuid">
										<div v-if="item.meal_type == 'jiacan'" class="panel_food_row FriNumStatus_jiacan">
											{{item.food_name}} <div class="panel_food_wig">{{item.newdose}}{{item.unit}}</div>
											<div v-if="item.dose_package != '0'" class="more_weight">共{{item.dose_package}}{{item.food_unit}}</div>
										</div>
									</div>
									<div class="diner_line">午餐</div>
									<div class="panel_people_num">
										<span>人数:</span>
										<el-input-number
											size="mini"
											placeholder="用餐人数"
											v-model="FriNum_wucan" @change="handleFriNum('wucan')" :min='0'  controls-position="right" class="sxik_input" :disabled="FriNumStatus_wucan">
										</el-input-number>
									</div>
									<div v-for="item in FriDoseList" :key="item.uuid">
										<div v-if="item.meal_type == 'wucan'" class="panel_food_row FriNumStatus_wucan">
											{{item.food_name}} <div class="panel_food_wig">{{item.newdose}}{{item.unit}}</div>
											<div v-if="item.dose_package != '0'" class="more_weight">共{{item.dose_package}}{{item.food_unit}}</div>
										</div>
									</div>
									<div class="diner_line">午点</div>
									<div class="panel_people_num">
										<span>人数:</span>
										<el-input-number
											size="mini"
											placeholder="用餐人数"
											v-model="FriNum_wudian" @change="handleFriNum('wudian')" :min='0'  controls-position="right" class="sxik_input" :disabled="FriNumStatus_wudian">
										</el-input-number>
									</div>
									<div v-for="item in FriDoseList" :key="item.uuid">
										<div v-if="item.meal_type == 'wudian'" class="panel_food_row FriNumStatus_wudian">
											{{item.food_name}} <div class="panel_food_wig">{{item.newdose}}{{item.unit}}</div>
											<div v-if="item.dose_package != '0'" class="more_weight">共{{item.dose_package}}{{item.food_unit}}</div>
										</div>
									</div>
									<div class="diner_line">晚餐</div>
									<div class="panel_people_num">
										<span>人数:</span>
										<el-input-number
											size="mini"
											placeholder="用餐人数"
											v-model="FriNum_wancan" @change="handleFriNum('wancan')" :min='0'  controls-position="right" class="sxik_input" :disabled="FriNumStatus_wancan">
										</el-input-number>
									</div>
									<div v-for="item in FriDoseList" :key="item.uuid">
										<div v-if="item.meal_type == 'wancan'" class="panel_food_row FriNumStatus_wancan">
											{{item.food_name}} <div class="panel_food_wig">{{item.newdose}}{{item.unit}}</div>
											<div v-if="item.dose_package != '0'" class="more_weight">共{{item.dose_package}}{{item.food_unit}}</div>
										</div>
									</div>
								</el-card>
							</el-col>
							<el-col class="sixk_row">
								<el-card class="box-card" shadow="never">
									<div slot="header" class="clearfix">
										<span class="card_sub_title">周六</span>
										<!-- <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button> -->
									</div>
									<div class="diner_line">早餐</div>
									<div class="panel_people_num">
										<span>人数:</span>
										<el-input-number
											size="mini"
											placeholder="用餐人数"
											v-model="SatNum_zaocan" @change="handleSatNum('zaocan')" :min='0' controls-position="right" class="sxik_input" :disabled="SatNumStatus_zaocan">
										</el-input-number>
									</div>
									<div v-for="item in SatDoseList" :key="item.uuid">
										<div v-if="item.meal_type == 'zaocan'" class="panel_food_row SatNumStatus_zaocan">
											{{item.food_name}} <div class="panel_food_wig">{{item.newdose}}{{item.unit}}</div>
											<div v-if="item.dose_package != '0'" class="more_weight">共{{item.dose_package}}{{item.food_unit}}</div>
										</div>
									</div>
									<div class="diner_line">加餐</div>
									<div class="panel_people_num">
										<span>人数:</span>
										<el-input-number
											size="mini"
											placeholder="用餐人数"
											v-model="SatNum_jiacan" @change="handleSatNum('jiacan')" :min='0'  controls-position="right" class="sxik_input" :disabled="SatNumStatus_jiacan">
										</el-input-number>
									</div>
									<div v-for="item in SatDoseList" :key="item.uuid">
										<div v-if="item.meal_type == 'jiacan'" class="panel_food_row SatNumStatus_jiacan">
											{{item.food_name}} <div class="panel_food_wig">{{item.newdose}}{{item.unit}}</div>
											<div v-if="item.dose_package != '0'" class="more_weight">共{{item.dose_package}}{{item.food_unit}}</div>
										</div>
									</div>
									<div class="diner_line">午餐</div>
									<div class="panel_people_num">
										<span>人数:</span>
										<el-input-number
											size="mini"
											placeholder="用餐人数"
											v-model="SatNum_wucan" @change="handleSatNum('wucan')" :min='0'  controls-position="right" class="sxik_input" :disabled="SatNumStatus_wucan">
										</el-input-number>
									</div>
									<div v-for="item in SatDoseList" :key="item.uuid">
										<div v-if="item.meal_type == 'wucan'" class="panel_food_row SatNumStatus_wucan">
											{{item.food_name}} <div class="panel_food_wig">{{item.newdose}}{{item.unit}}</div>
											<div v-if="item.dose_package != '0'" class="more_weight">共{{item.dose_package}}{{item.food_unit}}</div>
										</div>
									</div>
									<div class="diner_line">午点</div>
									<div class="panel_people_num">
										<span>人数:</span>
										<el-input-number
											size="mini"
											placeholder="用餐人数"
											v-model="SatNum_wudian" @change="handleSatNum('wudian')" :min='0'  controls-position="right" class="sxik_input" :disabled="SatNumStatus_wudian">
										</el-input-number>
									</div>
									<div v-for="item in SatDoseList" :key="item.uuid">
										<div v-if="item.meal_type == 'wudian'" class="panel_food_row SatNumStatus_wudian">
											{{item.food_name}} <div class="panel_food_wig">{{item.newdose}}{{item.unit}}</div>
											<div v-if="item.dose_package != '0'" class="more_weight">共{{item.dose_package}}{{item.food_unit}}</div>
										</div>
									</div>
									<div class="diner_line">晚餐</div>
									<div class="panel_people_num">
										<span>人数:</span>
										<el-input-number
											size="mini"
											placeholder="用餐人数"
											v-model="SatNum_wancan" @change="handleSatNum('wancan')" :min='0'  controls-position="right" class="sxik_input" :disabled="SatNumStatus_wancan">
										</el-input-number>
									</div>
									<div v-for="item in SatDoseList" :key="item.uuid">
										<div v-if="item.meal_type == 'wancan'" class="panel_food_row SatNumStatus_wancan">
											{{item.food_name}} <div class="panel_food_wig">{{item.newdose}}{{item.unit}}</div>
											<div v-if="item.dose_package != '0'" class="more_weight">共{{item.dose_package}}{{item.food_unit}}</div>
										</div>
									</div>
								</el-card>
							</el-col>
							<el-col class="sixk_row">
								<el-card class="box-card" shadow="never">
									<div slot="header" class="clearfix">
										<span class="card_sub_title">周日</span>
										<!-- <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button> -->
									</div>
									<div class="diner_line">早餐</div>
									<div class="panel_people_num">
										<span>人数:</span>
										<el-input-number
											size="mini"
											placeholder="用餐人数"
											v-model="SunNum_zaocan" @change="handleSunNum('zaocan')" :min='0' controls-position="right" class="sxik_input" :disabled="SunNumStatus_zaocan">
										</el-input-number>
									</div>
									<div v-for="item in SunDoseList" :key="item.uuid">
										<div v-if="item.meal_type == 'zaocan'" class="panel_food_row SunNumStatus_zaocan">
											{{item.food_name}} <div class="panel_food_wig">{{item.newdose}}{{item.unit}}</div>
											<div v-if="item.dose_package != '0'" class="more_weight">共{{item.dose_package}}{{item.food_unit}}</div>
										</div>
									</div>
									<div class="diner_line">加餐</div>
									<div class="panel_people_num">
										<span>人数:</span>
										<el-input-number
											size="mini"
											placeholder="用餐人数"
											v-model="SunNum_jiacan" @change="handleSunNum('jiacan')" :min='0'  controls-position="right" class="sxik_input" :disabled="SunNumStatus_jiacan">
										</el-input-number>
									</div>
									<div v-for="item in SunDoseList" :key="item.uuid">
										<div v-if="item.meal_type == 'jiacan'" class="panel_food_row SunNumStatus_jiacan">
											{{item.food_name}} <div class="panel_food_wig">{{item.newdose}}{{item.unit}}</div>
											<div v-if="item.dose_package != '0'" class="more_weight">共{{item.dose_package}}{{item.food_unit}}</div>
										</div>
									</div>
									<div class="diner_line">午餐</div>
									<div class="panel_people_num">
										<span>人数:</span>
										<el-input-number
											size="mini"
											placeholder="用餐人数"
											v-model="SunNum_wucan" @change="handleSunNum('wucan')" :min='0'  controls-position="right" class="sxik_input" :disabled="SunNumStatus_wucan">
										</el-input-number>
									</div>
									<div v-for="item in SunDoseList" :key="item.uuid">
										<div v-if="item.meal_type == 'wucan'" class="panel_food_row SunNumStatus_wucan">
											{{item.food_name}} <div class="panel_food_wig">{{item.newdose}}{{item.unit}}</div>
											<div v-if="item.dose_package != '0'" class="more_weight">共{{item.dose_package}}{{item.food_unit}}</div>
										</div>
									</div>
									<div class="diner_line">午点</div>
									<div class="panel_people_num">
										<span>人数:</span>
										<el-input-number
											size="mini"
											placeholder="用餐人数"
											v-model="SunNum_wudian" @change="handleSunNum('wudian')" :min='0'  controls-position="right" class="sxik_input" :disabled="SunNumStatus_wudian">
										</el-input-number>
									</div>
									<div v-for="item in SunDoseList" :key="item.uuid">
										<div v-if="item.meal_type == 'wudian'" class="panel_food_row SunNumStatus_wudian">
											{{item.food_name}} <div class="panel_food_wig">{{item.newdose}}{{item.unit}}</div>
											<div v-if="item.dose_package != '0'" class="more_weight">共{{item.dose_package}}{{item.food_unit}}</div>
										</div>
									</div>
									<div class="diner_line">晚餐</div>
									<div class="panel_people_num">
										<span>人数:</span>
										<el-input-number
											size="mini"
											placeholder="用餐人数"
											v-model="SunNum_wancan" @change="handleSunNum('wancan')" :min='0'  controls-position="right" class="sxik_input" :disabled="SunNumStatus_wancan">
										</el-input-number>
									</div>
									<div v-for="item in SunDoseList" :key="item.uuid">
										<div v-if="item.meal_type == 'wancan'" class="panel_food_row SunNumStatus_wancan">
											{{item.food_name}} <div class="panel_food_wig">{{item.newdose}}{{item.unit}}</div>
											<div v-if="item.dose_package != '0'" class="more_weight">共{{item.dose_package}}{{item.food_unit}}</div>
										</div>
									</div>
								</el-card>
							</el-col>
						</el-row>

					</el-tab-pane>
					<el-tab-pane label="营养分析" name='statistics'>
						<el-row >
							<el-col :span="24">
								<div id="maychar"></div>
							</el-col>
						</el-row>
					</el-tab-pane>
					<el-tab-pane  name='message'>
						<span slot="label" class="label_message"><span class="dot_item"></span>问题咨询</span>

						<el-row class="barsxk_op">
							<el-col :span="4">
								<el-button type="success" size="small" @click="handleCreateQuestion">创建新问题</el-button>
							</el-col>
						</el-row>

						<el-empty description="没有记录" v-if="QuestionListEmpty == true"></el-empty>

						<el-card v-for="item in questionDataList" :key="item.id" class="box-card" >
							<block  v-for="logrow in item.logList" :key="logrow.id"  >
								<div class="sixx_oprow" v-if="logrow.chat_type=='1'" >
									{{logrow.message_body}}
									<el-row class="fss_info">
										<el-col :span="10" >相关菜谱编号：{{item.receipt_id}}</el-col>
										<el-col :span="5" >提问人：{{logrow.create_user}}</el-col><el-col :span="5" >日期：{{logrow.create_date}}</el-col>
									</el-row>
								</div>
								<div class="sdxoa_rpw"  v-if="logrow.chat_type=='2'">
									回复：{{logrow.message_body}}
									<el-row class="fss_info">
										<el-col :span="8" >{{logrow.create_date}}</el-col>
									</el-row>
								</div>
							</block>
							<el-row class="mt20">
								<el-col :span="24" style="text-align: right">
									<el-button plain type="success" size="mini" @click="handleCreateSubQuestion(item.id)">追问</el-button>   
								</el-col>
							</el-row>
						</el-card>

					</el-tab-pane>
				</el-tabs>
			</el-main>

			<el-footer> copyright &copy; yyvvi.com </el-footer>
		</el-container>
	</el-container>


	<el-dialog
	title="创建问题"
	:visible.sync="dialogCreateQuestion"
	width="40%"  :close-on-click-modal="false">
	<el-row>
		<el-col :span="24">
			<el-input
			type="textarea"
			:rows="4"
			placeholder="请输入内容"  maxlength="200"  show-word-limit 
			v-model="new_ask_question">
			</el-input>
		</el-col> 
	</el-row>
	<el-row class="mt20">
		<el-col :span="20" >
			相关菜谱编号：{{this.detailUUID}}
		</el-col>
	</el-row>
	<el-row>
		<el-col :span="20" >
			【{{this.company_name}}】提问人：{{create_user}}
		</el-col>
	</el-row>
	<span slot="footer" class="dialog-footer">
		<el-button @click="dialogCreateQuestion = false">取 消</el-button>
		<el-button type="primary" @click="hadleSaveNewQuestion">保存</el-button>
	</span>
	</el-dialog>

	<el-dialog
		title="追问"
		:visible.sync="dialogCreateSubQuestion"
		width="30%"  :close-on-click-modal="false">
		<el-row>
			<el-col :span="24">
				<el-input
				type="textarea"
				:rows="3"
				placeholder="请输入内容"  maxlength="200"  show-word-limit 
				v-model="subask_question">
				</el-input>
			</el-col> 
		</el-row>
		<span slot="footer" class="dialog-footer">
			<el-button @click="dialogCreateSubQuestion = false">取 消</el-button>
			<el-button type="primary" @click="hadleSaveNewSubQuestion">保存</el-button>
		</span>
	</el-dialog>



	</div>
</template>

<script>
import CommonNav from "@/components/Nav.vue";
import CommonHeader from "@/components/Header.vue";
import * as MN from '@/api/common';

export default {
    name: 'crm', 
	props:['selec'],
	data() {
		return {
			subask_question:'',
			main_question_id:'',
			dialogCreateSubQuestion:false,
			new_ask_question:'',
			QuestionListEmpty:true,
			questionDataList:[],
			dialogCreateQuestion:false,
			is_order:'',
			company_name:'',
			MonNum:0,
			MonNum_zaocan:0,
			MonNum_jiacan:0,
			MonNum_wucan:0,
			MonNum_wudian:0,
			MonNum_wancan:0,
			TueNum:0,
			TueNum_zaocan:0,
			TueNum_jiacan:0,
			TueNum_wucan:0,
			TueNum_wudian:0,
			TueNum_wancan:0,
			WedNum:0,
			WedNum_zaocan:0,
			WedNum_jiacan:0,
			WedNum_wucan:0,
			WedNum_wudian:0,
			WedNum_wancan:0,
			ThuNum:0,
			ThuNum_zaocan:0,
			ThuNum_jiacan:0,
			ThuNum_wucan:0,
			ThuNum_wudian:0,
			ThuNum_wancan:0,
			FriNum:0,
			FriNum_zaocan:0,
			FriNum_jiacan:0,
			FriNum_wucan:0,
			FriNum_wudian:0,
			FriNum_wancan:0,
			SatNum:0,
			SatNum_zaocan:0,
			SatNum_jiacan:0,
			SatNum_wucan:0,
			SatNum_wudian:0,
			SatNum_wancan:0,
			SunNum:0,
			SunNum_zaocan:0,
			SunNum_jiacan:0,
			SunNum_wucan:0,
			SunNum_wudian:0,
			SunNum_wancan:0,
			MonDoseList:[],
			TueDoseList:[],
			WedDoseList:[],
			ThuDoseList:[],
			FriDoseList:[],
			SatDoseList:[],
			SunDoseList:[],
			MonNumStatus:false,
			TueNumStatus:false,
			WedNumStatus:false,
			ThuNumStatus:false,
			FriNumStatus:false,
			SatNumStatus:false,
			SunNumStatus:false,
			MonNumStatus_zaocan:false,
			MonNumStatus_jiacan:false,
			MonNumStatus_wucan:false,
			MonNumStatus_wudian:false,
			MonNumStatus_wancan:false,
			TueNumStatus_zaocan:false,
			TueNumStatus_jiacan:false,
			TueNumStatus_wucan:false,
			TueNumStatus_wudian:false,
			TueNumStatus_wancan:false,
			WedNumStatus_zaocan:false,
			WedNumStatus_jiacan:false,
			WedNumStatus_wucan:false,
			WedNumStatus_wudian:false,
			WedNumStatus_wancan:false,
			ThuNumStatus_zaocan:false,
			ThuNumStatus_jiacan:false,
			ThuNumStatus_wucan:false,
			ThuNumStatus_wudian:false,
			ThuNumStatus_wancan:false,
			FriNumStatus_zaocan:false,
			FriNumStatus_jiacan:false,
			FriNumStatus_wucan:false,
			FriNumStatus_wudian:false,
			FriNumStatus_wancan:false,
			SatNumStatus_zaocan:false,
			SatNumStatus_jiacan:false,
			SatNumStatus_wucan:false,
			SatNumStatus_wudian:false,
			SatNumStatus_wancan:false,
			SunNumStatus_zaocan:false,
			SunNumStatus_jiacan:false,
			SunNumStatus_wucan:false,
			SunNumStatus_wudian:false,
			SunNumStatus_wancan:false,
			select_supplier:'',
			supplier_options:[
				{value: '0',label: '显示全部'},
				{value: '10',label: '西安XXXX供应链有限公司'},
				{value: '20',label: '显示全部'},
				{value: '40',label: '显示全部'},
				{value: '30',label: '显示全部'},
				{value: '50',label: '显示全部'},
			],
			deliver_date:'',
			activeName:'menu',
			loading:true,
			boxshow: false, 
			curTipsID: '',
			userCount:0,
			detailData: [],
			detailList:[],
			detailUUID:this.$route.query.uuid,
			kindergarten_uuid:'',
			topicsLink:'',
			date_start:'',
			date_end:'',
			activateFoodList:[],
			menu_zaocan:[
				{id:1,label:'周一 Mon',value:[]},
				{id:2,label:'周二 Tue',value:[]},
				{id:3,label:'周三 Wed',value:[]},
				{id:4,label:'周四 Thu',value:[]},
				{id:5,label:'周五 Fri',value:[]},
				{id:6,label:'周六 Sat',value:[]},
				{id:7,label:'周日 Sun',value:[]}
			],
			menu_jiacan:[
				{id:1,label:'周一 Mon',value:[]},
				{id:2,label:'周二 Tue',value:[]},
				{id:3,label:'周三 Wed',value:[]},
				{id:4,label:'周四 Thu',value:[]},
				{id:5,label:'周五 Fri',value:[]},
				{id:6,label:'周六 Sat',value:[]},
				{id:7,label:'周日 Sun',value:[]}
			],
			menu_wucan:[
				{id:1,label:'周一 Mon',value:[]},
				{id:2,label:'周二 Tue',value:[]},
				{id:3,label:'周三 Wed',value:[]},
				{id:4,label:'周四 Thu',value:[]},
				{id:5,label:'周五 Fri',value:[]},
				{id:6,label:'周六 Sat',value:[]},
				{id:7,label:'周日 Sun',value:[]}
			],
			menu_wudian:[
				{id:1,label:'周一 Mon',value:[]},
				{id:2,label:'周二 Tue',value:[]},
				{id:3,label:'周三 Wed',value:[]},
				{id:4,label:'周四 Thu',value:[]},
				{id:5,label:'周五 Fri',value:[]},
				{id:6,label:'周六 Sat',value:[]},
				{id:7,label:'周日 Sun',value:[]}
			],
			menu_wancan:[
				{id:1,label:'周一 Mon',value:[]},
				{id:2,label:'周二 Tue',value:[]},
				{id:3,label:'周三 Wed',value:[]},
				{id:4,label:'周四 Thu',value:[]},
				{id:5,label:'周五 Fri',value:[]},
				{id:6,label:'周六 Sat',value:[]},
				{id:7,label:'周日 Sun',value:[]}
			],
		}
	},
	components: {
		CommonNav,
		CommonHeader
	},

	created() {
		this.initData()
		this.getFoodList();
	},

	mounted() {
		this.getCharts()
	},
	methods: {
		getFoodList(){
			let me = this;
			MN.getActivateFoodList().then(res => {
				me.activateFoodList = res;
			})
		},
		hadleSaveNewSubQuestion(){
			let me = this;
			if( !this.subask_question ){
				this.$message.warning('请输入问题');
				return
			}
			let post={
				chat_type:'1',
				question_id:me.main_question_id,
				sub_question:me.subask_question
			}
			MN.setSaveNewSubQuestion(post).then(res => {
				if( res == 'add_ok' ){
					me.dialogCreateSubQuestion = false;
					me.$message.success('已保存');
					me.initMessageData();
				}
			})
		},
		handleCreateSubQuestion(qid){
			this.main_question_id = qid;
			this.subask_question = '';
			this.dialogCreateSubQuestion = true
		},
		hadleSaveNewQuestion(){
			let me = this;
			if( !this.new_ask_question ){
				this.$message.warning('请输入问题');
				return
			}
			let post={
				menu_uuid:me.detailUUID,
				question:me.new_ask_question,
				type_i:'1'
			}
			MN.setSaveNewQuestion(post).then(res => {
				if( res == 'add_ok' ){
					me.dialogCreateQuestion = false;
					me.$message.success('已保存');
					me.initMessageData();
				}
			})
		},
		handleCreateQuestion(){
			this.new_ask_question = '';
			this.dialogCreateQuestion = true;
		},
		handleOrderNow(){
			
			if ( !this.select_supplier ){
				this.$message.warning('请选择供应商');
				return
			}

			if ( !this.deliver_date ){
				this.$message.warning('请选择送货日期');
				return
			}

			let me = this;
			this.$confirm('确认下单给供应商吗？', '提示', {
				confirmButtonText: '下单',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {

				let post={
					menu_uuid:me.detailUUID,
					kindergarten_uuid:me.kindergarten_uuid,
					deliver_date:me.deliver_date,
					supplier_uuid:me.select_supplier,
					MonNum_zaocan:me.MonNum_zaocan,
					MonNum_jiacan:me.MonNum_jiacan,
					MonNum_wucan:me.MonNum_wucan,
					MonNum_wudian:me.MonNum_wudian,
					MonNum_wancan:me.MonNum_wancan,
					TueNum_zaocan:me.TueNum_zaocan,
					TueNum_jiacan:me.TueNum_jiacan,
					TueNum_wucan:me.TueNum_wucan,
					TueNum_wudian:me.TueNum_wudian,
					TueNum_wancan:me.TueNum_wancan,
					WedNum_zaocan:me.WedNum_zaocan,
					WedNum_jiacan:me.WedNum_jiacan,
					WedNum_wucan:me.WedNum_wucan,
					WedNum_wudian:me.WedNum_wudian,
					WedNum_wancan:me.WedNum_wancan,
					ThuNum_zaocan:me.ThuNum_zaocan,
					ThuNum_jiacan:me.ThuNum_jiacan,
					ThuNum_wucan:me.ThuNum_wucan,
					ThuNum_wudian:me.ThuNum_wudian,
					ThuNum_wancan:me.ThuNum_wancan,
					FriNum_zaocan:me.FriNum_zaocan,
					FriNum_jiacan:me.FriNum_jiacan,
					FriNum_wucan:me.FriNum_wucan,
					FriNum_wudian:me.FriNum_wudian,
					FriNum_wancan:me.FriNum_wancan,
					SatNum_zaocan:me.SatNum_zaocan,
					SatNum_jiacan:me.SatNum_jiacan,
					SatNum_wucan:me.SatNum_wucan,
					SatNum_wudian:me.SatNum_wudian,
					SatNum_wancan:me.SatNum_wancan,
					SunNum_zaocan:me.SunNum_zaocan,
					SunNum_jiacan:me.SunNum_jiacan,
					SunNum_wucan:me.SunNum_wucan,
					SunNum_wudian:me.SunNum_wudian,
					SunNum_wancan:me.SunNum_wancan,
					MonDoseList:JSON.stringify(me.MonDoseList),
					TueDoseList:JSON.stringify(me.TueDoseList),
					WedDoseList:JSON.stringify(me.WedDoseList),
					ThuDoseList:JSON.stringify(me.ThuDoseList),
					FriDoseList:JSON.stringify(me.FriDoseList),
					SatDoseList:JSON.stringify(me.SatDoseList),
					SunDoseList:JSON.stringify(me.SunDoseList),
				}
				MN.getSaveMenuOrder(post).then(res => {
					if( res == 'order_ok' ){
						me.$message.success('已成功下单');
						me.$router.push('/menu')
					}
				})

			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消'
				});          
			});
		},
		handleMonNum(meal_type){
			console.log("meal_type",meal_type)
			console.log("this.MonDoseList",this.MonDoseList)
			console.log("activateFoodList",this.activateFoodList)
			
			if ( meal_type == 'zaocan' ){
				for(let i = 0 ; i <this.MonDoseList.length;i++){
					if ( this.MonDoseList[i].meal_type == 'zaocan'){
						let tempdoes = parseInt(this.MonDoseList[i].dose) * parseInt(this.MonNum_zaocan); 
						this.MonDoseList[i].newdose = tempdoes.toFixed(2);
						this.MonDoseList[i].dose_package = 0;
						this.MonDoseList[i].food_unit = 'g';
						this.activateFoodList.forEach((items,index) => {
							if ( this.MonDoseList[i].food_id == items.id && items.unit != 'g' ){
								this.MonDoseList[i].dose_package =  Math.ceil(parseFloat(tempdoes) / parseFloat(items.unit_weight));
								this.MonDoseList[i].food_unit = items.unit
							}
						});
					}
				}
			}

			console.log("MonDoseList",this.MonDoseList)

			if ( meal_type == 'jiacan' ){
				for(let i = 0 ; i <this.MonDoseList.length;i++){
					if ( this.MonDoseList[i].meal_type == 'jiacan'){
						this.MonDoseList[i].newdose = tempdoes.toFixed(2);
						this.MonDoseList[i].dose_package = 0;
						this.MonDoseList[i].food_unit = 'g';
						this.activateFoodList.forEach((items,index) => {
							if ( this.MonDoseList[i].food_id == items.id && items.unit != 'g' ){
								this.MonDoseList[i].dose_package =  Math.ceil(parseFloat(tempdoes) / parseFloat(items.unit_weight));
								this.MonDoseList[i].food_unit = items.unit
							}
						});
					}
				}
			}

			if ( meal_type == 'wucan' ){
				for(let i = 0 ; i <this.MonDoseList.length;i++){
					if ( this.MonDoseList[i].meal_type == 'wucan'){
						let tempdoes = parseInt(this.MonDoseList[i].dose) * parseInt(this.MonNum_wucan); 
						this.MonDoseList[i].newdose = tempdoes.toFixed(2);
						this.MonDoseList[i].dose_package = 0;
						this.MonDoseList[i].food_unit = 'g';
						this.activateFoodList.forEach((items,index) => {
							if ( this.MonDoseList[i].food_id == items.id && items.unit != 'g' ){
								this.MonDoseList[i].dose_package =  Math.ceil(parseFloat(tempdoes) / parseFloat(items.unit_weight));
								this.MonDoseList[i].food_unit = items.unit
							}
						});
					}
				}
			}

			if ( meal_type == 'wudian' ){
				for(let i = 0 ; i <this.MonDoseList.length;i++){
					if ( this.MonDoseList[i].meal_type == 'wudian'){
						let tempdoes = parseInt(this.MonDoseList[i].dose) * parseInt(this.MonNum_wudian); 
						this.MonDoseList[i].newdose = tempdoes.toFixed(2);
						this.MonDoseList[i].dose_package = 0;
						this.MonDoseList[i].food_unit = 'g';
						this.activateFoodList.forEach((items,index) => {
							if ( this.MonDoseList[i].food_id == items.id && items.unit != 'g' ){
								this.MonDoseList[i].dose_package =  Math.ceil(parseFloat(tempdoes) / parseFloat(items.unit_weight));
								this.MonDoseList[i].food_unit = items.unit
							}
						});
					}
				}
			}

			if ( meal_type == 'wancan' ){
				for(let i = 0 ; i <this.MonDoseList.length;i++){
					if ( this.MonDoseList[i].meal_type == 'wancan'){
						let tempdoes = parseInt(this.MonDoseList[i].dose) * parseInt(this.MonNum_wancan); 
						this.MonDoseList[i].newdose = tempdoes.toFixed(2);
						this.MonDoseList[i].dose_package = 0;
						this.MonDoseList[i].food_unit = 'g';
						this.activateFoodList.forEach((items,index) => {
							if ( this.MonDoseList[i].food_id == items.id && items.unit != 'g' ){
								this.MonDoseList[i].dose_package =  Math.ceil(parseFloat(tempdoes) / parseFloat(items.unit_weight));
								this.MonDoseList[i].food_unit = items.unit
							}
						});
					}
				}
			}


			
		},
		handleTueNum(meal_type){
			
			if ( meal_type == 'zaocan' ){
				for(let i = 0 ; i <this.TueDoseList.length;i++){
					if ( this.TueDoseList[i].meal_type == 'zaocan'){
						let tempdoes = parseInt(this.TueDoseList[i].dose) * parseInt(this.TueNum_zaocan); 
						this.TueDoseList[i].newdose = tempdoes.toFixed(2);
						this.TueDoseList[i].dose_package = 0;
						this.TueDoseList[i].food_unit = 'g';
						this.activateFoodList.forEach((items,index) => {
							if ( this.TueDoseList[i].food_id == items.id && items.unit != 'g' ){
								this.TueDoseList[i].dose_package =  Math.ceil(parseFloat(tempdoes) / parseFloat(items.unit_weight));
								this.TueDoseList[i].food_unit = items.unit
							}
						});
					}
				}
			}

			if ( meal_type == 'jiacan' ){
				for(let i = 0 ; i <this.TueDoseList.length;i++){
					if ( this.TueDoseList[i].meal_type == 'jiacan'){
						let tempdoes = parseInt(this.TueDoseList[i].dose) * parseInt(this.TueNum_jiacan); 
						this.TueDoseList[i].newdose = tempdoes.toFixed(2);
						this.TueDoseList[i].dose_package = 0;
						this.TueDoseList[i].food_unit = 'g';
						this.activateFoodList.forEach((items,index) => {
							if ( this.TueDoseList[i].food_id == items.id && items.unit != 'g' ){
								this.TueDoseList[i].dose_package =  Math.ceil(parseFloat(tempdoes) / parseFloat(items.unit_weight));
								this.TueDoseList[i].food_unit = items.unit
							}
						});
					}
				}
			}

			if ( meal_type == 'wucan' ){
				for(let i = 0 ; i <this.TueDoseList.length;i++){
					if ( this.TueDoseList[i].meal_type == 'wucan'){
						let tempdoes = parseInt(this.TueDoseList[i].dose) * parseInt(this.TueNum_wucan); 
						this.TueDoseList[i].newdose = tempdoes.toFixed(2);
						this.TueDoseList[i].dose_package = 0;
						this.TueDoseList[i].food_unit = 'g';
						this.activateFoodList.forEach((items,index) => {
							if ( this.TueDoseList[i].food_id == items.id && items.unit != 'g' ){
								this.TueDoseList[i].dose_package =  Math.ceil(parseFloat(tempdoes) / parseFloat(items.unit_weight));
								this.TueDoseList[i].food_unit = items.unit
							}
						});
					}
				}
			}

			if ( meal_type == 'wudian' ){
				for(let i = 0 ; i <this.TueDoseList.length;i++){
					if ( this.TueDoseList[i].meal_type == 'wudian'){
						let tempdoes = parseInt(this.TueDoseList[i].dose) * parseInt(this.TueNum_wudian); 
						this.TueDoseList[i].newdose = tempdoes.toFixed(2);
						this.TueDoseList[i].dose_package = 0;
						this.TueDoseList[i].food_unit = 'g';
						this.activateFoodList.forEach((items,index) => {
							if ( this.TueDoseList[i].food_id == items.id && items.unit != 'g' ){
								this.TueDoseList[i].dose_package =  Math.ceil(parseFloat(tempdoes) / parseFloat(items.unit_weight));
								this.TueDoseList[i].food_unit = items.unit
							}
						});
					}
				}
			}

			if ( meal_type == 'wancan' ){
				for(let i = 0 ; i <this.TueDoseList.length;i++){
					if ( this.TueDoseList[i].meal_type == 'wancan'){
						let tempdoes = parseInt(this.TueDoseList[i].dose) * parseInt(this.TueNum_wancan); 
						this.TueDoseList[i].newdose = tempdoes.toFixed(2);
						this.TueDoseList[i].dose_package = 0;
						this.TueDoseList[i].food_unit = 'g';
						this.activateFoodList.forEach((items,index) => {
							if ( this.TueDoseList[i].food_id == items.id && items.unit != 'g' ){
								this.TueDoseList[i].dose_package =  Math.ceil(parseFloat(tempdoes) / parseFloat(items.unit_weight));
								this.TueDoseList[i].food_unit = items.unit
							}
						});
					}
				}
			}
		},
		handleWedNum(meal_type){
			
			if ( meal_type == 'zaocan' ){
				for(let i = 0 ; i <this.WedDoseList.length;i++){
					if ( this.WedDoseList[i].meal_type == 'zaocan'){
						let tempdoes = parseInt(this.WedDoseList[i].dose) * parseInt(this.WedNum_zaocan); 
						
						this.WedDoseList[i].newdose = tempdoes.toFixed(2);
						this.WedDoseList[i].dose_package = 0;
						this.WedDoseList[i].food_unit = 'g';
						this.activateFoodList.forEach((items,index) => {
							if ( this.WedDoseList[i].food_id == items.id && items.unit != 'g' ){
								this.WedDoseList[i].dose_package =  Math.ceil(parseFloat(tempdoes) / parseFloat(items.unit_weight));
								this.WedDoseList[i].food_unit = items.unit
							}
						});
					}
				}
			}

			if ( meal_type == 'jiacan' ){
				for(let i = 0 ; i <this.WedDoseList.length;i++){
					if ( this.WedDoseList[i].meal_type == 'jiacan'){
						let tempdoes = parseInt(this.WedDoseList[i].dose) * parseInt(this.WedNum_jiacan); 
						this.WedDoseList[i].newdose = tempdoes.toFixed(2);
						this.WedDoseList[i].dose_package = 0;
						this.WedDoseList[i].food_unit = 'g';
						this.activateFoodList.forEach((items,index) => {
							if ( this.WedDoseList[i].food_id == items.id && items.unit != 'g' ){
								this.WedDoseList[i].dose_package =  Math.ceil(parseFloat(tempdoes) / parseFloat(items.unit_weight));
								this.WedDoseList[i].food_unit = items.unit
							}
						});
					}
				}
			}

			if ( meal_type == 'wucan' ){
				for(let i = 0 ; i <this.WedDoseList.length;i++){
					if ( this.WedDoseList[i].meal_type == 'wucan'){
						let tempdoes = parseInt(this.WedDoseList[i].dose) * parseInt(this.WedNum_wucan); 
						this.WedDoseList[i].newdose = tempdoes.toFixed(2);
						this.WedDoseList[i].dose_package = 0;
						this.WedDoseList[i].food_unit = 'g';
						this.activateFoodList.forEach((items,index) => {
							if ( this.WedDoseList[i].food_id == items.id && items.unit != 'g' ){
								this.WedDoseList[i].dose_package =  Math.ceil(parseFloat(tempdoes) / parseFloat(items.unit_weight));
								this.WedDoseList[i].food_unit = items.unit
							}
						});
					}
				}
			}

			if ( meal_type == 'wudian' ){
				for(let i = 0 ; i <this.WedDoseList.length;i++){
					if ( this.WedDoseList[i].meal_type == 'wudian'){
						let tempdoes = parseInt(this.WedDoseList[i].dose) * parseInt(this.WedNum_wudian); 
						this.WedDoseList[i].newdose = tempdoes.toFixed(2);
						this.WedDoseList[i].dose_package = 0;
						this.WedDoseList[i].food_unit = 'g';
						this.activateFoodList.forEach((items,index) => {
							if ( this.WedDoseList[i].food_id == items.id && items.unit != 'g' ){
								this.WedDoseList[i].dose_package =  Math.ceil(parseFloat(tempdoes) / parseFloat(items.unit_weight));
								this.WedDoseList[i].food_unit = items.unit
							}
						});
					}
				}
			}

			if ( meal_type == 'wancan' ){
				for(let i = 0 ; i <this.WedDoseList.length;i++){
					if ( this.WedDoseList[i].meal_type == 'wancan'){
						let tempdoes = parseInt(this.WedDoseList[i].dose) * parseInt(this.WedNum_wancan); 
						this.WedDoseList[i].newdose = tempdoes.toFixed(2);
						this.WedDoseList[i].dose_package = 0;
						this.WedDoseList[i].food_unit = 'g';
						this.activateFoodList.forEach((items,index) => {
							if ( this.WedDoseList[i].food_id == items.id && items.unit != 'g' ){
								this.WedDoseList[i].dose_package =  Math.ceil(parseFloat(tempdoes) / parseFloat(items.unit_weight));
								this.WedDoseList[i].food_unit = items.unit
							}
						});
					}
				}
			}
		},
		handleThuNum(meal_type){
			
			if ( meal_type == 'zaocan' ){
				for(let i = 0 ; i <this.ThuDoseList.length;i++){
					if ( this.ThuDoseList[i].meal_type == 'zaocan'){
						let tempdoes = parseInt(this.ThuDoseList[i].dose) * parseInt(this.ThuNum_zaocan); 
						this.ThuDoseList[i].newdose = tempdoes.toFixed(2);
						this.ThuDoseList[i].dose_package = 0;
						this.ThuDoseList[i].food_unit = 'g';
						this.activateFoodList.forEach((items,index) => {
							if ( this.ThuDoseList[i].food_id == items.id && items.unit != 'g' ){
								this.ThuDoseList[i].dose_package =  Math.ceil(parseFloat(tempdoes) / parseFloat(items.unit_weight));
								this.ThuDoseList[i].food_unit = items.unit
							}
						});
					}
				}
			}

			if ( meal_type == 'jiacan' ){
				for(let i = 0 ; i <this.ThuDoseList.length;i++){
					if ( this.ThuDoseList[i].meal_type == 'jiacan'){
						let tempdoes = parseInt(this.ThuDoseList[i].dose) * parseInt(this.ThuNum_jiacan); 
						this.ThuDoseList[i].newdose = tempdoes.toFixed(2);
						this.ThuDoseList[i].dose_package = 0;
						this.ThuDoseList[i].food_unit = 'g';
						this.activateFoodList.forEach((items,index) => {
							if ( this.ThuDoseList[i].food_id == items.id && items.unit != 'g' ){
								this.ThuDoseList[i].dose_package =  Math.ceil(parseFloat(tempdoes) / parseFloat(items.unit_weight));
								this.ThuDoseList[i].food_unit = items.unit
							}
						});
					}
				}
			}

			if ( meal_type == 'wucan' ){
				for(let i = 0 ; i <this.ThuDoseList.length;i++){
					if ( this.ThuDoseList[i].meal_type == 'wucan'){
						let tempdoes = parseInt(this.ThuDoseList[i].dose) * parseInt(this.ThuNum_wucan); 
						this.ThuDoseList[i].newdose = tempdoes.toFixed(2);
						this.ThuDoseList[i].dose_package = 0;
						this.ThuDoseList[i].food_unit = 'g';
						this.activateFoodList.forEach((items,index) => {
							if ( this.ThuDoseList[i].food_id == items.id && items.unit != 'g' ){
								this.ThuDoseList[i].dose_package =  Math.ceil(parseFloat(tempdoes) / parseFloat(items.unit_weight));
								this.ThuDoseList[i].food_unit = items.unit
							}
						});
					}
				}
			}

			if ( meal_type == 'wudian' ){
				for(let i = 0 ; i <this.ThuDoseList.length;i++){
					if ( this.ThuDoseList[i].meal_type == 'wudian'){
						let tempdoes = parseInt(this.ThuDoseList[i].dose) * parseInt(this.ThuNum_wudian); 
						this.ThuDoseList[i].newdose = tempdoes.toFixed(2);
						this.ThuDoseList[i].dose_package = 0;
						this.ThuDoseList[i].food_unit = 'g';
						this.activateFoodList.forEach((items,index) => {
							if ( this.ThuDoseList[i].food_id == items.id && items.unit != 'g' ){
								this.ThuDoseList[i].dose_package =  Math.ceil(parseFloat(tempdoes) / parseFloat(items.unit_weight));
								this.ThuDoseList[i].food_unit = items.unit
							}
						});
					}
				}
			}

			if ( meal_type == 'wancan' ){
				for(let i = 0 ; i <this.ThuDoseList.length;i++){
					if ( this.ThuDoseList[i].meal_type == 'wancan'){
						let tempdoes = parseInt(this.ThuDoseList[i].dose) * parseInt(this.ThuNum_wancan); 
						this.ThuDoseList[i].newdose = tempdoes.toFixed(2);
						this.ThuDoseList[i].dose_package = 0;
						this.ThuDoseList[i].food_unit = 'g';
						this.activateFoodList.forEach((items,index) => {
							if ( this.ThuDoseList[i].food_id == items.id && items.unit != 'g' ){
								this.ThuDoseList[i].dose_package =  Math.ceil(parseFloat(tempdoes) / parseFloat(items.unit_weight));
								this.ThuDoseList[i].food_unit = items.unit
							}
						});
					}
				}
			}
		},
		handleFriNum(meal_type){
			if ( meal_type == 'zaocan' ){
				for(let i = 0 ; i <this.FriDoseList.length;i++){
					if ( this.FriDoseList[i].meal_type == 'zaocan'){
						let tempdoes = parseInt(this.FriDoseList[i].dose) * parseInt(this.FriNum_zaocan); 
						this.FriDoseList[i].newdose = tempdoes.toFixed(2);
						this.FriDoseList[i].dose_package = 0;
						this.FriDoseList[i].food_unit = 'g';
						this.activateFoodList.forEach((items,index) => {
							if ( this.FriDoseList[i].food_id == items.id && items.unit != 'g' ){
								this.FriDoseList[i].dose_package =  Math.ceil(parseFloat(tempdoes) / parseFloat(items.unit_weight));
								this.FriDoseList[i].food_unit = items.unit
							}
						});
					}
				}
			}

			if ( meal_type == 'jiacan' ){
				for(let i = 0 ; i <this.FriDoseList.length;i++){
					if ( this.FriDoseList[i].meal_type == 'jiacan'){
						let tempdoes = parseInt(this.FriDoseList[i].dose) * parseInt(this.FriNum_jiacan); 
						this.FriDoseList[i].newdose = tempdoes.toFixed(2);
						this.FriDoseList[i].dose_package = 0;
						this.FriDoseList[i].food_unit = 'g';
						this.activateFoodList.forEach((items,index) => {
							if ( this.FriDoseList[i].food_id == items.id && items.unit != 'g' ){
								this.FriDoseList[i].dose_package =  Math.ceil(parseFloat(tempdoes) / parseFloat(items.unit_weight));
								this.FriDoseList[i].food_unit = items.unit
							}
						});
					}
				}
			}

			if ( meal_type == 'wucan' ){
				for(let i = 0 ; i <this.FriDoseList.length;i++){
					if ( this.FriDoseList[i].meal_type == 'wucan'){
						let tempdoes = parseInt(this.FriDoseList[i].dose) * parseInt(this.FriNum_wucan); 
						this.FriDoseList[i].newdose = tempdoes.toFixed(2);
						this.FriDoseList[i].dose_package = 0;
						this.FriDoseList[i].food_unit = 'g';
						this.activateFoodList.forEach((items,index) => {
							if ( this.FriDoseList[i].food_id == items.id && items.unit != 'g' ){
								this.FriDoseList[i].dose_package =  Math.ceil(parseFloat(tempdoes) / parseFloat(items.unit_weight));
								this.FriDoseList[i].food_unit = items.unit
							}
						});
					}
				}
			}

			if ( meal_type == 'wudian' ){
				for(let i = 0 ; i <this.FriDoseList.length;i++){
					if ( this.FriDoseList[i].meal_type == 'wudian'){
						let tempdoes = parseInt(this.FriDoseList[i].dose) * parseInt(this.FriNum_wudian); 
						this.FriDoseList[i].newdose = tempdoes.toFixed(2);
						this.FriDoseList[i].dose_package = 0;
						this.FriDoseList[i].food_unit = 'g';
						this.activateFoodList.forEach((items,index) => {
							if ( this.FriDoseList[i].food_id == items.id && items.unit != 'g' ){
								this.FriDoseList[i].dose_package =  Math.ceil(parseFloat(tempdoes) / parseFloat(items.unit_weight));
								this.FriDoseList[i].food_unit = items.unit
							}
						});
					}
				}
			}

			if ( meal_type == 'wancan' ){
				for(let i = 0 ; i <this.FriDoseList.length;i++){
					if ( this.FriDoseList[i].meal_type == 'wancan'){
						let tempdoes = parseInt(this.FriDoseList[i].dose) * parseInt(this.FriNum_wancan); 
						this.FriDoseList[i].newdose = tempdoes.toFixed(2);
						this.FriDoseList[i].dose_package = 0;
						this.FriDoseList[i].food_unit = 'g';
						this.activateFoodList.forEach((items,index) => {
							if ( this.FriDoseList[i].food_id == items.id && items.unit != 'g' ){
								this.FriDoseList[i].dose_package =  Math.ceil(parseFloat(tempdoes) / parseFloat(items.unit_weight));
								this.FriDoseList[i].food_unit = items.unit
							}
						});
					}
				}
			}
		},
		handleSatNum(meal_type){
			
			if ( meal_type == 'zaocan' ){
				for(let i = 0 ; i <this.SatDoseList.length;i++){
					if ( this.SatDoseList[i].meal_type == 'zaocan'){
						let tempdoes = parseInt(this.SatDoseList[i].dose) * parseInt(this.SatNum_zaocan); 
						this.SatDoseList[i].newdose = tempdoes.toFixed(2);
						this.SatDoseList[i].dose_package = 0;
						this.SatDoseList[i].food_unit = 'g';
						this.activateFoodList.forEach((items,index) => {
							if ( this.SatDoseList[i].food_id == items.id && items.unit != 'g' ){
								this.SatDoseList[i].dose_package =  Math.ceil(parseFloat(tempdoes) / parseFloat(items.unit_weight));
								this.SatDoseList[i].food_unit = items.unit
							}
						});
					}
				}
			}

			if ( meal_type == 'jiacan' ){
				for(let i = 0 ; i <this.SatDoseList.length;i++){
					if ( this.SatDoseList[i].meal_type == 'jiacan'){
						let tempdoes = parseInt(this.SatDoseList[i].dose) * parseInt(this.SatNum_jiacan); 
						this.SatDoseList[i].newdose = tempdoes.toFixed(2);
						this.SatDoseList[i].dose_package = 0;
						this.SatDoseList[i].food_unit = 'g';
						this.activateFoodList.forEach((items,index) => {
							if ( this.SatDoseList[i].food_id == items.id && items.unit != 'g' ){
								this.SatDoseList[i].dose_package =  Math.ceil(parseFloat(tempdoes) / parseFloat(items.unit_weight));
								this.SatDoseList[i].food_unit = items.unit
							}
						});
					}
				}
			}

			if ( meal_type == 'wucan' ){
				for(let i = 0 ; i <this.SatDoseList.length;i++){
					if ( this.SatDoseList[i].meal_type == 'wucan'){
						let tempdoes = parseInt(this.SatDoseList[i].dose) * parseInt(this.SatNum_wucan); 
						this.SatDoseList[i].newdose = tempdoes.toFixed(2);
						this.SatDoseList[i].dose_package = 0;
						this.SatDoseList[i].food_unit = 'g';
						this.activateFoodList.forEach((items,index) => {
							if ( this.SatDoseList[i].food_id == items.id && items.unit != 'g' ){
								this.SatDoseList[i].dose_package =  Math.ceil(parseFloat(tempdoes) / parseFloat(items.unit_weight));
								this.SatDoseList[i].food_unit = items.unit
							}
						});
					}
				}
			}

			if ( meal_type == 'wudian' ){
				for(let i = 0 ; i <this.SatDoseList.length;i++){
					if ( this.SatDoseList[i].meal_type == 'wudian'){
						let tempdoes = parseInt(this.SatDoseList[i].dose) * parseInt(this.SatNum_wudian); 
						this.SatDoseList[i].newdose = tempdoes.toFixed(2);
						this.SatDoseList[i].dose_package = 0;
						this.SatDoseList[i].food_unit = 'g';
						this.activateFoodList.forEach((items,index) => {
							if ( this.SatDoseList[i].food_id == items.id && items.unit != 'g' ){
								this.SatDoseList[i].dose_package =  Math.ceil(parseFloat(tempdoes) / parseFloat(items.unit_weight));
								this.SatDoseList[i].food_unit = items.unit
							}
						});
					}
				}
			}

			if ( meal_type == 'wancan' ){
				for(let i = 0 ; i <this.SatDoseList.length;i++){
					if ( this.SatDoseList[i].meal_type == 'wancan'){
						let tempdoes = parseInt(this.SatDoseList[i].dose) * parseInt(this.SatNum_wancan); 
						this.SatDoseList[i].newdose = tempdoes.toFixed(2);
						this.SatDoseList[i].dose_package = 0;
						this.SatDoseList[i].food_unit = 'g';
						this.activateFoodList.forEach((items,index) => {
							if ( this.SatDoseList[i].food_id == items.id && items.unit != 'g' ){
								this.SatDoseList[i].dose_package =  Math.ceil(parseFloat(tempdoes) / parseFloat(items.unit_weight));
								this.SatDoseList[i].food_unit = items.unit
							}
						});
					}
				}
			}
		},
		handleSunNum(meal_type){
			
			if ( meal_type == 'zaocan' ){
				for(let i = 0 ; i <this.SunDoseList.length;i++){
					if ( this.SunDoseList[i].meal_type == 'zaocan'){
						let tempdoes = parseInt(this.SunDoseList[i].dose) * parseInt(this.SunNum_zaocan); 
						
						this.SunDoseList[i].newdose = tempdoes.toFixed(2);
						this.SunDoseList[i].dose_package = 0;
						this.SunDoseList[i].food_unit = 'g';
						this.activateFoodList.forEach((items,index) => {
							if ( this.SunDoseList[i].food_id == items.id && items.unit != 'g' ){
								this.SunDoseList[i].dose_package =  Math.ceil(parseFloat(tempdoes) / parseFloat(items.unit_weight));
								this.SunDoseList[i].food_unit = items.unit
							}
						});
					}
				}
			}

			if ( meal_type == 'jiacan' ){
				for(let i = 0 ; i <this.SunDoseList.length;i++){
					if ( this.SunDoseList[i].meal_type == 'jiacan'){
						let tempdoes = parseInt(this.SunDoseList[i].dose) * parseInt(this.SunNum_jiacan); 
						this.SunDoseList[i].newdose = tempdoes.toFixed(2);
						this.SunDoseList[i].dose_package = 0;
						this.SunDoseList[i].food_unit = 'g';
						this.activateFoodList.forEach((items,index) => {
							if ( this.SunDoseList[i].food_id == items.id && items.unit != 'g' ){
								this.SunDoseList[i].dose_package =  Math.ceil(parseFloat(tempdoes) / parseFloat(items.unit_weight));
								this.SunDoseList[i].food_unit = items.unit
							}
						});
					}
				}
			}

			if ( meal_type == 'wucan' ){
				for(let i = 0 ; i <this.SunDoseList.length;i++){
					if ( this.SunDoseList[i].meal_type == 'wucan'){
						let tempdoes = parseInt(this.SunDoseList[i].dose) * parseInt(this.SunNum_wucan); 
						this.SunDoseList[i].newdose = tempdoes.toFixed(2);
						this.SunDoseList[i].dose_package = 0;
						this.SunDoseList[i].food_unit = 'g';
						this.activateFoodList.forEach((items,index) => {
							if ( this.SunDoseList[i].food_id == items.id && items.unit != 'g' ){
								this.SunDoseList[i].dose_package =  Math.ceil(parseFloat(tempdoes) / parseFloat(items.unit_weight));
								this.SunDoseList[i].food_unit = items.unit
							}
						});
					}
				}
			}

			if ( meal_type == 'wudian' ){
				for(let i = 0 ; i <this.SunDoseList.length;i++){
					if ( this.SunDoseList[i].meal_type == 'wudian'){
						let tempdoes = parseInt(this.SunDoseList[i].dose) * parseInt(this.SunNum_wudian); 
						this.SunDoseList[i].newdose = tempdoes.toFixed(2);
						this.SunDoseList[i].dose_package = 0;
						this.SunDoseList[i].food_unit = 'g';
						this.activateFoodList.forEach((items,index) => {
							if ( this.SunDoseList[i].food_id == items.id && items.unit != 'g' ){
								this.SunDoseList[i].dose_package =  Math.ceil(parseFloat(tempdoes) / parseFloat(items.unit_weight));
								this.SunDoseList[i].food_unit = items.unit
							}
						});
					}
				}
			}

			if ( meal_type == 'wancan' ){
				for(let i = 0 ; i <this.SunDoseList.length;i++){
					if ( this.SunDoseList[i].meal_type == 'wancan'){
						let tempdoes = parseInt(this.SunDoseList[i].dose) * parseInt(this.SunNum_wancan); 
						this.SunDoseList[i].newdose = tempdoes.toFixed(2);
						this.SunDoseList[i].dose_package = 0;
						this.SunDoseList[i].food_unit = 'g';
						this.activateFoodList.forEach((items,index) => {
							if ( this.SunDoseList[i].food_id == items.id && items.unit != 'g' ){
								this.SunDoseList[i].dose_package =  Math.ceil(parseFloat(tempdoes) / parseFloat(items.unit_weight));
								this.SunDoseList[i].food_unit = items.unit
							}
						});
					}
				}
			}
		},
		handleTabClick(){
			if ( this.activeName == 'menu' ){
				this.initData();
			}
			if ( this.activeName == 'dose' ){
				this.initDoseData();
			}
			if ( this.activeName == 'message' ){
				this.initMessageData();
			}
		},
		getCharts(){
			let chartBox = this.$echarts.init(document.getElementById("maychar"));
			let option = {
				xAxis: {
				data: ["星期一", "星期二", "星期三", "星期四", "星期五", "星期六", "星期日"],
				},
				yAxis: {},
				series: [
				{
					type: "bar",
					data: [23, 24, 18, 25, 27, 10, 25],
				},
				],
			};
			chartBox.setOption(option);
			// 根据页面大小自动响应图表大小
			window.addEventListener("resize", function () {
				chartBox.resize();
			});
		},
		initData(){
			let me = this;
			this.loading = false
			let post={
				menu_uuid:this.detailUUID
			}
			MN.getMenuDetail(post).then(res => {
				console.log( JSON.parse( res.menu_zaocan ));
				me.is_order = res.is_order,
				me.date_start = res.start_date;
				me.date_end = res.end_date;
				me.company_name = res.company_name;
				me.create_date = res.create_date;
				me.create_user = res.create_user;
				me.kindergarten_uuid = res.company_uuid;
				me.menu_zaocan = JSON.parse( res.menu_zaocan );
				me.menu_jiacan = JSON.parse( res.menu_jiacan );
				me.menu_wucan = JSON.parse( res.menu_wucan );
				me.menu_wudian = JSON.parse( res.menu_wudian );
				me.menu_wancan = JSON.parse( res.menu_wancan );
			})
		},
		initMessageData(){
			let me = this;
			this.loading = false
			let post={
				receipt_type:1,
				uuid:this.detailUUID
			}
			
			MN.questionDataList(post).then(res => {
				console.log(res);
				if( res.length == 0 ){
					me.QuestionListEmpty = true
				}else{
					me.QuestionListEmpty = false
					me.questionDataList = res
				}
			})
		},
		initDoseData(){
			let me = this;
			this.loading = false
			let post={
				menu_uuid:this.detailUUID
			}

			MN.supplierDataList().then(res => {
				me.supplier_options = res
			})

			MN.getMenuDoseTemplate(post).then(res => {
				
		

				me.MonDoseList = JSON.parse( res.MonDose );
				if( me.MonDoseList.length == 0 ){
					me.MonNumStatus_zaocan=true;
					me.MonNumStatus_jiacan=true;
					me.MonNumStatus_wucan=true;
					me.MonNumStatus_wudian=true;
					me.MonNumStatus_wancan=true;
				}
				me.MonDoseList.forEach(item => {
					item['newdose'] = 0;
					item['dose_package'] = 0;
				});
				me.TueDoseList = JSON.parse( res.TueDose );
				if( me.TueDoseList.length == 0 ){
					me.TueNumStatus_zaocan=true;
					me.TueNumStatus_jiacan=true;
					me.TueNumStatus_wucan=true;
					me.TueNumStatus_wudian=true;
					me.TueNumStatus_wancan=true;
				}
				me.TueDoseList.forEach(item => {
					item['newdose'] = 0;
					item['dose_package'] = 0;
				});
				me.WedDoseList = JSON.parse( res.WedDose );
				if( me.WedDoseList.length == 0 ){
					me.WedNumStatus_zaocan=true;
					me.WedNumStatus_jiacan=true;
					me.WedNumStatus_wucan=true;
					me.WedNumStatus_wudian=true;
					me.WedNumStatus_wancan=true;
				}
				me.WedDoseList.forEach(item => {
					item['newdose'] = 0;
					item['dose_package'] = 0;
				});
				me.ThuDoseList = JSON.parse( res.ThuDose );
				if( me.ThuDoseList.length == 0 ){
					me.ThuNumStatus_zaocan=true;
					me.ThuNumStatus_jiacan=true;
					me.ThuNumStatus_wucan=true;
					me.ThuNumStatus_wudian=true;
					me.ThuNumStatus_wancan=true;
				}
				me.ThuDoseList.forEach(item => {
					item['newdose'] = 0;
					item['dose_package'] = 0;
				});
				me.FriDoseList = JSON.parse( res.FriDose );
				if( me.FriDoseList.length == 0 ){
					me.FriNumStatus_zaocan=true;
					me.FriNumStatus_jiacan=true;
					me.FriNumStatus_wucan=true;
					me.FriNumStatus_wudian=true;
					me.FriNumStatus_wancan=true;
				}
				me.FriDoseList.forEach(item => {
					item['newdose'] = 0;
					item['dose_package'] = 0;
				});
				me.SatDoseList = JSON.parse( res.SatDose );
				if( me.SatDoseList.length == 0 ){
					me.SatNumStatus_zaocan=true;
					me.SatNumStatus_jiacan=true;
					me.SatNumStatus_wucan=true;
					me.SatNumStatus_wudian=true;
					me.SatNumStatus_wancan=true;
				}
				me.SatDoseList.forEach(item => {
					item['newdose'] = 0;
					item['dose_package'] = 0;
				});
				me.SunDoseList = JSON.parse( res.SunDose );
				if( me.SunDoseList.length == 0 ){
					me.SunNumStatus_zaocan=true;
					me.SunNumStatus_jiacan=true;
					me.SunNumStatus_wucan=true;
					me.SunNumStatus_wudian=true;
					me.SunNumStatus_wancan=true;
				}
				me.SunDoseList.forEach(item => {
					item['newdose'] = 0;
					item['dose_package'] = 0;
				});
			})
		},
		handleGoBack(){
			this.$router.back(-1)
		}
	}
}
</script>

<style scoped>

.diner_line{ color: #1d864b; background-color: #e0ffe7; font-size: 12px; margin:10px 0 2px 0; border-radius: 4px; text-align: center; padding: 4px 0 4px 0; }

.more_weight{ text-align: right; color: #2aa51a; padding-top: 4px; }
.sdxoa_rpw{ margin: 7px  0 0 2%; padding:7px; border-radius: 5px; background-color: #f5fff3; border: 1px solid #ededed; width: 97%; }
.sixx_oprow{ margin: 7px 2% 0 0; padding:7px; border-radius: 5px; width: 97%; background-color: #f7feff; border: 1px solid #ededed;  }

.fss_info{ border-top: 1px dashed #cccccc; padding-top: 10px; font-size: 12px; margin-top: 8px; } 
 
.label_message{position: relative;}

.dot_item{ position: absolute; top: 0; right: -7px; width: 6px; height: 6px; background-color: red; border-radius: 50%; }

#maychar{ width:800px; height:600px;  }

.sixk_row{
	width: 14.22%;
}

.sxik_input{
	width: 100%;
}

.box-card{
	margin-top: 14px;
}

.barsxk_op{
	margin:10px 0 10px 0;
	padding: 7px;
	font-size: 13px;
	background-color: #f7f7f7;
	border-radius: 6px;
}


.barsxk{
	margin:10px 0 10px 0;
	padding: 7px;
	font-size: 13px;
	background-color: #F4F8EE;
	border-radius: 6px;
}

.right_nav_bar{
	font-size: 13px;
	background-color: #eaeaea;
	padding: 4px;
	border-radius: 5px;
}
.panel_food_row{
	border-bottom: 1px dashed #cccccc;
	font-size: 13px;
	padding: 5px 0 5px 0;
	position: relative;
}
.panel_food_wig{
	position: absolute;
	right: 0;
	top: 8px;
	color: #2aa51a;
}
.panel_people_num{
	background-color: #f1f1f1;
	padding: 3% 3% 3% 30%;
	border-radius: 6px;
	margin-bottom: 5px;
	position: relative;
}
.panel_people_num span{
	position: absolute;
	left: 5px;
	top: 9px;
	font-size: 13px;
	padding: 0 0 4px 0;
}
.card_sub_title{
	font-size: 14px;
	font-weight: bolder;
}
.buttonexcel{
	background: none;
	background-color: #409eff;
	width: 90px;
	color: #fff;
	border: none;
	border-radius: 4px;
	padding: 5px;
	cursor: pointer;
}
.page_bar_title{
	color: #575757;
}
.mt20{
	margin-top: 20px;
}


.clear{ clear: both; }
.day_block_empty{
	position: absolute;
	left: 0;
	top: 30%;
	text-align: center;
	width: 100%;
	color: #b3b3b3;
	font-size: 13px;
}
.button_edit_dish{
	width: 18px;
	height: 18px;
	background:url('@/assets/icon_green_edit.png') no-repeat;
	background-size: 100%;
	position: absolute;
	right:30px;
	top: 4px;
	cursor: pointer;
}
.button_del_dish{
	background:url('@/assets/icon_green_del.png') no-repeat;
	background-size: 100%;
	width: 18px;
	height: 18px;
	position: absolute;
	right: 5px;
	top: 4px;
	cursor: pointer;
}
.dis_dish_row{ 
	position: relative;
	border-bottom: 1px dashed #cccccc;
	padding: 4px;
	text-align: center;
	font-size: 13px;
	margin-bottom: 5px;
	border-radius: 4px;
}
.button_add_dish{
	position: absolute;
	left: 0;
	bottom: -33px;
	width: 99%;
	padding:6px 0 6px 0;
	font-size: 13px;
	background:url('@/assets/icon_green_add.png') no-repeat #F4F8EE;
	background-position:30% 50%;
	background-size: 16px;
	border: 1px solid #54C651;
	color: #2C9E46;
	font-weight: bolder;
	border-radius: 4px;
	cursor: pointer;
}
.panel_menu_rowx{
	position: relative;
	overflow-x: auto;
	width: 97%;
	margin: 0 0 0 2%;
}
.panel_menu_rowbig{
	width: 1250px;
	padding-bottom:16px;
}
.week_txo{
	position: absolute;
	left: 5px;
	top: -18px;
	font-size: 12px;
	color: #999999;
}
.panel_day_block{
	position: relative;
	background-color: #ffffff;
	box-shadow: 1px 1px 3px #b9b9b9;
	border-radius: 8px;
	min-height: 60px;
	width: 145px;
	padding: 5px;
	float: left;
	margin: 20px 0 6px 14px;
}
.panel_dixz_label{
	width: 15px;
	float: left;
	text-align:center;
	font-size: 12px;
	color: #0C922E;
	font-weight: bolder;
}
.panel_dixz{
	border: 1px solid #cbcbcb;
	background:url('@/assets/icon_green_bg.png') repeat-y #EDECE6;
	background-size: 27px;
	text-align: center;
	padding: 6px;
	border-radius: 8px;
	margin-bottom: 8px;
	position: relative;
}
.panel_exo{
	border-radius: 10px;
	padding: 20px;
	background-color: #f9f9f9;
}
.buttonexcel{
	background: none;
	background-color: #409eff;
	width: 90px;
	color: #fff;
	border: none;
	border-radius: 4px;
	padding: 5px;
	cursor: pointer;
}
.page_bar_title{
	color: #575757;
}

.detail_row{
	background-color: #f8f8f8;
	border-radius: 10px;
	padding: 8px;
	box-shadow: 0 2px 5px #eeeeee;
	text-align: left;
}

::v-deep .el-tabs__active-bar {
	cursor: pointer;
	background-color: #03BC5B;
}
::v-deep .el-tabs__item.is-active {
	color: #03BC5B;
	font-weight: bolder;
	cursor: pointer;
}
::v-deep .el-tabs__item:hover {
	color: #03BC5B;
	cursor: pointer;
}
</style>
