<template>
	<div>

	<el-container class="data_skc">

		<el-aside class="aside-style" width="200px">
			<common-nav selec="6"></common-nav>
		</el-aside>
		
		<el-container>

			<el-header class="common_header">
				<common-header></common-header>
			</el-header>
			
			<el-main>

				<el-row :gutter="24" class="page_bar">
					<el-col :span="20" class="page_bar_title">消息中心</el-col>
					<el-col :span="4">
						<el-input placeholder="输入标题查找" v-model="searchValue" class="input-with-select" size="small" >
							<el-button slot="append" icon="el-icon-search"></el-button>
						</el-input>
					</el-col>
				</el-row>

				<el-table v-loading="loading" :data="tableMessageData" size="medium" stripe highlight-current-row>
					
					<el-table-column prop="chat_uuid" label="会话ID" width="110" ></el-table-column>
					<el-table-column prop="stype" label="消息内容" >
						<template slot-scope="scope">

							<block  v-for="logrow in scope.row.logList" :key="logrow.id"  >
								<div class="sixx_oprow" v-if="logrow.chat_type=='1'" >
									{{logrow.message_body}}
									<el-row class="fss_info">
										<!-- <el-col :span="10" >相关菜谱编号：{{item.receipt_id}}</el-col> -->
										<el-col :span="5" >提问人：{{logrow.create_user}}</el-col><el-col :span="5" >日期：{{logrow.create_date}}</el-col>
									</el-row>
								</div>
								<div class="sdxoa_rpw"  v-if="logrow.chat_type=='2'">
									回复：{{logrow.message_body}}
									<el-row class="fss_info">
										<el-col :span="8" >{{logrow.create_date}}</el-col>
									</el-row>
								</div>
							</block>

							
						</template>
					</el-table-column>
					<!-- <el-table-column prop="create_user" label="提交人"  width="120"></el-table-column>
					<el-table-column prop="create_date" label="日期" width="160" ></el-table-column> -->
					<el-table-column label="操作" width="100">
						<template slot-scope="scope">
							<!-- <el-button type="primary" size="mini" plain @click="handleShowMessageDetail(scope.row.id)">详情</el-button> -->
							<el-button type="success" size="mini" plain @click="handleShowMessageRe(scope.row.id)">回复</el-button>
						</template>
					</el-table-column>
				</el-table>

				<el-pagination
					background
					:page-size="15" 
					:current-page="pagenow"
					layout="prev, pager, next"
					:total="totalnum" @current-change="handleCurrentChange" class="page_pagination">
				</el-pagination>
			
			</el-main>

			<el-footer> copyright &copy; yyvvi.com </el-footer>
		</el-container>
	</el-container>


	<el-dialog
		title="管理员回复"
		:visible.sync="dialogCreateSubQuestion"
		width="30%"  :close-on-click-modal="false">
		<el-row>
			<el-col :span="24">
				<el-input
				type="textarea"
				:rows="3"
				placeholder="请输入内容"  maxlength="200"  show-word-limit 
				v-model="subask_question">
				</el-input>
			</el-col> 
		</el-row>
		<span slot="footer" class="dialog-footer">
			<el-button @click="dialogCreateSubQuestion = false">取 消</el-button>
			<el-button type="primary" @click="hadleSaveNewSubQuestion">保存</el-button>
		</span>
	</el-dialog>


	</div>
</template>

<script>

import CommonNav from "@/components/Nav.vue";
import CommonHeader from "@/components/Header.vue";

import * as MN from '@/api/common'

export default {
    name: 'crm', 
	props:['selec'],
	data() {
		return {
			subask_question:'',
			main_question_id:'',
			dialogCreateSubQuestion:false,
			new_ask_question:'',
			dialogCreateQuestion:false,
			tableMessageData:[],
			pagenow:1,
			totalnum:0,
			searchValue:'',
			loading:true,
			boxshow: false, 
			curTipsID: '', 
			tableData: []
		}
	},
	components: {
		CommonNav,
		CommonHeader
	},

	created() {
		this.initMessageData()
	},

	mounted() {

	},

	methods: {
		initMessageData(){
			let me = this;
			this.loading = true
			MN.getMessageList().then(res => {
				this.tableMessageData=res.data;
				this.loading=false;
			})
		},
		hadleSaveNewSubQuestion(){
			let me = this;
			if( !this.subask_question ){
				this.$message.warning('请输入问题');
				return
			}
			let post={
				chat_type:'2',
				question_id:me.main_question_id,
				sub_question:me.subask_question
			}
			MN.setSaveNewSubQuestion(post).then(res => {
				if( res == 'add_ok' ){
					me.dialogCreateSubQuestion = false;
					me.$message.success('已保存');
					me.initMessageData();
				}
			})
		},
		handleShowMessageRe(qid){
			console.log(qid)
			this.main_question_id = qid;
			this.subask_question = '';
			this.dialogCreateSubQuestion = true
		}
	}
}
</script>

<style scoped>
.sdxoa_rpw{ margin: 7px  0 0 2%; padding:7px; border-radius: 5px; background-color: #f5fff3; border: 1px solid #ededed; width: 97%; }
.sixx_oprow{ margin: 7px 2% 0 0; padding:7px; border-radius: 5px; width: 97%; background-color: #f7feff; border: 1px solid #ededed;  }

.fss_info{ border-top: 1px dashed #cccccc; padding-top: 10px; font-size: 12px; margin-top: 8px; } 
 
.label_message{position: relative;}

.message_info{ border-top: 1px dashed #cccccc; margin-top: 6px; padding: 4px; color: #c2c2c2; }
.resp_data{ border: 1px solid #c2c2c2; margin: 10px 0 10px 50px; border-radius: 5px; padding: 6px; background-color: #f5fff3; }
.tixo_data{ border: 1px solid #c2c2c2; margin: 10px 50px 10px 0; border-radius: 5px; padding: 6px; background-color: #f7feff; }
</style>
