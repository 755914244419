<template>
	<div>

	<el-container class="data_skc">

		<el-aside class="aside-style" width="200px">
			<common-nav selec="4"></common-nav>
		</el-aside>
		
		<el-container>

			<el-header class="common_header">
				<common-header></common-header>
			</el-header>
			
			<el-main>

				<el-row :gutter="24" class="page_bar">
					<el-col :span="5" class="page_bar_title">食材列表</el-col>
					<el-col :span="5" :offset="10">
						<el-button size="small" type="success"  icon="el-icon-plus" @click="handleAddFood" >新建食材</el-button>
						<el-button size="small" type="success"  icon="el-icon-document-copy" @click="handleBatchFood" >批量新建</el-button>
					</el-col>
					<el-col :span="4">
						<el-input placeholder="输入标题查找" v-model="searchFoodValue" class="input-with-select" size="small" >
							<el-button slot="append" @click="handleSearchFood" icon="el-icon-search"></el-button>
						</el-input>
					</el-col>
				</el-row>

				<el-table v-loading="loading" :data="tableFoodData" size="medium" stripe highlight-current-row>
						<el-table-column prop="pic_url" label="图片" width="80" >
							<template slot-scope="scope">
								<el-image :src="scope.row.pic_url" class="cover_img">
									<div slot="error" class="image-slot">
										<i class="el-icon-picture-outline"></i>
									</div>
								</el-image>
							</template>
						</el-table-column>
						<el-table-column prop="status" label="状态" width="80" >
							<template slot-scope="scope">
								<label v-if="scope.row.status == 1" style="color:green">正常</label>
								<label v-if="scope.row.status == 0" style="color:red">停用</label>
							</template>
						</el-table-column>
						<el-table-column prop="stype" label="分类" width="120" ></el-table-column>
						<el-table-column prop="name" label="名称"></el-table-column>
						<el-table-column prop="unit" width="190" label="单位" >
							<template slot-scope="scope">
								<div v-if="scope.row.unit == 'g'">{{scope.row.unit}}</div>
								<div v-if="scope.row.unit != 'g'">{{scope.row.unit}}（每{{scope.row.unit}}{{scope.row.unit_weight}}g）</div>
							</template>
						</el-table-column>
						<el-table-column prop="buy_price" width="110" label="每500g采购价" ></el-table-column>
						<el-table-column label="操作" width="170">
							<template slot-scope="scope">
								<el-button type="danger" v-if="scope.row.status==1" size="mini" plain @click="handleChangeFoodStatus('0',scope.row.id)">停用</el-button>
								<el-button type="success" v-if="scope.row.status==0" size="mini" plain @click="handleChangeFoodStatus('1',scope.row.id)">启用</el-button>
								<el-button type="primary" size="mini" plain @click="handleEditFood(scope.row)">修改</el-button>
							</template>
						</el-table-column>
					</el-table>

					<el-pagination
						background
						:page-size="15" 
						:current-page="pagenow"
						layout="prev, pager, next"
						:total="totalnum" @current-change="handleCurrentChange" class="page_pagination">
					</el-pagination>
	</el-main>

			<el-footer> copyright &copy; yyvvi.com </el-footer>
		</el-container>
	</el-container>

	<el-dialog
	title="食材"
	:visible.sync="addFoodDialogVisible"
	width="40%"  :close-on-click-modal="false">
	<el-row>
		<el-col :span="5">
			<el-upload
			class="avatar-uploader"
			action="https://www.yyvvi.com/kindergarten/index/postsImages"
			:show-file-list="false"
			:on-success="handleAvatarSuccess"
			:before-upload="beforeAvatarUpload">
			<img v-if="show_newadd_food_pic" :src="show_newadd_food_pic" class="avatar">
			<i v-else class="el-icon-plus avatar-uploader-icon"></i>
			</el-upload>
		</el-col>
		<el-col :span="6">
			食材分类:
			<el-select v-model="select_foodtype" placeholder="显示全部" size="small">
				<el-option
				v-for="item in foodTypeTableData"
				:key="item.name"
				:label="item.name"
				:value="item.name"
				:disabled="item.disabled">
				</el-option>
			</el-select>	
		</el-col> 
		<el-col :span="11" :offset="1">
			食材名称:
			<el-input v-model="newfood_name"  size="small" placeholder="请输入内容"></el-input>
		</el-col>
	</el-row>
	<el-row class="mt20">
		<el-col :span="6" :offset="5">
			每500g采购价:
			<el-input-number  style="width:100%" v-model="buy_price"  min="0" :precision="2"  size="small" placeholder="请输入内容"></el-input-number>
		</el-col>

		<el-col :span="4" :offset="1">
			食材单位：
			<el-select v-model="select_foodunit" @change="handleShowUnitWet" placeholder="显示全部" size="small">
				<el-option
				v-for="item in foodWeightTableData"
				:key="item.name"
				:label="item.name"
				:value="item.name"
				:disabled="item.disabled">
				</el-option>
			</el-select>
		</el-col>
		<el-col :span="6" :offset="1" v-if="!!ShowUnitWet">
			每<strong style="color:#397cba">{{ShowUnitWet}}</strong>重量（g）:
			<el-input-number  style="width:100%" v-model="unit_weight" min="0" max="999999999" :precision="0"  size="small" placeholder="请输入内容"></el-input-number>
		</el-col>
	</el-row>
	<span slot="footer" class="dialog-footer">
		<el-button @click="addFoodDialogVisible = false">取 消</el-button>
		
		<el-button v-if="!edit_food_id" type="primary" @click="hadleSaveNewFood">新增食材</el-button>
		<el-button v-if="!!edit_food_id" type="primary" @click="handleSaveEditFood">保存修改</el-button>
	</span>
	</el-dialog>

	</div>
</template>

<script>

import CommonNav from "@/components/Nav.vue";
import CommonHeader from "@/components/Header.vue";

import * as MN from '@/api/common'

export default {
    name: 'crm', 
	props:['selec'],
	data() {
		return {
			batchFoodExcelDialog:false,
			foodTypeTableData:[],
			foodWeightTableData:[],
			ShowUnitWet:'',
			pagenow:1,
			totalnum:0,
			newadd_food_pic:'',
			show_newadd_food_pic:'',
			disabled_tab_menu:false,
			disabled_tab_template:false,
			disabled_tab_dish:false,
			disabled_tab_food:false,
			login_user_type:'',
			login_supplier_food:'',
			login_supplier_dish:'',
			new_totemplate_menu:'',
			new_template_name:'',
			dialogSaveMenuTemplate:false,
			dialogShowDetailTemplate:false,
			tableTemplateData:[],
			select_kindergarten_options: [],
			new_menu_kindergarten_uuid: '',
			new_menu_template:'',
			new_menuWeek:'',
			templateTableData:[],
			dialogConfirmUseTemplate:false,
			dialogMenuTemplate:false,
			dish_sell_price:'',
			dishAddfoodValue:'',
			dishAddfoodDose:'',
			foodcheckedListData:[],
			addfood_value:'',
			newdish_name:'',
			select_dishtype:'',
			foodListData:[],
			addDishDialogVisible:false,
			searchDishValue:'',
			searchFoodValue:'',
			edit_food_id:'',
			newfood_name:'',
			select_foodtype:'',
			buy_price:'',
			unit_weight:'',
			select_foodunit:'g',
			foodunit_options:[
				{value: 'g',label: 'g'},
				{value: '盒',label: '盒'},
				{value: '袋',label: '袋'},
				{value: '瓶',label: '瓶'},
				{value: '支',label: '支'},
				{value: '件',label: '件'}
			],
			dishtype_options:[
				{value:'1',label:'主食/干食'},
				{value:'2',label:'蔬菜类'},
				{value:'3',label:'蛋类'},
				{value:'4',label:'肉菜'},
				{value:'5',label:'荤素搭配菜'},
				{value:'6',label:'素菜'},
				{value:'7',label:'汤类'},
			],
			foodtype_options:[
				{value: '1',label: '蔬菜'},
				{value: '2',label: '水果'},
				{value: '3',label: '蛋类'},
				{value: '4',label: '肉类'},
				{value: '5',label: '谷物'}
			],
			addFoodDialogVisible:false,
			activeTab:'menu',
			searchValue:'',
			loading:true,
			boxshow: false, 
			curTipsID: '', 
			tableMenuData: [],
			tableDishData:[],
			tableFoodData:[],
			select_options: [{
			value: 't',
			label: '显示全部'
			},{
			value: '1',
			label: '西安市AAAAAA幼儿园'
			}, {
			value: '2',
			label: '西安市BBBBBB幼儿园'
			}, {
			value: '3',
			label: '西安市CCCCCC幼儿园'
			}, {
			value: '4',
			label: '西安市DDDDDD幼儿园'
			}, {
			value: '5',
			label: '西安市EEEEEE幼儿园'
			}],
			select_value: '',
			menu_template_name:'',
			template_detail_menu_zaocan:[],
			template_detail_menu_jiacan:[],
			template_detail_menu_wucan:[],
			template_detail_menu_wudian:[],
			template_detail_menu_wancan:[],
			menu_zaocan:[
				{id:1,label:'周一 Mon',value:[]},
				{id:2,label:'周二 Tue',value:[]},
				{id:3,label:'周三 Wed',value:[]},
				{id:4,label:'周四 Thu',value:[]},
				{id:5,label:'周五 Fri',value:[]},
				{id:6,label:'周六 Sat',value:[]},
				{id:7,label:'周日 Sun',value:[]}
			],
			menu_jiacan:[
				{id:1,label:'周一 Mon',value:[]},
				{id:2,label:'周二 Tue',value:[]},
				{id:3,label:'周三 Wed',value:[]},
				{id:4,label:'周四 Thu',value:[]},
				{id:5,label:'周五 Fri',value:[]},
				{id:6,label:'周六 Sat',value:[]},
				{id:7,label:'周日 Sun',value:[]}
			],
			menu_wucan:[
				{id:1,label:'周一 Mon',value:[]},
				{id:2,label:'周二 Tue',value:[]},
				{id:3,label:'周三 Wed',value:[]},
				{id:4,label:'周四 Thu',value:[]},
				{id:5,label:'周五 Fri',value:[]},
				{id:6,label:'周六 Sat',value:[]},
				{id:7,label:'周日 Sun',value:[]}
			],
			menu_wudian:[
				{id:1,label:'周一 Mon',value:[]},
				{id:2,label:'周二 Tue',value:[]},
				{id:3,label:'周三 Wed',value:[]},
				{id:4,label:'周四 Thu',value:[]},
				{id:5,label:'周五 Fri',value:[]},
				{id:6,label:'周六 Sat',value:[]},
				{id:7,label:'周日 Sun',value:[]}
			],
			menu_wancan:[
				{id:1,label:'周一 Mon',value:[]},
				{id:2,label:'周二 Tue',value:[]},
				{id:3,label:'周三 Wed',value:[]},
				{id:4,label:'周四 Thu',value:[]},
				{id:5,label:'周五 Fri',value:[]},
				{id:6,label:'周六 Sat',value:[]},
				{id:7,label:'周日 Sun',value:[]}
			],
		}
	},
	components: {
		CommonNav,
		CommonHeader
	},

	created() {
		this.initData(1);
		this.handleLoginUser();
	},

	mounted() {

	},

	methods: {
		handleBatchFoodExcel(){
			this.batchFoodExcelDialog = true;
		},
		handleShowUnitWet(){
			if ( this.select_foodunit == 'g'){
				this.ShowUnitWet = '';
			}else{
				this.ShowUnitWet = this.select_foodunit;
			}
		},
		handleCurrentChange(new_page) {
			this.initData(new_page);
      },
	  handleBatchFoodExcelSuccess(res,file){
		console.log("res",res)
	  },
		handleAvatarSuccess(res, file) {
			this.newadd_food_pic = res.image_url
			this.show_newadd_food_pic = "https://www.yyvvi.com/" + "uploads/" + res.image_url
		},
      beforeAvatarUpload(file) {
        const isPNG = file.type === 'image/png';
		const isGIF = file.type === 'image/gif';
		const isJPG = file.type === 'image/jpeg';
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isJPG && !isGIF && !isPNG) {
          this.$message.error('只能是图片格式（JPG/GIF/PNG）');
		   return false
        }
        if (!isLt2M) {
          this.$message.error('图片大小不能超过2MB');
		  return false
        }
        
      },
		handleChangeFoodStatus(new_status,id){
			let show_label = '';
			let me = this;
			if ( new_status == '1' ){
				show_label = '确认启用这个食材吗？';
			}else{
				show_label = '确认停用这个食材吗？';
			}
			this.$confirm(show_label, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {

				let post={
					u_id:id,
					u_status:new_status
				}
				MN.setChangeFoodStatus(post).then(res => {
					me.$message({
						type: 'success',
						message: '操作成功!'
					});
					me.initData(1);
				})
				
			}).catch(() => {
				me.$message({
					type: 'info',
					message: '已取消'
				});          
			});

		},
		handleLoginUser(){
			// let checkSession = sessionStorage.getItem('kindergarten_meals_session');
			// if ( !checkSession ){
			//   this.$router.push('/');
			// }
			let me = this;
			// let post={
			//   meals_session:sessionStorage.getItem('kindergarten_meals_session'),
					// 	meals_usertype:sessionStorage.getItem('kindergarten_meals_usertype')
					// }
			MN.kindergartenLoginUser().then(res => {
				//me.login_user_uuid = res.user_uuid;
				me.login_user_type = res.login_type;
				me.login_supplier_food = res.supplier_manage_food;
				me.login_supplier_dish = res.supplier_manage_dish;
				//me.login_user_name = res.user_name;
				// me.login_user_company_name = res.company_name;
				// me.login_user_kindergarten_name = res.kindergarten_name;

				if(  res.login_type == 'admin_user' ){
					me.disabled_tab_menu = false
					me.disabled_tab_template = false
					me.disabled_tab_dish = false
					me.disabled_tab_food = false
				}

				if(  res.login_type == 'staff_user' ){
					me.disabled_tab_menu = false
					me.disabled_tab_template = true
					me.disabled_tab_dish = true
					me.disabled_tab_food = true
				}

				if(  res.login_type == 'supplier_user' ){
					//me.disabled_tab_menu = true
					me.disabled_tab_template = true
					if ( res.supplier_manage_food == '0' ){
						me.disabled_tab_dish = false
					}else{
						me.disabled_tab_dish = true
					}

					if ( res.supplier_manage_dish == '0' ){
						me.disabled_tab_food = false
					}else{
						me.disabled_tab_food = true
					}
					
				}


				console.log(res)
				return
			})
		},
		handleSaveMenuToTemplate(){
			let me = this;
			if ( !this.new_template_name ){
				this.$message.error('请输入模板名称');
				return
			}
			let post={
				menu_uuid:me.new_totemplate_menu,
				template_name:me.new_template_name,
			}
			MN.saveMenuToTemplate(post).then(res => {
				if( res == 'new_template_ok' ){
					me.$message.success('成功保存为模板，可到模板库中查看');
					me.dialogSaveMenuTemplate = false
				}
			})
		},
		handleMenuToTemplate(menu_uuid){
			this.new_totemplate_menu = menu_uuid;
			this.new_template_name = '';
			this.dialogSaveMenuTemplate = true;
		},
		handleDetailTemplate(rowdata){
			
			this.template_detail_menu_zaocan=JSON.parse( rowdata.menu_zaocan );
			this.template_detail_menu_jiacan=JSON.parse( rowdata.menu_jiacan );
			this.template_detail_menu_wucan=JSON.parse( rowdata.menu_wucan );
			this.template_detail_menu_wudian=JSON.parse( rowdata.menu_wudian );
			this.template_detail_menu_wancan=JSON.parse( rowdata.menu_wancan );
			this.dialogShowDetailTemplate = true;
			console.log(rowdata);
		},
		handleDeleteTemplate(template_uuid){
			console.log(template_uuid);
			let me = this;
			
			this.$confirm('此操作将永久删除此模板, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
					let post={
						template_uuid:template_uuid
					}
					MN.handleDeleteTemplate(post).then(res => {
						me.initData(1);
					})
					
					this.$message({
						type: 'success',
						message: '删除成功!'
					});
			}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});          
			});
		},
		handleAddMenuByTemplate(){
			let me = this;
			if ( !this.new_menuWeek ){
				this.$message.error('请选择菜谱日期');
				return
			}
			if ( !this.new_menu_kindergarten_uuid ){
				this.$message.error('请选择幼儿园');
				return
			}
			let post={
				menuWeek:this.new_menuWeek,
				menu_template:this.new_menu_template,
				kindergarten_uuid:this.new_menu_kindergarten_uuid
			}
			MN.setAddMenuByTemplate(post).then(res => {
				if( res == 'add_ok' ){
					me.initData(1);
					me.dialogMenuTemplate = false;
					me.dialogConfirmUseTemplate = false;
				}
			})
		},
		handleDeleteDishAddFood(foodid){
			console.log(foodid);
			for ( let i = 0; i < this.foodListData.length;i++ ){
				if ( this.foodcheckedListData[i].foodid == foodid ){
					this.foodcheckedListData.splice([i],1)
				}
			}
		},
		hadleNewDishAddFood(){
			if ( !this.dishAddfoodValue || !this.dishAddfoodDose ){
				this.$message.error('请选择要添加的食材、输入用量');
				return
			}
			let getfoodname = '';
			for ( let i = 0; i < this.foodListData.length;i++ ){
				if ( this.foodListData[i].id == this.dishAddfoodValue ){
					getfoodname = this.foodListData[i].name;
				}
			}
			
			this.foodcheckedListData.push({
				foodid:this.dishAddfoodValue,
				foodname:getfoodname,
				fooddose:this.dishAddfoodDose
			})

			this.dishAddfoodValue = '';
			this.dishAddfoodDose = '';
		},
		handleAddDish(){
			let me = this;
			MN.getFoodListData().then(res => {
				me.select_dishtype = '';
				me.newdish_name = '';
				me.dish_sell_price = '';
				me.dishAddfoodValue = '';
				me.dishAddfoodDose = '';
				me.foodcheckedListData = [];

				me.foodListData=res;
				me.addDishDialogVisible=true;
			})
		},
		handleSearchDish(){
			this.loading=true;
			let me = this;
			let post={
				searchdish:this.searchDishValue,
			}
			MN.searchDishData(post).then(res => {
				me.tableDishData=res;
				me.loading=false;
			})
		},
		handleSearchFood(){

			this.loading=true;
			let me = this;
			let post={
				searchfood:this.searchFoodValue,
			}
			MN.searchFoodData(post).then(res => {
				me.tableFoodData=res;
				me.loading=false;
			})
			//this.initData('food');
		},
		handleSaveEditFood(){
			if( !this.newfood_name || !this.select_foodtype || !this.buy_price ){
				this.$message.error('请选择类别、输入食物名称、采购价、图片');
				return
			}

			if( this.select_foodunit != 'g' && this.unit_weight == '0' ){
				this.$message.error('选择其他食材单位后，请填写重量');
				return
			}

			let me = this;
			let post={
				edit_food_id:this.edit_food_id,
				pic_url:this.newadd_food_pic,
				food_name:this.newfood_name,
				food_type:this.select_foodtype,
				buy_price:this.buy_price,
				food_unit:this.select_foodunit,
				unit_weight:this.unit_weight
			}
			MN.setSaveEditFood(post).then(res => {
				console.log(res)
				if( res == 'error_input' ){
					this.$message.error('请选择类别、输入食物名称、采购价、图片');
				}
				if( res == 'edit_ok' ){
					me.edit_food_id = ''
					me.initData(1);
					me.addFoodDialogVisible = false
				}
			})
		},
		handleEditFood(rowdata){
			console.log("rowdata",rowdata);
			let split_url = rowdata.pic_url;
			split_url = split_url.split("/uploads/");
			if ( rowdata.unit != 'g' ){
				this.select_foodunit = rowdata.unit;
				this.ShowUnitWet = rowdata.unit;
				this.unit_weight = rowdata.unit_weight;
			}else{
				this.ShowUnitWet = '';
				this.select_foodunit = 'g';
				this.unit_weight = 0;
			}

			let me = this;
			MN.getActivateTypeList().then(res => {
				me.foodTypeTableData = res.food;
				me.foodWeightTableData = res.foodweight;
				me.edit_food_id = rowdata.id;
				me.select_foodtype = rowdata.stype;
			})
			
			this.newadd_food_pic = split_url[1];
			this.show_newadd_food_pic= rowdata.pic_url;
		
			this.newfood_name = rowdata.name;
			
			this.buy_price = rowdata.buy_price;
			this.addFoodDialogVisible = true
		},
		handleDeleteFood(rowid){
			let me = this;
			console.log(rowid)
			 this.$confirm('此操作将永久删除, 是否继续?', '提示', {
			confirmButtonText: '确定',
			cancelButtonText: '取消',
			type: 'warning'
			}).then(() => {
				let post={
					food_id:rowid
				}
				MN.handleDeleteFood(post).then(res => {
					me.initData(1);
				})
				
				this.$message({
					type: 'success',
					message: '删除成功!'
				});
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消删除'
				});          
			});
		},
		hadleSaveNewFood(){
			if( !this.newfood_name || !this.select_foodtype || !this.buy_price ){
				this.$message.error('请选择类别、输入食物名称、采购价、图片');
				return
			}

			if( this.select_foodunit != 'g' && this.unit_weight == '0' ){
				this.$message.error('选择其他食材单位后，请填写重量');
				return
			}


			let me = this;
			let post={
				pic_url:this.newadd_food_pic,
				food_name:this.newfood_name,
				food_type:this.select_foodtype,
				buy_price:this.buy_price,
				food_unit:this.select_foodunit,
				unit_weight:this.unit_weight
			}
			MN.setSaveNewFood(post).then(res => {
				console.log(res)
				if( res == 'error_input' ){
					this.$message.error('请选择类别、输入食物名称、采购价');
				}
				if( res == 'add_ok' ){
					me.initData(1);
					me.addFoodDialogVisible = false
				}
			})
		},
		handleAddFood(){
			let me = this;
			
			MN.getActivateTypeList().then(res => {
				me.foodTypeTableData = res.food;
				me.foodWeightTableData = res.foodweight;
			})
			
			this.show_newadd_food_pic ='';
			this.newadd_food_pic='';
			this.select_foodtype = '';
			this.newfood_name = '';
			this.buy_price = 0;
			this.ShowUnitWet ='';
			this.select_foodunit = 'g';
			this.unit_weight = 0;
			this.addFoodDialogVisible = true;
		},
		handleTabClick(){
			console.log(this.activeTab);
			this.initData(1);
		},
		initData(p_num){
			let me = this;
			this.loading = true
			let post={
				p_num:p_num
			};
			MN.getFoodList(post).then(res => {
				me.totalnum = res.total;
				me.tableFoodData=res.data;
				me.tableFoodData.forEach(items => {
					items.pic_url = "https://www.yyvvi.com/" + "uploads/" + items.pic_url
				});
				me.loading=false;
			})
		},
		handleMenuDelete(menu_uuid){
			let me = this;
			console.log(menu_uuid)
			this.$confirm('此操作将永久删除此菜单, 是否继续?', '提示', {
			confirmButtonText: '确定',
			cancelButtonText: '取消',
			type: 'warning'
			}).then(() => {
				let post={
					menu_uuid:menu_uuid
				}
				MN.handleDeleteMenu(post).then(res => {
					me.initData(1);
				})
				
				this.$message({
					type: 'success',
					message: '删除成功!'
				});
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消删除'
				});          
			});
		},
		handleBatchFood(){
			this.$router.push('/food/batch')
		},
		handleMenuEdit(rowdata){
			this.$router.push('/menu/edit?uuid='+rowdata.uuid)
		},
		handleMenuDetail(rowdata){
			this.$router.push('/menu/detail?uuid='+rowdata.uuid)
			//this.$router.push({name: 'topicsdetail', params: {uuid: rowdata.uuid}})
		}
	}
}
</script>

<style scoped>

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;background-color: red;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 18px;
    color: #4d7534;
    width: 65px;
    height: 65px;
    line-height: 65px;
    text-align: center;
	border: 1px dashed #a4c471;

  }
  .avatar {
    width: 65px;
    height:65px;
    display: block;
  }

.cover_img{
	font-size: 20px;
	background-color:#EDECE6;
	width: 65px;
	height: 65px;
	line-height: 65px;
	text-align:center;
	border-radius: 4px;
}
.sikopop_title{
	font-size: 16px;
	padding-bottom:10px;
	font-weight: bolder;
}
.sikopop{
	background-color: #f3f3f3;
	padding: 10px;
	border: 1px solid #EDECE6;
	border-radius: 5px;
	margin-left: 6px;
}
.panel_dixz_label{
	width: 15px;
	float: left;
	text-align:center;
	font-size: 12px;
	color: #0C922E;
	font-weight: bolder;
}
.panel_dixz{
	border: 1px solid #cbcbcb;
	background:url('@/assets/icon_green_bg.png') repeat-y #EDECE6;
	background-size: 27px;
	text-align: center;
	padding: 6px;
	border-radius: 8px;
	margin-bottom: 8px;
	position: relative;
}
.sixk_row{
	width: 14.22%;
	font-size: 13px;
}

.mt20{
	margin-top: 20px;
}
.uicxo{
	font-size: 12px;
	color: #bababa;
}
.panel_menu_rowx{
	position: relative;
	overflow-x: auto;
	width: 97%;
	margin: 0 0 0 2%;
}
.panel_menu_rowbig{
	width: 1250px;
	padding-bottom:16px;
}
.week_txo{
	position: absolute;
	left: 5px;
	top: -18px;
	font-size: 12px;
	color: #999999;
}
.dis_dish_row{ 
	position: relative;
	border-bottom: 1px dashed #cccccc;
	padding: 4px;
	text-align: center;
	font-size: 13px;
	margin-bottom: 5px;
	border-radius: 4px;
}
.panel_day_block{
	position: relative;
	background-color: #ffffff;
	box-shadow: 1px 1px 3px #b9b9b9;
	border-radius: 8px;
	min-height: 60px;
	width: 145px;
	padding: 5px;
	float: left;
	margin: 20px 0 6px 14px;
}
::v-deep .el-tabs__active-bar {
	cursor: pointer;
	background-color: #03BC5B;
}
::v-deep .el-tabs__item.is-active {
	color: #03BC5B;
	font-weight: bolder;
	cursor: pointer;
}
::v-deep .el-tabs__item:hover {
	color: #03BC5B;
	cursor: pointer;
}

</style>
