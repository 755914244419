<template>
	<div>

	<el-container class="data_skc">

		<el-aside class="aside-style" width="200px">
			<common-nav selec="5"></common-nav>
		</el-aside>
		
		<el-container>

			<el-header class="common_header">
				<common-header></common-header>
			</el-header>
			
			<el-main>

				<el-row :gutter="24" class="page_bar">
					<el-col :span="20" class="page_bar_title">订单列表</el-col>
					<el-col :span="4">
						<el-input placeholder="输入标题查找" v-model="searchValue" class="input-with-select" size="small" >
							<el-button slot="append" icon="el-icon-search"></el-button>
						</el-input>
					</el-col>
				</el-row>

					<el-table v-loading="loading" :data="orderTableData" size="medium" stripe highlight-current-row>					
							<el-table-column prop="status" label="状态" width="120" >
								<template slot-scope="scope">
									<label v-if="scope.row.status == 1">已创建</label>
									<label v-if="scope.row.status == 2" style="color:green">供应商接单</label>
									<!-- <label v-if="scope.row.status == 3" style="color:green">已下单</label> -->
								</template>
							</el-table-column>
							<el-table-column prop="order_no" label="订单编号" ></el-table-column>
							<el-table-column prop="kindergarten_name" label="下单幼儿园" ></el-table-column>
							<el-table-column prop="suppliser_name" label="供应商" ></el-table-column>
							<el-table-column prop="deliver_date" label="要求送货日期"></el-table-column>
							<el-table-column prop="create_date" label="下单日期"></el-table-column>
							<el-table-column label="操作" width="200">
								<template slot-scope="scope">
									<el-button type="primary" size="mini" @click="handleShowOrderDetail(scope.row.uuid)" plain>详情</el-button>
									<el-button type="warning" v-if="user_type=='supplier_user' && scope.row.status ==1 " size="mini" @click="handleOrderConfirm(scope.row.uuid)" plain>确认接单</el-button>
								</template>
							</el-table-column>
						</el-table>
					
				</el-main>

			<el-footer> copyright &copy; yyvvi.com </el-footer>
		</el-container>
	</el-container>


	</div>
</template>

<script>

import CommonNav from "@/components/Nav.vue";
import CommonHeader from "@/components/Header.vue";

import * as MN from '@/api/common'

export default {
    name: 'crm', 
	props:['selec'],
	data() {
		return {
			searchValue:'',
			loading:true,
			boxshow: false, 
			curTipsID: '', 
			orderTableData: [],
			user_type:''
		}
	},
	components: {
		CommonNav,
		CommonHeader
	},

	created() {
		this.initData()
		
	},

	mounted() {

	},

	methods: {
		handleOrderConfirm(order_uuid){
			let me = this;
			this.$confirm('确认下单吗？', '提示', {
				confirmButtonText: '确认',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				let post={
					order_uuid:order_uuid,
					supplier_uuid:sessionStorage.getItem('kindergarten_meals_session'),
				}
				MN.setOrderConfirm(post).then(res => {
					if( res == 'order_confirm' ){
						me.$message.success('已成功接单');
						me.initData()
					}
				})

			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消'
				});          
			});
		},
		initData(){
			let me = this;
			// this.user_type = sessionStorage.getItem('kindergarten_meals_usertype');
			this.loading = true
			MN.getOrderList().then(res => {
				this.orderTableData=res;
				this.loading=false;
			})
		},
		handleShowOrderDetail(order_uuid){
			this.$router.push('/order/detail?order_uuid='+order_uuid)
		},
		handlePSTableDetail(rowdata){
			this.$router.push('/topics/psdetail?uuid='+rowdata.uuid)
		},
		handleTableDetail(rowdata){
			
			this.$router.push('/topics/detail?uuid='+rowdata.uuid)
			//this.$router.push({name: 'topicsdetail', params: {uuid: rowdata.uuid}})
		}
	}
}
</script>

<style scoped>

</style>
