<template>
	<div>

	<el-container class="data_skc">

		<el-aside class="aside-style" width="200px">
			<common-nav selec="8"></common-nav>
		</el-aside>
		
		<el-container>

			<el-header class="common_header">
				<common-header></common-header>
			</el-header>
			
			<el-main>

				<el-tabs  v-model="activeTab" @tab-click="handleTabClick">
					<el-tab-pane label="幼儿园列表" name="kindergarten">
											
						<el-row :gutter="24" class="page_bar">
							<el-col :span="18">
								<el-button size="small" type="success" @click="showAddKindergarten"  icon="el-icon-plus" >新建幼儿园</el-button>
							</el-col>
						</el-row>
						<el-table v-loading="loading" :data="kindergartenTableData" size="medium" stripe highlight-current-row>					
							<el-table-column prop="status" label="状态" width="80" >
								<template slot-scope="scope">
									<label v-if="scope.row.status == 1" style="color:green">正常</label>
									<label v-if="scope.row.status == 0" style="color:red">停用</label>
									<!-- <label v-if="scope.row.status == 3" style="color:green">已下单</label> -->
								</template>
							</el-table-column>
							<el-table-column prop="name" label="联系人名" ></el-table-column>
							<el-table-column prop="phone" width="120" label="手机号"></el-table-column>
							<el-table-column prop="kindergarten_name" width="300" label="幼儿园名称" ></el-table-column>
							<el-table-column prop="address" label="联系地址" ></el-table-column>
							<el-table-column label="操作" width="170">
								<template slot-scope="scope">
									<el-button @click="handleChangeUserStatus('1','kindergarten_user',scope.row.uuid)" v-if="scope.row.status == 0" type="success" size="mini" plain>启用</el-button>
									<el-button @click="handleChangeUserStatus('0','kindergarten_user',scope.row.uuid)" v-if="scope.row.status == 1" type="warning" size="mini" plain>停用</el-button>
									<el-button @click="handleDelKindergarten(scope.row.uuid)" type="danger" size="mini" plain>删除</el-button>
								</template>
							</el-table-column>
						</el-table>
						<el-pagination
							background
							layout="prev, pager, next"
							:total="10" class="page_pagination">
						</el-pagination>

					</el-tab-pane>

					<el-tab-pane label="供应商列表" name="supplier">
						<el-row :gutter="24" class="page_bar">
							<el-col :span="18">
								<el-button size="small" type="success" @click="showAddSupplier"  icon="el-icon-plus" >新建供应商</el-button>
							</el-col>
						</el-row>
						<el-table v-loading="loading" :data="supplierTableData" size="medium" stripe highlight-current-row>					
							<el-table-column prop="status" label="状态" width="80" >
								<template slot-scope="scope">
									<label v-if="scope.row.status == 1" style="color:green">正常</label>
									<label v-if="scope.row.status == 0" style="color:red">停用</label>
									<!-- <label v-if="scope.row.status == 3" style="color:green">已下单</label> -->
								</template>
							</el-table-column>
							<el-table-column prop="manage_dish" label="管理菜品" width="80" >
								<template slot-scope="scope">
									<label v-if="scope.row.manage_dish == 1" style="color:green">有权限</label>
									<label v-if="scope.row.manage_dish == 0" style="color:red">无权限</label>
									<!-- <label v-if="scope.row.status == 3" style="color:green">已下单</label> -->
								</template>
							</el-table-column>
							<el-table-column prop="manage_food" label="管理食材" width="80" >
								<template slot-scope="scope">
									<label v-if="scope.row.manage_food == 1" style="color:green">有权限</label>
									<label v-if="scope.row.manage_food == 0" style="color:red">无权限</label>
									<!-- <label v-if="scope.row.status == 3" style="color:green">已下单</label> -->
								</template>
							</el-table-column>
							<el-table-column prop="company_name" label="供应商公司名" ></el-table-column>
							<el-table-column prop="name" width="100"  label="联系人" ></el-table-column>
							<el-table-column prop="phone" width="120" label="手机号"></el-table-column>
							<el-table-column prop="address" label="联系地址" ></el-table-column>
							<el-table-column label="操作" width="170">
								<template slot-scope="scope">
									<el-button v-if="scope.row.status == 0" @click="handleChangeUserStatus('1','supplier_user',scope.row.uuid)"  type="success" size="mini" plain>启用</el-button>
									<el-button v-if="scope.row.status == 1" @click="handleChangeUserStatus('0','supplier_user',scope.row.uuid)"  type="warning" size="mini" plain>停用</el-button>
									<el-button @click="handleDelSupplier(scope.row.uuid)" type="danger" size="mini" plain>删除</el-button>
								</template>
							</el-table-column>
						</el-table>
						<el-pagination
							background
							layout="prev, pager, next"
							:total="10" class="page_pagination">
						</el-pagination>
					</el-tab-pane>

					<el-tab-pane label="系统用户列表" name="admin">
						<el-row :gutter="24" class="page_bar">
							<el-col :span="18">
								<el-button size="small" type="success"  icon="el-icon-plus" @click="showAddAdminUser"  >新建用户</el-button>
							</el-col>
						</el-row>
						<el-table v-loading="loading" :data="adminTableData" size="medium" stripe highlight-current-row>					
							<el-table-column prop="status" label="状态" width="80" >
								<template slot-scope="scope">
									<label v-if="scope.row.status == 1" style="color:green">正常</label>
									<label v-if="scope.row.status == 0" style="color:red">停用</label>
									<!-- <label v-if="scope.row.status == 3" style="color:green">已下单</label> -->
								</template>
							</el-table-column>
							<el-table-column prop="status" label="权限" width="80" >
								<template slot-scope="scope">
									<label v-if="scope.row.status == 1">总管理</label>
									<!-- <label v-if="scope.row.status == 2" style="color:red">停用</label> -->
									<!-- <label v-if="scope.row.status == 3" style="color:green">已下单</label> -->
								</template>
							</el-table-column>
							<el-table-column prop="name" label="姓名" ></el-table-column>
							<el-table-column prop="phone" label="手机号"></el-table-column>
							<!-- <el-table-column prop="kindergarten_name" width="300" label="幼儿园名称" ></el-table-column> -->
							<!-- <el-table-column prop="address" label="联系地址" ></el-table-column> -->
							<el-table-column label="操作" width="170">
								<template slot-scope="scope">
									<el-button v-if="scope.row.status == 0" @click="handleChangeUserStatus('1','admin_user',scope.row.uuid)"  type="success" size="mini" plain>启用</el-button>
									<el-button v-if="scope.row.status == 1" @click="handleChangeUserStatus('0','admin_user',scope.row.uuid)"  type="warning" size="mini" plain>停用</el-button>
									<el-button @click="handleDelAdmin(scope.row.uuid)" type="danger" size="mini" plain>删除</el-button>
								</template>
							</el-table-column>
						</el-table>
						<el-pagination
							background
							layout="prev, pager, next"
							:total="10" class="page_pagination">
						</el-pagination>
					</el-tab-pane>

					<el-tab-pane label="分类项管理" name="unit">
						<el-row :gutter="24" style="margin:20px 0 0 0">
							<el-col :span="8">
								<el-card class="box-card" shadow="never">
									<div slot="header" class="clearfix">
										<span class="card_sub_title">菜品分类管理</span>
										<el-button style="float: right; padding: 3px 0" type="text" @click="handleAddType('dish')">新增</el-button>
									</div>
									<el-table size="small" v-loading="loading" :data="dishTypeTableData" :show-header="false" stripe highlight-current-row>					
										<el-table-column prop="status" label="状态" width="50" >
											<template slot-scope="scope">
												<label v-if="scope.row.flag == 1" style="color:green">正常</label>
												<label v-if="scope.row.flag == 0" style="color:red">停用</label>
											</template>
										</el-table-column>
										<el-table-column prop="name" label="名称" ></el-table-column>
										<el-table-column label="操作" width="150">
											<template slot-scope="scope">
												<el-button v-if="scope.row.flag == 0" @click="handleChangeTypeStatus('1','dish',scope.row.id)"  type="success" size="mini" plain>启用</el-button>
												<el-button v-if="scope.row.flag == 1" @click="handleChangeTypeStatus('0','dish',scope.row.id)"  type="warning" size="mini" plain>停用</el-button>
												<el-button @click="handleDelType('dish',scope.row.id)" type="danger" size="mini" plain>删除</el-button>
											</template>
										</el-table-column>
									</el-table>
								</el-card>
							</el-col>
							<el-col :span="8">
								<el-card class="box-card" shadow="never">
									<div slot="header" class="clearfix">
										<span class="card_sub_title">食材分类管理</span>
										<el-button style="float: right; padding: 3px 0" type="text" @click="handleAddType('food')">新增</el-button>
									</div>
									<el-table size="small" v-loading="loading" :data="foodTypeTableData" :show-header="false" stripe highlight-current-row>					
										<el-table-column prop="status" label="状态" width="50" >
											<template slot-scope="scope">
												<label v-if="scope.row.flag == 1" style="color:green">正常</label>
												<label v-if="scope.row.flag == 0" style="color:red">停用</label>
											</template>
										</el-table-column>
										<el-table-column prop="name" label="名称" ></el-table-column>
										<el-table-column label="操作" width="150">
											<template slot-scope="scope">
												<el-button v-if="scope.row.flag == 0" @click="handleChangeTypeStatus('1','food',scope.row.id)"  type="success" size="mini" plain>启用</el-button>
												<el-button v-if="scope.row.flag == 1" @click="handleChangeTypeStatus('0','food',scope.row.id)"  type="warning" size="mini" plain>停用</el-button>
												<el-button @click="handleDelType('food',scope.row.id)" type="danger" size="mini" plain>删除</el-button>
											</template>
										</el-table-column>
									</el-table>
								</el-card>
							</el-col>
							<el-col :span="8">
								<el-card class="box-card" shadow="never">
									<div slot="header" class="clearfix">
										<span class="card_sub_title">食材单位管理</span>
										<el-button style="float: right; padding: 3px 0" type="text" @click="handleAddType('weight')">新增</el-button>
									</div>
									<el-table size="small" v-loading="loading" :data="foodWeightTableData" :show-header="false" stripe highlight-current-row>					
										<el-table-column prop="status" label="状态" width="50" >
											<template slot-scope="scope">
												<label v-if="scope.row.flag == 1" style="color:green">正常</label>
												<label v-if="scope.row.flag == 0" style="color:red">停用</label>
											</template>
										</el-table-column>
										<el-table-column prop="name" label="名称" ></el-table-column>
										<el-table-column label="操作" width="150">
											<template slot-scope="scope">
												<block v-if="scope.row.name != 'g'">
													<el-button v-if="scope.row.flag == 0" @click="handleChangeTypeStatus('1','weight',scope.row.id)"  type="success" size="mini" plain>启用</el-button>
													<el-button v-if="scope.row.flag == 1" @click="handleChangeTypeStatus('0','weight',scope.row.id)"  type="warning" size="mini" plain>停用</el-button>
													<el-button @click="handleDelType('weight',scope.row.id)" type="danger" size="mini" plain>删除</el-button>
												</block>
											</template>
										</el-table-column>
									</el-table>
								</el-card>
							</el-col>
						</el-row>
					</el-tab-pane>

				</el-tabs>

				</el-main>

			<el-footer> copyright &copy; yyvvi.com </el-footer>
		</el-container>
	</el-container>


	<el-dialog
		title="新建幼儿园"
		:visible.sync="dialogAddKindergarten"
		width="40%"
		:close-on-click-modal="false">
		<el-row class="row_spac">
			<el-col :span="24"><span class="red_dot">*</span>幼儿园名称：<el-input v-model="new_kindergarten_name" placeholder="请输入内容" size="small"></el-input></el-col>
		</el-row>
		<el-row class="row_spac">
			<el-col :span="24">详细地址：<el-input v-model="new_kindergarten_address" placeholder="请输入内容" size="small"></el-input></el-col>
		</el-row>
		<el-row class="row_spac">
			<el-col :span="12"><span class="red_dot">*</span>联系人姓名：<el-input v-model="new_kindergarten_people" placeholder="请输入内容" size="small"></el-input></el-col>
		</el-row>
		<el-row class="row_spac">
			<el-col :span="12"><span class="red_dot">*</span>手机号（登陆系统用，请务必填写正确）：<el-input v-model="new_kindergarten_phone" placeholder="请输入内容" size="small"></el-input></el-col>
			<el-col :span="11" :offset="1" class="risl"><span class="red_dot">*</span>登陆密码：<el-input  max="8" maxlength="8" show-password v-model="new_kindergarten_userpass" placeholder="请输入内容" size="small"></el-input></el-col>
		</el-row>
		<el-row class="row_spac">
			<el-col :span="24">提示：手机号是唯一凭证，也会用于小程序登陆时绑定用。</el-col>
		</el-row>
		<span slot="footer" class="dialog-footer">
			<el-button @click="dialogAddKindergarten = false">取 消</el-button>
			<el-button type="primary" @click="handleSaveNewKindergarten">保 存</el-button>
		</span>
	</el-dialog>


	<el-dialog
		title="新建供应商"
		:visible.sync="dialogAddSupplier"
		width="40%"
		:close-on-click-modal="false">
		<el-row class="row_spac">
			<el-col :span="24"><span class="red_dot">*</span>公司名称：<el-input v-model="new_supplier_company" placeholder="请输入内容" size="small"></el-input></el-col>
		</el-row>
		<el-row class="row_spac">
			<el-col :span="24">详细地址：<el-input v-model="new_supplier_address" placeholder="请输入内容" size="small"></el-input></el-col>
		</el-row>
		<el-row class="row_spac">
			<el-col :span="12"><span class="red_dot">*</span>联系人姓名：<el-input v-model="new_supplier_name" placeholder="请输入内容" size="small"></el-input></el-col>
		</el-row>
		<el-row class="row_spac">
			<el-col :span="12"><span class="red_dot">*</span>手机号（登陆系统用，请务必填写正确）：<el-input v-model="new_supplier_phone" placeholder="请输入内容" size="small"></el-input></el-col>
			<el-col :span="11" :offset="1" class="risl"><span class="red_dot">*</span>登陆密码：<el-input max="8" maxlength="8" show-password v-model="new_supplier_userpass" placeholder="请输入内容" size="small"></el-input></el-col>
		</el-row>
		<el-row class="row_spac">
			<el-col :span="12">
				<el-switch active-color="#36D900" v-model="new_supplier_manage_dish" active-text="可管理菜品（增、删、改）"></el-switch>
			</el-col>
		</el-row>
		<el-row class="row_spac">
			<el-col :span="12">
				<el-switch active-color="#36D900" v-model="new_supplier_manage_food" active-text="可管理食材（增、删、改）"></el-switch>
			</el-col>
		</el-row>
		<el-row class="row_spac">
			<el-col :span="24">提示：手机号是唯一凭证，也会用于小程序登陆时绑定用。</el-col>
		</el-row>
		<span slot="footer" class="dialog-footer">
			<el-button @click="dialogAddSupplier = false">取 消</el-button>
			<el-button type="primary" @click="handleSaveNewSupplier">保 存</el-button>
		</span>
	</el-dialog>



	<el-dialog
		title="新建管理员"
		:visible.sync="dialogAddAdmin"
		width="40%"
		:close-on-click-modal="false">
		<el-row class="row_spac">
			<el-col :span="12"><span class="red_dot">*</span>姓名：<el-input v-model="new_admin_name" placeholder="请输入内容" size="small"></el-input></el-col>
		</el-row>
		<el-row class="row_spac">
			<el-col :span="12"><span class="red_dot">*</span>手机号（登陆系统用，请务必填写正确）：<el-input v-model="new_admin_phone" placeholder="请输入内容" size="small"></el-input></el-col>
			<el-col :span="11" :offset="1" class="risl"><span class="red_dot">*</span>登陆密码：<el-input  max="8" maxlength="8" v-model="new_admin_userpass" show-password placeholder="请输入内容" size="small"></el-input></el-col>
		</el-row>
		<el-row class="row_spac">
			<el-col :span="24">提示：手机号是唯一凭证，也会用于小程序登陆时绑定用。</el-col>
		</el-row>
		<span slot="footer" class="dialog-footer">
			<el-button @click="dialogAddAdmin = false">取 消</el-button>
			<el-button type="primary"  @click="handleSaveNewAdmin">保 存</el-button>
		</span>
	</el-dialog>


	<el-dialog
		:title="dialogAddTypeTitle"
		:visible.sync="dialogAddType"
		width="30%"
		:close-on-click-modal="false">
		<el-row class="row_spac">
			<el-col :span="24"><span class="red_dot">*</span>名称：<el-input maxlength="50" v-model="new_type_name" placeholder="请输入内容" ></el-input></el-col>
		</el-row>
		<span slot="footer" class="dialog-footer">
			<el-button @click="dialogAddType = false">取 消</el-button>
			<el-button type="primary"  @click="handleSaveNewType">保 存</el-button>
		</span>
	</el-dialog>



	</div>
</template>

<script>

import CommonNav from "@/components/Nav.vue";
import CommonHeader from "@/components/Header.vue";

import * as MN from '@/api/common'

export default {
    name: 'crm', 
	props:['selec'],
	data() {
		return {
			new_type:'',
			dialogAddType:false,
			dialogAddTypeTitle:'',
			new_type_name:'',
			dishTypeTableData:[],
			foodTypeTableData:[],
			foodWeightTableData:[],
			new_kindergarten_name:'',
			new_kindergarten_address:'',
			new_kindergarten_people:'',
			new_kindergarten_phone:'',
			new_kindergarten_userpass:'',

			new_supplier_company:'',
			new_supplier_address:'',
			new_supplier_name:'',
			new_supplier_phone:'',
			new_supplier_userpass:'',
			new_supplier_manage_dish:true,
			new_supplier_manage_food:true,

			new_admin_phone:'',
			new_admin_name:'',
			new_admin_userpass:'',

			activeTab:'kindergarten',
			searchValue:'',
			loading:true,
			boxshow: false, 
			curTipsID: '', 
			kindergartenTableData: [],
			supplierTableData: [],
			adminTableData: [],
			dialogAddKindergarten:false,
			dialogAddSupplier:false,
			dialogAddAdmin:false
		}
	},
	components: {
		CommonNav,
		CommonHeader
	},

	created() {
		this.initData()
	},

	mounted() {
		this.initData()
	},

	methods: {
		handleDelKindergarten(uuid){
			let me = this;
			this.$confirm("确认删除吗？", '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				let post={
					uuid:uuid
				}
				MN.setDelKindergarten(post).then(res => {
					me.$message({
						type: 'success',
						message: '操作成功!'
					});
					me.initData();
				})
				
			}).catch(() => {
				me.$message({
					type: 'info',
					message: '已取消'
				});          
			});
		},
		handleDelSupplier(uuid){
			let me = this;
			this.$confirm("确认删除吗？", '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				let post={
					uuid:uuid
				}
				MN.setDelSupplier(post).then(res => {
					me.$message({
						type: 'success',
						message: '操作成功!'
					});
					me.initData();
				})
				
			}).catch(() => {
				me.$message({
					type: 'info',
					message: '已取消'
				});          
			});
		},
		handleDelAdmin(uuid){
			let me = this;
			this.$confirm("确认删除吗？", '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				let post={
					uuid:uuid
				}
				MN.setDelAdmin(post).then(res => {
					if( res == 'only_one' ){
						me.$message({
							type: 'info',
							message: '唯一的管理员账号不能删除'
						});  
						return
					}

					if( res == 'set_ok' ){
						me.$message({
							type: 'success',
							message: '操作成功!'
						});
						me.initData();
					}
				})
				
			}).catch(() => {
				me.$message({
					type: 'info',
					message: '已取消'
				});          
			});
		},
		handleSaveNewType(){
			let me = this;
			if ( !this.new_type_name ){
				this.$message.error('请输入名称');
				return
			}

			let post={
				u_type:me.new_type,
				type_name:me.new_type_name
			}
			MN.setAddNewType(post).then(res => {
				me.$message({
					type: 'success',
					message: '操作成功!'
				});
				me.initData();
				me.dialogAddType=false
			})

		},
		handleAddType(u_type){
			
			if ( u_type == 'dish'){
				this.dialogAddTypeTitle = '新增菜品分类';
			}

			if ( u_type == 'food'){
				this.dialogAddTypeTitle = '新增食材分类';
			}

			if ( u_type == 'weight'){
				this.dialogAddTypeTitle = '新增食材单位';
			}

			this.dialogAddType = true;
			this.new_type = u_type;
			this.new_type_name = '';
		},
		handleDelType(u_type,id){
			let me = this;
			this.$confirm("确认删除吗？", '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				let post={
					u_type:u_type,
					u_id:id
				}
				MN.setDelTypeData(post).then(res => {
					me.$message({
						type: 'success',
						message: '操作成功!'
					});
					me.initData();
				})
				
			}).catch(() => {
				me.$message({
					type: 'info',
					message: '已取消'
				});          
			});
		},
		handleChangeTypeStatus(new_status,u_type,id){
			let show_label = '';
			let me = this;
			if ( new_status == '1' ){
				show_label = '确认启用吗？';
			}else{
				show_label = '确认停用吗？';
			}
			this.$confirm(show_label, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {

				let post={
					u_type:u_type,
					u_id:id,
					u_status:new_status
				}
				MN.setChangeDataStatus(post).then(res => {
					me.$message({
						type: 'success',
						message: '操作成功!'
					});
					me.initData();
				})
				
			}).catch(() => {
				me.$message({
					type: 'info',
					message: '已取消'
				});          
			});
		},
		handleChangeUserStatus(new_status,u_type,uuid){
			let show_label = '';
			let me = this;
			if ( new_status == '1' ){
				show_label = '确认启用这个账号吗？';
			}else{
				show_label = '确认停用这个账号吗？';
			}
			this.$confirm(show_label, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {

				let post={
					u_type:u_type,
					u_uuid:uuid,
					u_status:new_status
				}
				MN.setChangeUserStatus(post).then(res => {
					me.$message({
						type: 'success',
						message: '操作成功!'
					});
					me.initData();
				})
				
			}).catch(() => {
				me.$message({
					type: 'info',
					message: '已取消'
				});          
			});

		},
		handleSaveNewKindergarten(){
			if( !this.new_kindergarten_name ){
				this.$message.error('请输入公司名称');
				return
			}
			if( !this.new_kindergarten_people ){
				this.$message.error('请输入联系人');
				return
			}
			if( !this.new_kindergarten_phone ){
				this.$message.error('请输入正确手机号');
				return
			}
			if( !this.new_kindergarten_userpass ){
				this.$message.error('请输入密码');
				return
			}

			let me = this;
			this.loading = true
			let post={
				kindergarten_name:me.new_kindergarten_name,
				kindergarten_address:me.new_kindergarten_address,
				kindergarten_people:me.new_kindergarten_people,
				kindergarten_phone:me.new_kindergarten_phone,
				kindergarten_userpass:me.new_kindergarten_userpass
			}
			MN.setSaveNewKindergarten(post).then(res => {
				me.loading = false;
				if ( res == 'hasUser' ){
					this.$message.error('已有此幼儿园账号了');
					return
				}
				if ( res == 'add_ok' ){
					me.dialogAddKindergarten = false;
					me.initData();
				}
			})
		},
		showAddKindergarten(){
			this.new_kindergarten_name = '';
			this.new_kindergarten_address = '';
			this.new_kindergarten_people = '';
			this.new_kindergarten_phone = '';
			this.new_kindergarten_userpass = '';
			this.dialogAddKindergarten = true;
		},
		showAddSupplier(){
			this.new_supplier_company = '';
			this.new_supplier_address = '';
			this.new_supplier_name = '';
			this.new_supplier_phone = '';
			this.new_supplier_userpass = '';
			this.new_supplier_manage_dish = true;
			this.new_supplier_manage_food = true;
			this.dialogAddSupplier = true;
		},
		handleSaveNewSupplier(){
			if( !this.new_supplier_company ){
				this.$message.error('请输入公司名称');
				return
			}
			if( !this.new_supplier_name ){
				this.$message.error('请输入联系人姓名');
				return
			}
			if( !this.new_supplier_phone ){
				this.$message.error('请输入正确手机号');
				return
			}
			if( !this.new_supplier_userpass ){
				this.$message.error('请输入密码');
				return
			}

			let me = this;
			this.loading = true
			let post={
				supplier_company:me.new_supplier_company,
				supplier_address:me.new_supplier_address,
				supplier_name:me.new_supplier_name,
				supplier_phone:me.new_supplier_phone,
				supplier_userpass:me.new_supplier_userpass,
				supplier_manage_dish:me.new_supplier_manage_dish,
				supplier_manage_food:me.new_supplier_manage_food
			}
			MN.setSaveNewSupplier(post).then(res => {
				me.loading = false;
				if ( res == 'hasUser' ){
					this.$message.error('已有此供应商账号了');
					return
				}
				if ( res == 'add_ok' ){
					me.dialogAddSupplier = false;
					me.initData();
				}
			})
		},
		handleSaveNewAdmin(){
			if( !this.new_admin_phone ){
				this.$message.error('请输入正确手机号');
				return
			}
			if( !this.new_admin_name ){
				this.$message.error('请输入联系人姓名');
				return
			}
			if( !this.new_admin_userpass ){
				this.$message.error('请输入密码');
				return
			}

			let me = this;
			this.loading = true
			let post={
				admin_phone:me.new_admin_phone,
				admin_name:me.new_admin_name,
				admin_userpass:me.new_admin_userpass,
			}
			MN.setSaveNewAdmin(post).then(res => {
				me.loading = false;
				if ( res == 'hasUser' ){
					this.$message.error('已有此管理员账号了');
					return
				}
				if ( res == 'add_ok' ){
					me.dialogAddAdmin = false;
					me.initData();
				}
			})
		},
		showAddAdminUser(){
			this.new_admin_phone = '';
			this.new_admin_name = '';
			this.new_admin_userpass = '';
			this.dialogAddAdmin = true;
		},
		handleTabClick(){
			console.log( this.activeTab )
			this.initData()
		},
		initData(){
			let me = this;
			this.loading = true
			let post={
				active_tab:this.activeTab,
			}
			MN.getUserDataList(post).then(res => {
				if ( res.stype == 'staff_user' ){
					this.kindergartenTableData=res.list;
				}

				if ( res.stype == 'supplier_user' ){
					this.supplierTableData=res.list;
				}

				if ( res.stype == 'admin_user' ){
					this.adminTableData=res.list;
				}

				this.loading=false;
			})
			
			MN.getMenuTypeDataList().then(res => {
				this.dishTypeTableData = res.dishtype;
				this.foodTypeTableData = res.food;
				this.foodWeightTableData = res.foodweight;
			})
		},
		handlePSTableDetail(rowdata){
			this.$router.push('/topics/psdetail?uuid='+rowdata.uuid)
		},
		handleTableDetail(rowdata){
			
			this.$router.push('/topics/detail?uuid='+rowdata.uuid)
			//this.$router.push({name: 'topicsdetail', params: {uuid: rowdata.uuid}})
		}
	}
}
</script>

<style scoped>

.row_spac{ margin-bottom: 20px; }

.risl{ color: #e23737; }

.red_dot{ color: #e23737; font-weight: bold; }

::v-deep .el-tabs__active-bar {
	cursor: pointer;
	background-color: #03BC5B;
}
::v-deep .el-tabs__item.is-active {
	color: #03BC5B;
	font-weight: bolder;
	cursor: pointer;
}
::v-deep .el-tabs__item:hover {
	color: #03BC5B;
	cursor: pointer;
}

/* ::v-deep .el-card__body, .el-main{
	padding: 0;
} */
</style>
