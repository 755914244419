<template>
	<div>

	<el-container class="data_skc">

		<el-aside class="aside-style" width="200px">
			<common-nav selec="4"></common-nav>
		</el-aside>
		
		<el-container>

			<el-header class="common_header">
				<common-header></common-header>
			</el-header>
			
			<el-main>

				<el-row :gutter="24" class="page_bar">
					<el-col :span="20" class="page_bar_title"><i @click="handleGoBack()" class="el-icon-back showhand" style="font-size:22px"></i>  订单详情</el-col>
					<el-col :span="4"></el-col>
				</el-row>

				<el-row style="margin:10px 0 5px 0">
					<el-col :span="24">
						<el-descriptions class="margin-top" :column="2" size="medium" border>
							<el-descriptions-item>
								<template slot="label">
									订单编号
								</template>
								{{detail_orderno}}
							</el-descriptions-item>
							
							<el-descriptions-item>
								<template slot="label">
									订单状态
								</template>
								<span v-if="detail_status==1" >已创建</span>
								<span v-if="detail_status==2" style="color:green">供应商接单</span>
							</el-descriptions-item>
							<el-descriptions-item>
								<template slot="label">
									下单幼儿园
								</template>
								{{detail_kindergarten_name}}
							</el-descriptions-item>
							<el-descriptions-item>
								<template slot="label">
									下单日期
								</template>
								{{detail_create_date}}
							</el-descriptions-item>
							<el-descriptions-item>
								<template slot="label">
									下单给
								</template>
								{{detail_suppliser_name}}
							</el-descriptions-item>
							<el-descriptions-item>
								<template slot="label">
									要求送货日期
								</template>
								{{detail_deliver_date}}
							</el-descriptions-item>
						</el-descriptions>
					</el-col>
				</el-row>

				<el-tabs  class="mt20" v-model="activeName"  >
					<el-tab-pane label="订单明细" name='order'>

						<el-row class="barsxk_op">
							<el-col :span="8">
								<el-switch
									v-model="showQuality"
									active-text="按千克（kg）显示"
									inactive-text="按克（g）显示" 
									active-color="#13ce66"  
									inactive-color="#13ce66"
									active-value="kg" 
									inactive-value="g" @change="switchQuality">
								</el-switch>
							</el-col>
						</el-row>


					<el-row>
					<el-col class="sixk_row">
						<el-card class="box-card" shadow="never">
							<div slot="header" class="clearfix">
								<span class="card_sub_title">周一</span>
								<!-- <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button> -->
							</div>
							<div class="panel_people_num">
								<span>早餐人数:</span><strong>{{MonPeople_zaocan}}</strong><br>
								<span>加餐人数:</span><strong>{{MonPeople_jiacan}}</strong><br>
								<span>午餐人数:</span><strong>{{MonPeople_wucan}}</strong><br>
								<span>午点人数:</span><strong>{{MonPeople_wudian}}</strong><br>
								<span>晚餐人数:</span><strong>{{MonPeople_wancan}}</strong>
							</div>
							<div v-for="item in MonDose" :key="item.uuid" class="panel_food_row">
								{{item.food_name}}  <div class="panel_food_wig">{{item.newdose}}g</div>
								<div v-if="item.dose_package != '0'" class="more_weight">共{{item.dose_package}}{{item.food_unit}}</div>
							</div>
						</el-card>
					</el-col>
					<el-col class="sixk_row">
						<el-card class="box-card" shadow="never">
							<div slot="header" class="clearfix">
								<span class="card_sub_title">周二</span>
								<!-- <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button> -->
							</div>
							<div class="panel_people_num">
								<span>早餐人数:</span><strong>{{TuePeople_zaocan}}</strong><br>
								<span>加餐人数:</span><strong>{{TuePeople_jiacan}}</strong><br>
								<span>午餐人数:</span><strong>{{TuePeople_wucan}}</strong><br>
								<span>午点人数:</span><strong>{{TuePeople_wudian}}</strong><br>
								<span>晚餐人数:</span><strong>{{TuePeople_wancan}}</strong>
							</div>
							<div v-for="item in TueDose" :key="item.uuid" class="panel_food_row">
								{{item.food_name}}  <div class="panel_food_wig">{{item.newdose}}g</div>
								<div v-if="item.dose_package != '0'" class="more_weight">共{{item.dose_package}}{{item.food_unit}}</div>
							</div>
						</el-card>
					</el-col>
					<el-col class="sixk_row">
						<el-card class="box-card" shadow="never">
							<div slot="header" class="clearfix">
								<span class="card_sub_title">周三</span>
								<!-- <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button> -->
							</div>
							<div class="panel_people_num">
								<span>早餐人数:</span><strong>{{WedPeople_zaocan}}</strong><br>
								<span>加餐人数:</span><strong>{{WedPeople_jiacan}}</strong><br>
								<span>午餐人数:</span><strong>{{WedPeople_wucan}}</strong><br>
								<span>午点人数:</span><strong>{{WedPeople_wudian}}</strong><br>
								<span>晚餐人数:</span><strong>{{WedPeople_wancan}}</strong>
							</div>
							<div v-for="item in WedDose" :key="item.uuid" class="panel_food_row">
								{{item.food_name}}  <div class="panel_food_wig">{{item.newdose}}g</div>
								<div v-if="item.dose_package != '0'" class="more_weight">共{{item.dose_package}}{{item.food_unit}}</div>
							</div>
						</el-card>
					</el-col>
					<el-col class="sixk_row">
						<el-card class="box-card" shadow="never">
							<div slot="header" class="clearfix">
								<span class="card_sub_title">周四</span>
								<!-- <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button> -->
							</div>
							<div class="panel_people_num">
								<span>早餐人数:</span><strong>{{ThuPeople_zaocan}}</strong><br>
								<span>加餐人数:</span><strong>{{ThuPeople_jiacan}}</strong><br>
								<span>午餐人数:</span><strong>{{ThuPeople_wucan}}</strong><br>
								<span>午点人数:</span><strong>{{ThuPeople_wudian}}</strong><br>
								<span>晚餐人数:</span><strong>{{ThuPeople_wancan}}</strong>
							</div>
							<div v-for="item in ThuDose" :key="item.uuid" class="panel_food_row">
								{{item.food_name}}  <div class="panel_food_wig">{{item.newdose}}g</div>
								<div v-if="item.dose_package != '0'" class="more_weight">共{{item.dose_package}}{{item.food_unit}}</div>
							</div>
						</el-card>
					</el-col>
					<el-col class="sixk_row">
						<el-card class="box-card" shadow="never">
							<div slot="header" class="clearfix">
								<span class="card_sub_title">周五</span>
								<!-- <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button> -->
							</div>
							<div class="panel_people_num">
								<span>早餐人数:</span><strong>{{FriPeople_zaocan}}</strong><br>
								<span>加餐人数:</span><strong>{{FriPeople_jiacan}}</strong><br>
								<span>午餐人数:</span><strong>{{FriPeople_wucan}}</strong><br>
								<span>午点人数:</span><strong>{{FriPeople_wudian}}</strong><br>
								<span>晚餐人数:</span><strong>{{FriPeople_wancan}}</strong>
							</div>
							<div v-for="item in FriDose" :key="item.uuid" class="panel_food_row">
								{{item.food_name}}  <div class="panel_food_wig">{{item.newdose}}g</div>
								<div v-if="item.dose_package != '0'" class="more_weight">共{{item.dose_package}}{{item.food_unit}}</div>
							</div>
						</el-card>
					</el-col>
					<el-col class="sixk_row">
						<el-card class="box-card" shadow="never">
							<div slot="header" class="clearfix">
								<span class="card_sub_title">周六</span>
								<!-- <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button> -->
							</div>
							<div class="panel_people_num">
								<span>早餐人数:</span><strong>{{SatPeople_zaocan}}</strong><br>
								<span>加餐人数:</span><strong>{{SatPeople_jiacan}}</strong><br>
								<span>午餐人数:</span><strong>{{SatPeople_wucan}}</strong><br>
								<span>午点人数:</span><strong>{{SatPeople_wudian}}</strong><br>
								<span>晚餐人数:</span><strong>{{SatPeople_wancan}}</strong>
							</div>
							<div v-for="item in SatDose" :key="item.uuid" class="panel_food_row">
								{{item.food_name}}  <div class="panel_food_wig">{{item.newdose}}g</div>
								<div v-if="item.dose_package != '0'" class="more_weight">共{{item.dose_package}}{{item.food_unit}}</div>
							</div>
						</el-card>
					</el-col>
					<el-col class="sixk_row">
						<el-card class="box-card" shadow="never">
							<div slot="header" class="clearfix">
								<span class="card_sub_title">周日</span>
								<!-- <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button> -->
							</div>
							<div class="panel_people_num">
								<span>早餐人数:</span><strong>{{SunPeople_zaocan}}</strong><br>
								<span>加餐人数:</span><strong>{{SunPeople_jiacan}}</strong><br>
								<span>午餐人数:</span><strong>{{SunPeople_wucan}}</strong><br>
								<span>午点人数:</span><strong>{{SunPeople_wudian}}</strong><br>
								<span>晚餐人数:</span><strong>{{SunPeople_wancan}}</strong>
							</div>
							<div v-for="item in SunDose" :key="item.uuid" class="panel_food_row">
								{{item.food_name}}  <div class="panel_food_wig">{{item.newdose}}g</div>
								<div v-if="item.dose_package != '0'" class="more_weight">共{{item.dose_package}}{{item.food_unit}}</div>
							</div>
						</el-card>
					</el-col>
				</el-row>

					</el-tab-pane>


					<el-tab-pane  name='message'>
						<span slot="label" class="label_message"><span class="dot_item"></span>问题咨询</span>

						<el-row class="barsxk_op">
							<el-col :span="4">
								<el-button type="success" size="small" @click="handleCreateQuestion">创建新问题</el-button>
							</el-col>
						</el-row>

						<el-empty description="没有记录" v-if="QuestionListEmpty == true"></el-empty>

						<el-card v-for="item in questionDataList" :key="item.id" class="box-card" >
							<div  v-for="logrow in item.logList" :key="logrow.id"  >
								<div class="sixx_oprow" v-if="logrow.chat_type=='1'" >
									{{logrow.message_body}}
									<el-row class="fss_info">
										<el-col :span="10" >相关订单编号：{{item.receipt_id}}</el-col>
										<el-col :span="5" >提问人：{{logrow.create_user}}</el-col><el-col :span="5" >日期：{{logrow.create_date}}</el-col>
									</el-row>
								</div>
								<div class="sdxoa_rpw"  v-if="logrow.chat_type=='2'">
									回复：{{logrow.message_body}}
									<el-row class="fss_info">
										<el-col :span="8" >{{logrow.create_date}}</el-col>
									</el-row>
								</div>
							</div>
							<el-row class="mt20">
								<el-col :span="24" style="text-align: right">
									<el-button plain type="success" size="mini" @click="handleCreateSubQuestion(item.id)">追问</el-button>   
								</el-col>
							</el-row>
						</el-card>

					</el-tab-pane>
				</el-tabs>

		
				<div class="page_bottom"></div>
			</el-main>

			<el-footer> copyright &copy; yyvvi.com </el-footer>
		</el-container>
	</el-container>

	<el-dialog
	title="创建问题"
	:visible.sync="dialogCreateQuestion"
	width="40%"  :close-on-click-modal="false">
	<el-row>
		<el-col :span="24">
			<el-input
			type="textarea"
			:rows="4"
			placeholder="请输入内容"  maxlength="200"  show-word-limit 
			v-model="new_ask_question">
			</el-input>
		</el-col> 
	</el-row>
	<el-row class="mt20">
		<el-col :span="20" >
			相关订单编号：{{this.detailUUID}}
		</el-col>
	</el-row>

	<span slot="footer" class="dialog-footer">
		<el-button @click="dialogCreateQuestion = false">取 消</el-button>
		<el-button type="primary" @click="hadleSaveNewQuestion">保存</el-button>
	</span>
	</el-dialog>

	<el-dialog
		title="追问"
		:visible.sync="dialogCreateSubQuestion"
		width="30%"  :close-on-click-modal="false">
		<el-row>
			<el-col :span="24">
				<el-input
				type="textarea"
				:rows="3"
				placeholder="请输入内容"  maxlength="200"  show-word-limit 
				v-model="subask_question">
				</el-input>
			</el-col> 
		</el-row>
		<span slot="footer" class="dialog-footer">
			<el-button @click="dialogCreateSubQuestion = false">取 消</el-button>
			<el-button type="primary" @click="hadleSaveNewSubQuestion">保存</el-button>
		</span>
	</el-dialog>

	</div>
</template>

<script>
import CommonNav from "@/components/Nav.vue";
import CommonHeader from "@/components/Header.vue";
import * as MN from '@/api/common';

export default {
    name: 'crm', 
	props:['selec'],
	data() {
		return {
			defaultUnit:'g',
			showQuality:'g',
			subask_question:'',
			main_question_id:'',
			dialogCreateSubQuestion:false,
			new_ask_question:'',
			QuestionListEmpty:true,
			questionDataList:[],
			dialogCreateQuestion:false,
			activeName:'order',
			loading:true,
			boxshow: false, 
			curTipsID: '',
			userCount:0,
			MonDose:[],
			TueDose:[],
			WedDose:[],
			ThuDose:[],
			FriDose:[],
			SatDose:[],
			SunDose:[],
			MonPeople_zaocan:0,
			MonPeople_jiacan:0,
			MonPeople_wucan:0,
			MonPeople_wudian:0,
			MonPeople_wancan:0,
			TuePeople_zaocan:0,
			TuePeople_jiacan:0,
			TuePeople_wucan:0,
			TuePeople_wudian:0,
			TuePeople_wancan:0,
			WedPeople_zaocan:0,
			WedPeople_jiacan:0,
			WedPeople_wucan:0,
			WedPeople_wudian:0,
			WedPeople_wancan:0,
			ThuPeople_zaocan:0,
			ThuPeople_jiacan:0,
			ThuPeople_wucan:0,
			ThuPeople_wudian:0,
			ThuPeople_wancan:0,
			FriPeople_zaocan:0,
			FriPeople_jiacan:0,
			FriPeople_wucan:0,
			FriPeople_wudian:0,
			FriPeople_wancan:0,
			SatPeople_zaocan:0,
			SatPeople_jiacan:0,
			SatPeople_wucan:0,
			SatPeople_wudian:0,
			SatPeople_wancan:0,
			SunPeople_zaocan:0,
			SunPeople_jiacan:0,
			SunPeople_wucan:0,
			SunPeople_wudian:0,
			SunPeople_wancan:0,
			detailData: [],
			detailList:[],
			detailUUID:this.$route.query.order_uuid,
			detail_orderno:'',
			detail_kindergarten_name:'',
			detail_suppliser_name:'',
			detail_deliver_date:'',
			detail_create_date:'',
			detail_status:''
		}
	},
	components: {
		CommonNav,
		CommonHeader
	},

	created() {
		this.initData()
		this.initMessageData()
	},

	mounted() {

	},

	methods: {
		switchQuality(){
			console.log(this.showQuality)
			console.log( this.ThuDose )
			if ( this.showQuality == 'kg' ){
				this.defaultUnit = 'kg';
				this.MonDose.forEach(item => {
					if ( item['unit'] == 'g' ){
						let new_dose = parseFloat(item['newdose']) / 1000;
						item['newdose'] = new_dose.toFixed(2) + 'k';
					}
				});
				this.TueDose.forEach(item => {
					if ( item['unit'] == 'g' ){
						let new_dose = parseFloat(item['newdose']) / 1000;
						item['newdose'] = new_dose.toFixed(2) + 'k';
					}
				});
				this.WedDose.forEach(item => {
					if ( item['unit'] == 'g' ){
						let new_dose = parseFloat(item['newdose']) / 1000;
						item['newdose'] = new_dose.toFixed(2) + 'k';
					}
				});
				this.ThuDose.forEach(item => {
					if ( item['unit'] == 'g' ){
						let new_dose = parseFloat(item['newdose']) / 1000;
						item['newdose'] = new_dose.toFixed(2) + 'k';
					}
				});
				this.FriDose.forEach(item => {
					if ( item['unit'] == 'g' ){
						let new_dose = parseFloat(item['newdose']) / 1000;
						item['newdose'] = new_dose.toFixed(2) + 'k';
					}
				});
				this.SatDose.forEach(item => {
					if ( item['unit'] == 'g' ){
						let new_dose = parseFloat(item['newdose']) / 1000;
						item['newdose'] = new_dose.toFixed(2) + 'k';
					}
				});
				this.SunDose.forEach(item => {
					if ( item['unit'] == 'g' ){
						let new_dose = parseFloat(item['newdose']) / 1000;
						item['newdose'] = new_dose.toFixed(2) + 'k';
					}
				});
			}
			if ( this.showQuality == 'g' ){
				this.defaultUnit = 'g';
				this.MonDose.forEach(item => {
					if ( item['unit'] == 'g' ){
						let new_dose = parseFloat(item['newdose']) * 1000;
						item['newdose'] = new_dose;
					}
				});
				this.TueDose.forEach(item => {
					if ( item['unit'] == 'g' ){
						let new_dose = parseFloat(item['newdose']) * 1000;
						item['newdose'] = new_dose;
					}
				});
				this.WedDose.forEach(item => {
					if ( item['unit'] == 'g' ){
						let new_dose = parseFloat(item['newdose']) * 1000;
						item['newdose'] = new_dose;
					}
				});
				this.ThuDose.forEach(item => {
					if ( item['unit'] == 'g' ){
						let new_dose = parseFloat(item['newdose']) * 1000;
						item['newdose'] = new_dose;
					}
				});
				this.FriDose.forEach(item => {
					if ( item['unit'] == 'g' ){
						let new_dose = parseFloat(item['newdose']) * 1000;
						item['newdose'] = new_dose;
					}
				});
				this.SatDose.forEach(item => {
					if ( item['unit'] == 'g' ){
						let new_dose = parseFloat(item['newdose']) * 1000;
						item['newdose'] = new_dose;
					}
				});
				this.SunDose.forEach(item => {
					if ( item['unit'] == 'g' ){
						let new_dose = parseFloat(item['newdose']) * 1000;
						item['newdose'] = new_dose;
					}
				});
			}
		},
		hadleSaveNewSubQuestion(){
			let me = this;
			if( !this.subask_question ){
				this.$message.warning('请输入问题');
				return
			}
			let post={
				chat_type:'1',
				question_id:me.main_question_id,
				sub_question:me.subask_question
			}
			MN.setSaveNewSubQuestion(post).then(res => {
				if( res == 'add_ok' ){
					me.dialogCreateSubQuestion = false;
					me.$message.success('已保存');
					me.initMessageData();
				}
			})
		},
		handleCreateSubQuestion(qid){
			this.main_question_id = qid;
			this.subask_question = '';
			this.dialogCreateSubQuestion = true
		},
		hadleSaveNewQuestion(){
			let me = this;
			if( !this.new_ask_question ){
				this.$message.warning('请输入问题');
				return
			}
			let post={
				menu_uuid:me.detailUUID,
				question:me.new_ask_question,
				type_i:'2'
			}
			MN.setSaveNewQuestion(post).then(res => {
				if( res == 'add_ok' ){
					me.dialogCreateQuestion = false;
					me.$message.success('已保存');
					me.initMessageData();
				}
			})
		},
		handleCreateQuestion(){
			this.new_ask_question = '';
			this.dialogCreateQuestion = true;
		},
		initMessageData(){
			let me = this;
			this.loading = false
			let post={
				receipt_type:2,
				uuid:this.detailUUID
			}
			
			MN.questionDataList(post).then(res => {
				console.log(res);
				if( res.length == 0 ){
					me.QuestionListEmpty = true
				}else{
					me.QuestionListEmpty = false
					me.questionDataList = res
				}
			})
		},
		initData(){
			let me = this;
			this.loading = false
			let post={
				order_uuid:this.detailUUID
			}
			MN.getOrderDetail(post).then(res => {
				me.MonDose = JSON.parse(res.MonDose);
				me.TueDose = JSON.parse(res.TueDose);
				me.WedDose = JSON.parse(res.WedDose);
				me.ThuDose = JSON.parse(res.ThuDose);
				me.FriDose = JSON.parse(res.FriDose);
				me.SatDose = JSON.parse(res.SatDose);
				me.SunDose = JSON.parse(res.SunDose);
				me.MonPeople_zaocan = res.MonPeople_zaocan;
				me.MonPeople_jiacan = res.MonPeople_jiacan; 
				me.MonPeople_wucan = res.MonPeople_wucan;
				me.MonPeople_wudian = res.MonPeople_wudian;
				me.MonPeople_wancan = res.MonPeople_wancan;
				me.TuePeople_zaocan = res.TuePeople_zaocan;
				me.TuePeople_jiacan = res.TuePeople_jiacan; 
				me.TuePeople_wucan = res.TuePeople_wucan;
				me.TuePeople_wudian = res.TuePeople_wudian;
				me.TuePeople_wancan = res.TuePeople_wancan;
				me.WedPeople_zaocan = res.WedPeople_zaocan;
				me.WedPeople_jiacan = res.WedPeople_jiacan; 
				me.WedPeople_wucan = res.WedPeople_wucan;
				me.WedPeople_wudian = res.WedPeople_wudian;
				me.WedPeople_wancan = res.WedPeople_wancan;
				me.ThuPeople_zaocan = res.ThuPeople_zaocan;
				me.ThuPeople_jiacan = res.ThuPeople_jiacan; 
				me.ThuPeople_wucan = res.ThuPeople_wucan;
				me.ThuPeople_wudian = res.ThuPeople_wudian;
				me.ThuPeople_wancan = res.ThuPeople_wancan;
				me.FriPeople_zaocan = res.FriPeople_zaocan;
				me.FriPeople_jiacan = res.FriPeople_jiacan; 
				me.FriPeople_wucan = res.FriPeople_wucan;
				me.FriPeople_wudian = res.FriPeople_wudian;
				me.FriPeople_wancan = res.FriPeople_wancan;
				me.SatPeople_zaocan = res.SatPeople_zaocan;
				me.SatPeople_jiacan = res.SatPeople_jiacan; 
				me.SatPeople_wucan = res.SatPeople_wucan;
				me.SatPeople_wudian = res.SatPeople_wudian;
				me.SatPeople_wancan = res.SatPeople_wancan;
				me.SunPeople_zaocan = res.SunPeople_zaocan;
				me.SunPeople_jiacan = res.SunPeople_jiacan; 
				me.SunPeople_wucan = res.SunPeople_wucan;
				me.SunPeople_wudian = res.SunPeople_wudian;
				me.SunPeople_wancan = res.SunPeople_wancan;

				me.detail_orderno = res.order_no;
				me.detail_kindergarten_name = res.kindergarten_name;
				me.detail_suppliser_name = res.suppliser_name;
				me.detail_deliver_date = res.deliver_date;
				me.detail_create_date = res.create_date;
				me.detail_status = res.status;
				//console.log( JSON.parse( res.menu_zaocan ));
				// me.is_order = res.is_order,
				// me.date_start = res.start_date;
				// me.date_end = res.end_date;
				// me.company_name = res.company_name;
				// me.create_date = res.create_date;
				// me.create_user = res.create_user;
				// me.kindergarten_uuid = res.company_uuid;
				// me.menu_zaocan = JSON.parse( res.menu_zaocan );
				// me.menu_jiacan = JSON.parse( res.menu_jiacan );
				// me.menu_wucan = JSON.parse( res.menu_wucan );
				// me.menu_wudian = JSON.parse( res.menu_wudian );
				// me.menu_wancan = JSON.parse( res.menu_wancan );
			})
		},
		handleGoBack(){
			this.$router.back(-1)
		}
	}
}
</script>

<style scoped>
.more_weight{ text-align: right; color: #2aa51a; padding-top: 4px; }
.sdxoa_rpw{ margin: 7px  0 0 2%; padding:7px; border-radius: 5px; background-color: #f5fff3; border: 1px solid #ededed; width: 97%; }
.sixx_oprow{ margin: 7px 2% 0 0; padding:7px; border-radius: 5px; width: 97%; background-color: #f7feff; border: 1px solid #ededed;  }

.fss_info{ border-top: 1px dashed #cccccc; padding-top: 10px; font-size: 12px; } 
 
.label_message{position: relative;}

.dot_item{ position: absolute; top: 0; right: -7px; width: 6px; height: 6px; background-color: red; border-radius: 50%; }


.order_title{
	border-bottom: 1px solid #cccccc;
	padding: 0 0 6px 9px;
	margin: 40px 0 10px 0;
	font-weight: bold;
}

.message_row{
	border:1px solid #dfdfdf;
	border-radius: 6px;
	padding: 20px 40px 20px 40px;
	margin: 14px 0 0 0;
	background-color: #ffffff;
	box-shadow: 1px 2px 4px #e9e9e9;
}


.message_row_info{
	margin-top: 8px;
	font-size: 12px;
	color: #116438;
}



.page_bottom{
	width: 100%;
	height: 100px;
}

.panel_people_num{
	border-bottom: 1px dashed #939393;
	padding: 0 0 10px 0;
	font-size: 14px;
}

.sixk_row{
	width: 14.22%;
}

.sxik_input{
	width: 100%;
}

.panel_food_row{
	border-bottom: 1px dashed #cccccc;
	font-size: 13px;
	padding: 5px 0 5px 0;
	position: relative;
}
.panel_food_wig{
	position: absolute;
	right: 0;
	top: 8px;
	color: #2aa51a;
}

.barsxk{
	margin:10px 0 10px 0;
	padding: 7px;
	font-size: 13px;
	background-color: #F4F8EE;
	border-radius: 6px;
}

.buttonexcel{
	background: none;
	background-color: #409eff;
	width: 90px;
	color: #fff;
	border: none;
	border-radius: 4px;
	padding: 5px;
	cursor: pointer;
}
.page_bar_title{
	color: #575757;
}
.mt20{
	margin-top: 20px;
}
.detail_row{
	background-color: #f8f8f8;
	border-radius: 10px;
	padding: 8px;
	box-shadow: 0 2px 5px #eeeeee;
	text-align: left;
}
.card_sub_title{
	font-size: 14px;
	font-weight: bolder;
}

::v-deep .el-tabs__active-bar {
	cursor: pointer;
	background-color: #03BC5B;
}
::v-deep .el-tabs__item.is-active {
	color: #03BC5B;
	font-weight: bolder;
	cursor: pointer;
}
::v-deep .el-tabs__item:hover {
	color: #03BC5B;
	cursor: pointer;
}


.barsxk_op{
	margin:10px 0 10px 0;
	padding: 7px;
	font-size: 13px;
	background-color: #f7f7f7;
	border-radius: 6px;
}

</style>
